.skeleton {
  position: relative;
  display: inline-block;
}

.skeleton::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--brand-accent-color-gray);
  opacity: 0.5;
  animation: skeleton 1s infinite linear;
  border-radius: 0.5rem;
}

@keyframes skeleton {
  0% {
    opacity: 0.65;
  }
  50% {
    opacity: 0.95;
  }
  100% {
    opacity: 0.65;
  }
}
