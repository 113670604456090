table tr th.lastColumn {
  border-right: 1px solid var(--view-table-border-color);
}

.viewDataTableCell {
  border: 1px solid var(--view-table-border-color);
  border-width: 1px 0 0 1px;
  padding: 0;
  --control-border-width: 2.5px;
  white-space: nowrap;
  user-select: none;
  cursor: pointer;
}

.viewDataTableCell .editCellTextBox {
  width: 100%;
  height: 100%;
  margin: 0;
  /* padding: 0 0.5em;
  border: var(--control-border-width) solid transparent; */
  border-radius: 0;
  outline: none;
  background-color: inherit;
  text-overflow: ellipsis;
}

.viewDataTableCell:focus .editCellTextBox,
.viewDataTableCell .editCellTextBox:focus,
.viewDataTableCell:hover .editCellTextBox,
.viewDataTableCell .editCellTextBox:hover {
  /* border: var(--control-border-width) solid transparent; */
  border-radius: 0;
  box-shadow: none;
  background-color: inherit;
}

.viewDataTableCell .editCellTextBox:focus {
  border: var(--control-border-width) solid var(--brand-accent-color-blue-lighter-1);
  border-radius: 0.3em !important;
}

.viewDataTableCell .editCellTextBox:hover {
  /* border: 1px solid var(--brand-accent-color-gray); */
  border-radius: 0.3em !important;
}

.nonEditable.lastRow {
  border-bottom-width: 1px;
}
