.parent {
  position: fixed;
  bottom: 22px;
  right: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  height: 2em;
  border: none;
  border-radius: 1em;
  outline: none;
  padding: 0.6em 1em;
  font-size: 0.9em;
  background-color: var(--brand-color);
  color: white;
  cursor: pointer;
}

.parent > img {
  height: 1.2em;
  filter: invert(100%) sepia(92%) saturate(1%) hue-rotate(236deg) brightness(119%) contrast(100%);
  margin-right: 0.3em;
}

.disabled {
  background-color: var(--brand-accent-color-gray);
  box-shadow: none;
}

.parent span {
  display: inline-block;
  margin-right: 0.3em;
  font-size: 1.2em;
}
