.filterList {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  list-style-type: none;
  gap: 0.3em;
}

/* Default, for embedded note case */
.filterList {
  flex-wrap: wrap;
}

.filterList li {
  max-width: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 600px) {
  .filterList li {
    max-width: max-content;
    overflow: visible;
  }
}

@media (min-width: 600px) {
  .filterList {
    flex-wrap: nowrap;
  }
  .filterList li {
    max-width: max-content;
    overflow: visible;
  }
}

.filterList li {
  --filter-height: 1.35em;
  border-radius: 0.75em;
  padding: 0 0.7em;
  background-color: var(--brand-accent-color-blue-lighter-2);
  color: var(--brand-accent-color-blue-lighter-2-contrast);
  font-size: 0.8em;
  line-height: var(--filter-height);
  cursor: pointer;
}

@media (min-width: 600px) {
  .filterList li {
    --filter-height: 2em;
    padding: 0 1em;
  }
}

.filterList li:hover {
  background-color: var(--brand-accent-color-blue-lighter-1);
}

.filterList li.selected {
  background-color: var(--brand-accent-color-blue-lighter-1);
  color: var(--brand-accent-color-blue-lighter-2);
}

.icon {
  margin-right: 0.5em;
  min-width: 2em;
}

.unselectableIcon {
  margin-left: auto;
}

.unselectableIcon > img {
  stroke-width: 2em;
}

.recordLinkStatusContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.autoLinkMessage {
  align-self: center;
  color: var(--brand-accent-color-blue);
  font-size: 0.6rem;
  font-weight: var(--bold-font-weight);
  text-overflow: ellipsis;
  display: inline-flex;
  align-items: center;
  height: 1.5em;
  position: absolute;
  top: -1.7em;
}

.autoLinkMessage span {
  font-size: 0.85rem;
}

.autoLinkMessage span img {
  height: 0.85rem;
  filter: var(--brand-accent-color-blue-filter);
}

.modalMessage {
  width: min(400px, calc(100% - 3em));
  margin: 1.5em;
}

.modalMessage p {
  margin-bottom: 1em;
}
