/** appContainer contains the header, aside and main */
.appContainer {
  display: flex;
  flex-direction: column;
  margin: 0;
  height: 100%;
  overflow: hidden;
}

/** appBody contains the menu (aside) and body (main) */
.appBody,
.dashboardsPage,
.dashboardsPageNew,
.workflowPage,
.workflowEditorPage,
.settingsPage {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto; /* It fills the remaining space after header */
  overflow: auto;
  overflow-x: hidden;
}

.taskPage {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  /* overflow: auto;
  overflow-x: hidden; */
}

/* .settingsPage,
.settingsPageClose {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
} */

.appBody main,
.taskPage main,
.workflowPage main,
.workflowEditorPage main,
/* .settingsPage main, */
.dashboardsPage main,
.dashboardsPageNew main {
  flex: 0 0 100%;
}

div.dashboardsPageNew > main {
  /* height: calc(100vh - 70px); */
  padding-left: 0.7rem;
}

.taskPage {
  z-index: 0;
}

.dashboardsPageNew main {
  margin: 0;
}

@media (min-width: 600px) {
  .appBody main,
  .taskPage main,
  .workflowPage main,
  .settingsPage main,
  .dashboardsPage main,
  .dashboardsPageNew main {
    flex: 1 0 93%;
  }
  .workflowEditorPage main {
    flex: 1 0 calc(100vw - 78px);
  }
  .settingsPage main {
    flex: 1 0 calc(100% - 7em);
  }
}

@media (min-width: 900px) {
  .appBody main {
    flex: 68%;
    margin: 0;
  }

  .settingsPage main {
    /* flex: 0 0 93%; */
    margin: 0;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  .settingsPageClose main {
    overflow-x: hidden;
    overflow-y: scroll;
    flex: 0 0 90%;
  }

  .workflowPage main {
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .workflowEditorPage main {
    margin: 0;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .workflowEditorPage main {
    background-color: var(--background-color);
  }

  .dashboardsPage main {
    flex: 0 0 90%;
    margin: 0 auto;
  }
}

/* Used in earlier layout */
/* pageContent describes the container for what comes after header and
   sub-header */
.pageContent {
  flex: 1 1 auto;
  overflow-y: auto;
  padding: 0 15%;
}

.pageContent h1 {
  color: var(--text-subdued);
  font-weight: var(--bold-font-weight);
  margin: 1em 0;
}

/** Scroll */
* {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border: 3px solid var(--background-color);
  border-radius: 6px;
  background-color: var(--brand-accent-color-gray);
}

aside::-webkit-scrollbar-thumb {
  background-color: var(--background-color);
}

aside:hover::-webkit-scrollbar-thumb {
  background-color: var(--brand-accent-color-gray);
}
