.collectionsContainer {
  min-height: 4em;
  padding: 0.5em;
}

.collectionsRow {
  margin-top: 0.2em;
  padding-left: 2.5%;
  padding-right: 2.5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.collectionsRowItem {
  width: 20%;
  border: 1px solid var(--foreground-color) !important;
  height: 3em;
  padding: 0.3em 1em 0.3em 1em;
  border-radius: var(--luru-border-radius);
  outline: none;
  cursor: pointer;
  user-select: none;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.collectionsRowItem:hover {
  border: 2px solid var(--brand-accent-color-blue) !important;
}

.selected {
  background-color: var(--brand-accent-color-gray-lighter-2);
  color: var(--foreground-color);
}

.multiSelectPopupContainer {
  max-width: 10em !important;
}

.collectionName {
  overflow: hidden;
  white-space: wrap;
  text-overflow: ellipsis;
  text-align: center;
}

/* New */
div.collectionsChooser {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
}

.title {
  margin-bottom: 0.3em;
}

button.collectionChooserButton {
  width: 11em;
  border: 1px solid var(--brand-accent-color-gray);
  border-radius: var(--luru-border-radius);
  /* outline: 1px solid var(--background-color); */
  padding: 0.3em 1em;
  background-color: var(--background-color-secondary);
  color: var(--text-subdued);
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

button.collectionChooserButton:hover {
  border: 1px solid var(--brand-accent-color-blue);
  background-color: var(--brand-accent-color-blue-lighter-2);
  color: black;
}

button.collectionChooserButton.selected {
  background-color: var(--brand-accent-color-blue-lighter-2);
  border: 2px solid var(--brand-accent-color-blue);
  outline: 0;
  color: var(--brand-accent-color-blue);
  font-weight: var(--bold-font-weight);
}

button.collectionChooserComponent {
  border: 1px solid var(--brand-accent-color-gray);
  border-radius: var(--luru-border-radius);
}

div.fieldChooserContainer:not(:nth-child(6n)) {
  margin-left: auto;
}

button.fieldChooserComponent {
  width: 8.8em !important;
  border: 1px solid var(--brand-accent-color-gray) !important;
  border-radius: var(--luru-border-radius);
  background-color: var(--background-color-secondary) !important;
}

@media (max-width: 400px) {
  button.collectionChooserButton {
    width: 9em;
  }
}

@media (max-width: 850px) {
  .fieldChooserContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}
