.parent,
.parent[data-color='blue'] {
  --luru-button-primary-color-100: var(--brand-accent-color-blue-lighter-3);
  --luru-button-primary-color-200: var(--brand-accent-color-blue-lighter-2);
  --luru-button-primary-color-300: var(--brand-accent-color-blue);
  --luru-button-primary-color-400: var(--brand-accent-color-blue-semi);
  --luru-button-primary-color-500: var(--brand-accent-color-blue-darker-1);
}

.parent[data-color='red'] {
  --luru-button-primary-color-100: var(--brand-accent-color-pink-lighter-3);
  --luru-button-primary-color-200: var(--brand-accent-color-pink-lighter-2);
  --luru-button-primary-color-300: var(--brand-accent-color-pink-darker-1);
  --luru-button-primary-color-400: var(--brand-accent-color-pink-darker-2);
  --luru-button-primary-color-500: var(--brand-accent-color-pink-darker-3);
}

.parent[data-color='gray'] {
  --luru-button-primary-color-100: var(--brand-accent-color-gray-lighter-3);
  --luru-button-primary-color-200: var(--brand-accent-color-gray-lighter-2);
  --luru-button-primary-color-300: var(--brand-accent-color-gray-darker-2);
  --luru-button-primary-color-400: var(--brand-accent-color-gray-darker-2);
  --luru-button-primary-color-500: var(--brand-accent-color-gray-darker-3);
}

.parent[data-variant='solid'] {
  --luru-button-bg-color: var(--luru-button-primary-color-300);
  --luru-button-bg-color-hover: var(--luru-button-primary-color-400);
  --luru-button-bg-color-active: var(--luru-button-primary-color-500);
  --luru-button-fg-color: var(--white-color);
  --luru-button-border-color: var(--luru-button-primary-color-300);
}

.parent[data-variant='outline'],
.parent[data-variant='link'] {
  --luru-button-bg-color: var(--white-color);
  --luru-button-bg-color-hover: var(--luru-button-primary-color-100);
  --luru-button-bg-color-active: var(--luru-button-primary-color-200);
  --luru-button-fg-color: var(--luru-button-primary-color-300);
  --luru-button-border-color: var(--luru-button-primary-color-300);
}

.parent[data-variant='link'] {
  --luru-button-bg-color: transparent;
  --luru-button-bg-color-hover: var(--luru-button-primary-color-100);
  --luru-button-bg-color-active: var(--luru-button-primary-color-200);
  --luru-button-border-color: transparent;
}

.parent,
.parent[data-size='md'] {
  font-size: 1rem;
}

.parent[data-size='sm'] {
  font-size: 0.9rem;
}

.parent[data-size='xs'] {
  font-size: var(--font-size-decrease-1);
}

.parent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  border: 1px solid var(--luru-button-border-color);
  border-radius: var(--luru-border-radius);
  padding: 0.3em 1em;
  background-color: var(--luru-button-bg-color);
  color: var(--luru-button-fg-color);
  font-weight: var(--bold-font-weight);
  user-select: none;
  cursor: pointer;
  transition: all 150ms ease-in;
  white-space: nowrap;
}

.parent[data-variant='link'] {
  display: inline-flex;
  margin-left: 1ch;
}

.parent:hover {
  background-color: var(--luru-button-bg-color-hover);
  color: var(--luru-button-fg-color);
}

.parent[data-variant='solid']:hover {
  border-color: var(--luru-button-bg-color-hover);
}

.parent[data-variant='outline']:hover {
  border-color: var(--luru-button-fg-color);
}

.parent:active {
  background-color: var(--luru-button-bg-color-active);
}

.parent[data-variant='solid']:active {
  border-color: var(--luru-button-bg-color-active);
}

.parent[data-variant='outline']:active {
  border-color: var(--luru-button-fg-color);
}

.parent[disabled],
.parent[disabled]:hover,
.parent[disabled]:active {
  border: 1px solid var(--luru-button-border-color);
  background-color: var(--luru-button-bg-color);
  color: var(--brand-accent-color-gray);
  opacity: 0.5;
  cursor: default;
}

.parent[data-variant='outline'][disabled] {
  border-color: var(--brand-accent-color-gray);
  color: var(--brand-accent-color-gray-darker-1);
}

.parent[data-variant='solid'] img {
  filter: var(--white-color-filter);
}

.parent[data-variant='outline'][data-color='blue'] img {
  filter: var(--brand-accent-color-blue-filter);
}

.parent[data-variant='outline'][data-color='red'] img {
  filter: var(--brand-accent-color-pink-filter);
}

.parent.noChildren {
  padding: 0.3em;
}

.warning {
  background-color: var(--brand-accent-color-pink-lighter-2) !important;
  color: var(--brand-accent-color-pink-darker-1) !important;
  border: none !important;
}

.warning img {
  width: 1.2em;
  filter: invert(26%) sepia(54%) saturate(2771%) hue-rotate(315deg) brightness(86%) contrast(97%);
}
