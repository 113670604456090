.inviteUserTable {
  margin: 1em 0;
}

.email,
.role,
.addUser,
.removeUser {
  padding: 0.5em;
}

.email {
  width: 70%;
}

.email > input[type='email'] {
  width: 100%;
  padding: 0.15em 0.6em;
}

.role {
  width: 15%;
}

.addUser,
.removeUser {
  width: 7.5%;
  vertical-align: middle;
}

.addUser button,
.removeUser .nullButton,
.removeUser button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.8rem;
  height: 1.8rem;
  border: 0;
  border-radius: 0.3em;
  padding: 0.3em;
  outline: none;
  background-color: transparent;
  color: var(--brand-accent-color-blue-lighter-1);
  cursor: pointer;
}

.addUser button:hover,
.removeUser button:hover {
  background-color: var(--background-color-secondary);
}

.addUser button span,
.removeUser button span {
  font-size: var(--font-size-increase-1);
}

.addUser button:active,
.removeUser button:active {
  background-color: var(--brand-accent-color-blue-lighter-2);
  font-weight: var(--bold-font-weight);
}

.removeUser .nullButton,
.removeUser .nullButton:hover,
.removeUser .nullButton:active {
  background-color: transparent;
  cursor: default;
}

.inviteButtons {
  display: flex;
  flex-direction: row;
  margin-top: 2em;
}

.inviteButtons button[data-role='cancel-button'],
.inviteButtons button[data-role='invite-button'] {
  width: 100px;
  height: 32px;
  margin-left: auto;
  border: none;
  outline: none;
  border-radius: 0.3em;
  cursor: pointer;
}

.inviteButtons button[data-role='invite-button'] {
  margin-left: 1em;
  background-color: var(--brand-color);
  color: white;
  font-weight: var(--bold-font-weight);
}

.inputBox {
  width: 100%;
}

.inputLabel {
  display: block;
  padding: 0.3em 0 0.4em;
  font-size: 0.85em;
  font-weight: var(--bold-font-weight);
  color: var(--brand-accent-color-blue);
}

.inputContainer {
  margin-top: 0.5em;
}

.createCollectionDialog hr {
  background-color: var(--brand-accent-color-blue);
  color: var(--brand-accent-color-blue);
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.fieldLabelContainer {
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: space-between; */
  margin-top: 1em;
}

.fieldLabelContainer h2 {
  font-size: var(--font-size-increase-1);
  font-weight: var(--bold-font-weight);
  margin-bottom: 0.5em;
}

.fieldSet {
  max-height: min(60vh, 200px);
  overflow: auto;
}

div.fieldLabelContainer div[data-luru-role='fieldset-chooser-component'] {
  display: block;
  width: 100%;
}

/* div.fieldLabelContainer li[data-luru-role='multi-select-popup-list-item'] {
  padding: 0.3em;
} */

.fieldsetChooserContainer {
  display: flex;
  flex-direction: column;
}

div.fieldsetChooserContainer > div[data-luru-role='fieldset-chooser-component'] {
  width: 9em;
  /* display: inline-block; */
  margin-left: auto;
}

div.fieldsetChooserContainer > div[data-luru-role='fieldset-chooser-component'] > div > button {
  width: 10em;
  color: var(--brand-accent-color-blue);
}

.createCollectionDialog h3 {
  display: block;
  margin-top: 1em;
  color: var(--text-subdued);
  text-align: center;
  font-style: italic;
}

.createCollectionModal {
  max-width: 750px;
}

.error {
  color: var(--warning-color);
}

.alert {
  padding: 0.3em 1em 1em 0em;
  color: var(--warning-color);
  font-weight: var(--bold-font-weight);
  font-size: 0.9em;
}
