.results li[data-sor-result='true'] {
  justify-content: space-between;
}

li[data-sor-result='true'] span.subject {
  flex-grow: 0;
  width: 70%;
  font-size: 0.8em;
}

li[data-sor-result='true'] span.time {
  font-size: 0.8em;
}

.connectButton {
  justify-content: center;
  width: fit-content;
  background-color: var(--brand-accent-color-blue-lighter-2);
  font-weight: var(--bold-font-weight);
  font-size: 0.9em;
}

@media (min-width: 600px) {
  .connectButton {
    width: 12em;
  }
}

@media (min-width: 900px) {
  .connectButton {
    width: 12em;
  }
}

@media (max-width: 600px) {
  .connectButtonRegular {
    display: flex;
    align-items: center;
    gap: 0.5em;
    width: auto !important;
    flex-grow: 1;
    /* min-width: 75% !important; */
    max-width: 75% !important;
  }
}

@media (min-width: 600px) {
  .connectButtonRegular {
    display: flex;
    align-items: center;
    gap: 0.5em;
    width: auto !important;
    flex-grow: 1;
    /* min-width: 50% !important; */
    max-width: 50% !important;
  }
}

@media (min-width: 1200px) {
  .connectButtonRegular {
    display: flex;
    align-items: center;
    gap: 0.5em;
    width: auto !important;
    flex-grow: 1;
    max-width: 50.5% !important;
  }
}

.connectButtonEmbedded {
  width: auto !important;
  flex-grow: 1;
}
