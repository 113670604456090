.circularProgress {
  position: relative;
  transform: rotate(-90deg);
  color: var(--brand-accent-color-blue);
}

@keyframes bar-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes bar-dash {
  0% {
    stroke-dasharray: 2.08%, 416%;
    stroke-dashoffset: 0%;
  }
  50% {
    stroke-dasharray: 185.4%, 416%;
    stroke-dashoffset: -72.9%;
  }
  to {
    stroke-dasharray: 185.4%, 416%;
    stroke-dashoffset: -258.33%;
  }
}

.circle {
  animation: bar-rotate 2s linear infinite;
}

.circlePath {
  animation: bar-dash 1.5s ease-in-out infinite;
  stroke-dasharray: 2.08%, 416%;
  stroke-dashoffset: 0;
  transition: stroke-dasharray 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  fill: none;
  stroke-linecap: round;
  stroke-miterlimit: 20;
  stroke-width: 0.125rem;
  stroke: currentColor;
  transform-origin: 0 0;
}
