.parent {
  display: inline-block;
  height: 1.8em;
}

button.chooseFields {
  width: 10em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 0;
  outline: 0;
  padding: 0.3em 1em;
  cursor: pointer;
}

button.chooseFields > img {
  margin-right: 0.5em;
}

div.popupContent {
  width: 20em;
}

div.selectionMode {
  display: flex;
  flex-direction: row;
}

div.selectionMode button {
  width: 50%;
  border: none;
  border-bottom: 2px solid var(--background-color);
  outline: none;
  padding: 0.3em;
  background-color: transparent;
  color: var(--foreground-color);
  cursor: pointer;
  font-weight: var(--bold-font-weight);
}

div.selectionMode button.selected {
  border-bottom: 2px solid var(--brand-accent-color-blue-lighter-1);
  background-color: var(--background-color);
  color: var(--brand-accent-color-blue-lighter-1);
}

div.collectionsSelector {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.1em;
  padding: 0 0.25em;
  text-align: center;
}

div.collectionsSelector > div[data-luru-role='luru-popup-menu'] {
  width: calc(100% - 1.5em);
  margin: 0 auto;
  text-align: center;
}

button.collectionSelectBox {
  width: 100%;
}

div.collectionsSelector div[data-role='popup-menu'] {
  width: 100%;
}

div.collectionsSelector div[data-role='popup-menu'] ul {
  width: 100%;
}

div.collectionsSelector div[data-role='popup-menu'] ul li {
  width: 100%;
}

div.popupContent li[data-luru-role='multi-select-popup-list-item'][data-luru-multiselect-disabled='true']:hover img {
  visibility: hidden;
}

div.multiSelectPopupContainer {
  min-width: 15em;
}

div.multiSelectPopupContainer ul li[data-luru-role='multi-select-popup-list-item'] {
  padding-top: 0.3em;
  padding-bottom: 0.3em;
}

@media (max-width: 400px) {
  div.popupContent {
    width: 18em;
  }
}

img.readonlyIcon {
  position: absolute;
  right: 2px;
  top: 0.6em;
  display: inline;
  width: 0.7em;
}
