aside.toolbar {
  width: 78px;
  padding-top: 1em;
  user-select: none;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

aside.toolbar > div:nth-child(2) {
  margin-bottom: 1em;
}

.toolbar a.normal {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem;
  border-radius: 0.75em;
  padding: 8px 10px;
  outline: 0;
  font-size: var(--font-size-decrease-2);
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

.toolbar a .image {
  padding: 8px 10px;
  border-radius: 1rem;
}

.toolbar a:hover .image {
  background-color: var(--brand-accent-color-gray-lighter-1);
  padding: 8px 10px;
  border-radius: 1rem;
}

.toolbar .image img {
  display: block;
  width: 20px;
  margin: 0.3em;
}

.toolbar a span {
  width: 140%;
  margin-top: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.toolbar a.selected span {
  font-weight: var(--bold-font-weight);
}

.toolbar a.selected > .image > img {
  width: 20px;
}

.toolbar a.selected > .image {
  background-color: var(--brand-accent-color-blue);
  padding: 8px 10px;
  border-radius: 1rem;
}

.toolbar a > .image::before {
  display: block;
  content: ' ';
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  filter: var(--brand-accent-color-gray-filter);
  transform: scale(0.833);
}

.toolbar a.selected > .image::before {
  filter: brightness(0) invert(1);
}

.noteImage::before {
  /* background-image: url(@/images/fluent/document_bullet_list_multiple.svg); */
  background-image: url(@/images/flaticon/layers.svg);
}

.toolbar a.selected > .noteImage::before {
  /* background-image: url(@/images/fluent/document_bullet_filled.svg); */
  background-image: url(@/images/flaticon/layers-filled.svg);
}

.dealsDashboardImage::before {
  background-image: url(@/images/flaticon/chart-histogram.svg);
}

.toolbar a.selected > .dealsDashboardImage::before {
  background-image: url(@/images/flaticon/chart-histogram.svg);
}

.workflowImage::before {
  /* background-image: url(@/images/fluent/organization_horizontal.svg); */
  background-image: url(@/images/flaticon/workflow.svg);
}

.toolbar a.selected > .workflowImage::before {
  /* background-image: url(@/images/fluent/organization_filled.svg); */
  /* transform: rotateZ(270deg); */
  background-image: url(@/images/flaticon/workflow-filled.svg);
}

.pipeLineImage::before {
  /* background-image: url(@/images/fluent/table_freeze.svg); */
  background-image: url(@/images/flaticon/table-list.svg);
}

.toolbar a.selected > .pipeLineImage::before {
  /* background-image: url(@/images/fluent/table_freeze_filled.svg); */
  background-image: url(@/images/flaticon/table-list-filled.svg);
}

.meetingImage::before {
  background-image: url(@/images/fluent/calendar_ltr.svg);
}

.toolbar a.selected > .meetingImage::before {
  background-image: url(@/images/fluent/calendar_filled.svg);
}

.taskImage::before {
  background-image: url(@/images/fluent/task_list_square_ltr.svg);
}

.toolbar a.selected > .taskImage::before {
  background-image: url(@/images/fluent/task_list_filled.svg);
}

.settingsImage::before {
  /* background-image: url(@/images/fluent/settings.svg); */
  background-image: url(@/images/flaticon/settings.svg);
}

.toolbar a.selected > .settingsImage::before {
  /* background-image: url(@/images/fluent/settings_filled.svg); */
  background-image: url(@/images/flaticon/settings-filled.svg);
}

.toolbar a.withBadge {
  position: relative;
  overflow: visible;
}

.toolbar .badge {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0.3em;
  padding: 0.3em 1em;
  background-image: linear-gradient(-45deg, var(--brand-accent-color-blue), var(--brand-color-light));
  background-color: transparent;
  color: white;
  font-weight: var(--bold-font-weight);
  font-size: 80%;
  z-index: 100;
}

img[data-luru-role='meeting-icon'] {
  width: 24px;
}

.pipeLine > .tips {
  position: absolute;
  visibility: hidden;
}

/* .pipeLine {
  display: flex;
  position: relative;
  align-items: center;
} */

.pipeLine:hover .tips {
  left: 55px;
  visibility: visible;
  z-index: 10000;
  opacity: 1;
  transition: all 0.1s ease-in-out;
  background-color: var(--brand-accent-color-gray-lighter-2);
  padding: 4px;
  margin-top: -15px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
}

.pipeLine a {
  width: 100%;
}

.Icon {
  filter: var(--brand-accent-color-gray-darker-2-filter);
  cursor: pointer;
}

.video > p {
  font-weight: var(--bold-font-weight);
  margin: 1em;
}

.video > p > a {
  display: inline;
  color: #6a45ff;
}
