.main {
  position: relative;
}

.main .loading {
  position: absolute;
  top: 1em;
  right: 3em;
  width: 1em;
  height: 1em;
}

.main input {
  text-overflow: ellipsis;
  width: 100%;
}

input.withIcon[type='search'] {
  background: url('../../images/search.png') no-repeat scroll 0.3em 0.3em;
  background-size: 1.15em;
  padding-left: 2em;
}

.main input[disabled][type='search'] {
  background-color: var(--disabled-background-color);
}

.main .closeIcon {
  position: absolute;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  right: 1em;
  width: 1em;
  height: 1em;
}

.closeIcon img {
  width: 1em;
}

.closeImg {
  margin-top: 0.4em;
}
