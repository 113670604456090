/** Editor menu */
.editormenu {
  position: absolute;
  z-index: 200;
  visibility: hidden;
  display: none;
  height: auto;
  /* max-height: 350px; */
  border: var(--luru-border);
  border-radius: var(--luru-border-radius);
  box-shadow: var(--luru-box-shadow);
  background-color: var(--background-color);
  opacity: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

.editormenu .noResults {
  display: none;
}

.editormenu[data-no-results='true'] .noResults {
  display: block;
  min-width: 20em;
  padding: 0.3em 1em;
  font-size: var(--font-size-decrease-1);
}

.editormenu[data-no-results='true'] .noResults em {
  color: var(--brand-accent-color-blue);
  font-style: normal;
}

.editormenu ul {
  list-style-type: none;
}

.editormenu li {
  display: block;
  min-width: 20em;
  white-space: nowrap;
  padding: 0.5em 1em;
  color: var(--foreground-color);
  cursor: pointer;
}

.editormenu li:focus {
  outline: none;
}

.editormenu li.separated {
  border-top: 1px solid #d9d9d9;
}

.shortcutMenuVisible,
.contactsMenuVisible,
.hashtagMenuVisible {
  display: block;
  visibility: visible;
  opacity: 1;
}

.editormenu > ul > li {
  margin-right: 0;
  color: var(--brand-accent-color-gray-darker-1);
  font-size: var(--font-size-decrease-1);
  font-weight: var(--bold-font-weight);
}

.editormenu > ul > ul > li {
  font-size: var(--font-size-decrease-1);
  padding-left: 2em;
}

li[data-menu-index]:hover,
li.selectedMenuItem {
  background-color: var(--brand-accent-color-blue-lighter-2);
  color: var(--brand-accent-color-gray-darker-2);
}

.editormenu [data-menu-item-visible='false'] {
  display: none;
}

/** Contacts menu styling */
.editormenu .contactsmenu ul ul li {
  /** Inner menu list item */
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  margin-bottom: 1em;
}

.editormenu .contactsmenu ul ul li:last-child {
  margin-bottom: 0;
}

.contactsmenu span.contactName,
.contactsmenu span.contactEmail {
  display: block;
  margin: 0;
  padding: 0;
  line-height: 1.5em;
}

.contactsmenu span.contactName {
  font-weight: var(--bold-font-weight);
}

.contactsmenu span.contactEmail {
  font-size: var(--font-size-decrease-1);
}
