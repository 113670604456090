.parent {
  background-color: var(--modal-background-color);
  backdrop-filter: var(--modal-background-filter);
  transition: var(--modal-background-transition);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 300;
  user-select: none;
  font-size: calc(0.9rem);
}

.minimized {
  visibility: hidden;
}

.dialog {
  --dialog-width: 300px;
  --dialog-height: 400px;
  position: absolute;
  left: calc((100% - var(--dialog-width)) / 2);
  top: calc((100% - var(--dialog-height)) / 2);
  width: var(--dialog-width);
  height: var(--dialog-height);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: 1px solid var(--brand-accent-color-gray);
  border-radius: 0.3em;
  padding: 0 1em 1em;
  box-shadow: var(--shadow-color) 0px 3px 8px;
  background-color: var(--background-color);
  color: var(--foreground-color);
  font-size: calc(0.9rem);
}

@media (min-width: 600px) {
  .dialog {
    --dialog-width: 360px;
    --dialog-height: 300px;
  }
}

@media (min-width: 900px) {
  .dialog {
    --dialog-width: 400px;
    --dialog-height: 300px;
  }
}

.title {
  margin-top: 1em;
  text-align: center;
  font-weight: var(--bold-font-weight);
  font-size: 0.9em;
}

.content {
  flex-grow: 1;
  text-align: center;
  overflow: auto;
}

.buttons {
  text-align: right;
}

.buttons button {
  width: 80px;
  margin-left: 1em;
  font-size: 0.9em;
  font-weight: var(--bold-font-weight);
  overflow: hidden;
  text-overflow: ellipsis;
}

.okButton {
  background-color: var(--brand-accent-color-blue);
  color: white;
}

.content ul {
  text-align: left;
  list-style-type: none;
}

.content ul li {
  padding: 0.6em 1em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  text-transform: capitalize;
}

input[data-luru-role='filter-box'] {
  /* outline: none; */
  width: calc(100% - 6px);
  margin: 1em 0;
  /* border: 1px solid var(--brand-accent-color-gray);
  border-width: 0 0 1px 0; */
  /* border-radius: 0.3em; */
  padding: 0.3em 1em;
}

li.selected {
  background-color: var(--brand-accent-color-blue-lighter-2);
}

.parent footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid var(--brand-accent-color-gray);
  padding-top: 0.3em;
  font-size: 0.9em;
}

span.empty {
  color: var(--brand-accent-color-blue);
  cursor: pointer;
}

div.parentMessage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

div.parentMessage > div:nth-child(2) {
  display: flex;
  align-items: center;
  gap: 3px;
}

div.parentMessage > div:nth-child(2) > span:first-child {
  max-width: fit-content;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

div.parentMessage > div:nth-child(2) > span:last-child {
  width: 1.3em;
}

@media (max-width: 500px) {
  div.parentMessage > div:nth-child(2) > span:first-child {
    max-width: 300px;
  }
}

@media (max-width: 350px) {
  div.parentMessage > div:nth-child(2) > span:first-child {
    max-width: 200px;
  }
}
