.heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1.5rem 0 0.5rem;
}

.heading span {
  font-size: var(--font-size-default);
  font-weight: var(--bold-font-weight);
}

.heading .createButton {
  height: 1em;
  margin-right: 0.2em;
  color: var(--brand-accent-color-blue);
  font-size: 1.4em;
}

.heading span + .createButton {
  margin-left: auto;
}

.heading:hover .createButton,
.heading .createButton:hover {
  color: var(--brand-accent-color-blue);
}

.recordList {
  margin-top: 0;
  margin-right: 0;
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
}

.recordList li {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
  border: 1px solid transparent;
  border-radius: 0.5em;
  padding: 0.5rem 1rem;
  font-size: var(--font-size-decrease-1);
}

.selected li {
  /* border: 1px solid var(--brand-accent-color-gray); */
  border-radius: 0.3em;
  background-color: var(--brand-accent-color-gray-lighter-2);
  /* color: var(--brand-blue-color); */
}

.selected .primaryLine {
  font-weight: var(--bold-font-weight);
}

.recordDisplay {
  display: flex;
  flex-direction: column;
  /* icon-width, 2 * gap-width */
  width: calc(100% - 2.5em - 2em);
  flex-grow: 1;
}

.primaryLine {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.secondaryLine {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 2.5em;
  min-width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  /* background-color: var(--brand-accent-color-blue-lighter-2); */
}

.icon img,
.icon img[data-luru-icon-name] {
  width: 30px;
  height: 30px;
}

.right {
  margin-left: auto;
}

.joinMeetingImg {
  width: 1.3em;
  /* display: none; */
}

.zoomLogo {
  width: 1em;
}

.actionButton.shareButton,
.actionButton.hideButton,
.actionButton.deleteButton,
.actionButton.copyButton {
  width: 1.2em;
  height: 1.2em;
  font-size: 1.2em;
  color: transparent;
}

.actionButton.copyButton img {
  height: 1.1em;
  filter: invert(44%) sepia(98%) saturate(277%) hue-rotate(108deg) brightness(92%) contrast(102%);
  opacity: 0;
}

.actionButton img {
  height: 1em;
  filter: var(--brand-accent-color-blue-filter);
  /* vertical-align: middle; */
}

[data-luru-role='list-with-button'] li:hover .copyButton,
[data-luru-role='list-with-button'] li:hover .copyButton:hover,
.recordList li:hover .copyButton,
.recordList li:hover .copyButton:hover {
  color: var(--green-color);
}

[data-luru-role='list-with-button'] li:hover .copyButton img,
[data-luru-role='list-with-button'] li:hover .copyButton:hover img,
.recordList li:hover .copyButton img,
.recordList li:hover .copyButton:hover img {
  opacity: 1;
}

[data-luru-role='list-with-button'] li:hover .deleteButton,
[data-luru-role='list-with-button'] li:hover .deleteButton:hover,
.recordList li:hover .deleteButton,
.recordList li:hover .deleteButton:hover,
.recordList li:hover .deleteButton:hover {
  color: var(--brand-accent-color-pink-darker-2);
}

[data-luru-role='list-with-button'] li:hover .hideButton,
[data-luru-role='list-with-button'] li:hover .hideButton:hover,
.recordList li:hover .hideButton,
.recordList li:hover .hideButton:hover {
  color: var(--brand-accent-color-pink-darker-2);
}

[data-luru-role='list-with-button'] li:hover .hideButtonDisabled,
[data-luru-role='list-with-button'] li:hover .hideButtonDisabled:hover,
.recordList li:hover .hideButtonDisabled,
.recordList li:hover .hideButtonDisabled:hover {
  color: var(--brand-accent-color-gray);
  filter: var(--brand-accent-color-gray-darker-2-filter);
  cursor: default;
}

[data-luru-role='list-with-button'] li:hover .shareButton,
[data-luru-role='list-with-button'] li:hover .shareButton:hover,
.recordList li:hover .shareButton,
.recordList li:hover .shareButton:hover {
  color: var(--brand-accent-color-blue-darker-2);
}

[data-luru-role='list-with-button'] li:hover .shareButtonDisabled,
[data-luru-role='list-with-button'] li:hover .shareButtonDisabled:hover,
.recordList li:hover .shareButtonDisabled,
.recordList li:hover .shareButtonDisabled:hover {
  color: var(--brand-accent-color-gray);
  filter: var(--brand-accent-color-gray-darker-2-filter);
  cursor: default;
}

[data-luru-role='list-with-button'] li .actionButton,
.recordList li .actionButton,
.actionButton {
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition: color 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out;
}

[data-luru-role='list-with-button'] li:hover .actionButton,
.recordList li:hover .actionButton,
.actionButton:hover {
  transition-duration: 0.2s;
}

.recordInfo {
  font-size: var(--font-size-decrease-1);
  color: var(--text-subdued);
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.noResults {
  margin-left: 0.75em;
  margin-right: 0.75em;
  text-align: center;
}

li:hover .pushToRight {
  margin-left: auto;
}

.alwaysPushToRight {
  margin-left: auto;
}

.expandCollapseButton {
  /* transform: translateX(-0.01em) translateY(0.07em); */
  transition: transform 0.4s ease-in-out;
}

.upsideDown {
  transform: rotate(180deg);
}

.emptyPanel {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  margin: 1em 0;
}

.selected li,
.selected:active li {
  background-color: var(--brand-accent-color-blue-lighter-3);
  color: var(--brand-accent-color-blue-darker-1);
}

.selected li {
  border-radius: 2rem;
}

.recordList::-webkit-scrollbar-track {
  background-color: transparent;
}

aside.flexible ul.recordList {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 200px;
  overflow: auto;
  overflow-x: hidden;
  max-height: calc(100% - 22em);
}

aside.flexible ul.naturalSize {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: content;
  max-height: calc(100% - 16em);
}

.tooltip {
  display: table;
  border-collapse: collapse;
}

div[data-role='luru-tool-tip'] div.tooltip div.colHeader {
  padding-right: 0.5em;
  text-align: right;
}

div[data-role='luru-tool-tip'] div.tooltip div.colHeader,
div[data-role='luru-tool-tip'] div.tooltip div.colValue {
  font-size: 0.8em;
}

div[data-role='luru-tool-tip'] div.tooltip div.colValue {
  max-width: 10em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.oneLineRecordDisplay {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
}

.oneLineRecordDisplay .recordTitle {
  width: 40%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1 0 auto;
}

.oneLineRecordDisplay .recordInfo {
  flex: 0 0 100px;
  text-align: right;
}

.oneLineRecordDisplay img {
  height: 1em;
  margin: 0 1em 0 0;
  margin-left: 0.5em;
}
.oneLineRecordDisplay img[data-luru-disable='true'] {
  height: 1em;
  margin: 0 1em 0 0;
  margin-left: 0.5em;
  visibility: hidden;
}

aside.embeddedExplorer {
  display: block;
  width: 100%;
}

.deleteButton img {
  filter: var(--brand-accent-color-pink-darker-2-filter);
}

.recordList li .actionButton img {
  opacity: 0;
  transition: opacity 0.15s ease-in-out;
}

.recordList li:hover .actionButton img {
  opacity: 1;
}

.right > img {
  height: 1.3em;
}

div.meetingNavigationPane {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 0.5em 1em 0.5em;
}

div.meetingNavigationPane > button {
  font-size: var(--font-size-decrease-1);
}

div.addMeetingNoteBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: var(--brand-accent-color-gray-darker-1);
  gap: 1em;
  font-size: var(--font-size-decrease-1);
}

div.meetingNavigationPane + div > details {
  border-top: 1px solid var(--border-gray-color);
  padding-top: 1em;
}

div.meetingNavigationPane + div > details > summary > span {
  color: var(--brand-accent-color-blue);
}
