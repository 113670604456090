.tt {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  top: 0;
  left: 0;
  border-radius: 0.6rem;
  padding: 0.6rem 1rem;
  z-index: 3999;
  /* 1 less than modal */
  transform: scale(0.9);
  transition: opacity 0.3s ease, transform 0.3s ease;
  background-color: var(--brand-accent-color-gray-darker-3);
  color: var(--white-color);
  font-size: 0.9rem;
}

.ttWrapper {
  display: inline-block;
}

.tt.show {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transition: opacity 0.15s ease, transform 0.3s ease;
  box-shadow: var(--luru-box-shadow);
}
