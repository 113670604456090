.textArea {
  height: calc(4 * ((1.15 + 0.3 * 2) * 1em + 2px));
  border: 1px solid var(--brand-accent-color-gray);
  border-radius: 0.3em;
  padding: 0.3em 1em;
  resize: none;
}
.disabled {
  background-color: var(--disabled-background-color) !important;
}
