.toastContainer {
  --toast-buffer: 10px;
  --max-toast-width: 480px;
  --toast-width: calc(min(100vw - 2 * var(--toast-buffer), var(--max-toast-width)));
  --hor-realized-buffer: calc(50vw - var(--toast-width) / 2);
  position: fixed;
  right: var(--hor-realized-buffer);
  bottom: var(--toast-buffer);
  left: var(--hor-realized-buffer);
  z-index: 999;
}

.toastContainer .toastMessage {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1.25rem;
  width: var(--toast-width);
  margin: 0 auto;
  margin-top: var(--toast-buffer);
  border-radius: var(--luru-border-radius);
  border-radius: 1rem;
  padding: calc(var(--toast-buffer) * 1.5);
  background-color: var(--brand-accent-color-blue);
  color: var(--white-color);
  box-shadow: var(--luru-box-shadow);
  font-size: 0.9rem;
  box-shadow: var(--luru-box-shadow);
  opacity: 1;
}

.toastMessage [data-role='link'] {
  color: var(--brand-accent-color-orange);
  cursor: pointer;
}

.toastContainer .toastMessage.closed {
  opacity: 0;
  transform: scale(0.8);
  transition: opacity 0.2s ease-in-out, transform 0.3s ease-in-out;
}

.toastMessage > button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.85rem;
  min-width: 1.85rem;
  height: 1.85rem;
  min-height: 1.85rem;
  margin: 0;
  padding: 0;
  cursor: pointer;
  border-radius: 50%;
  padding: 0.3rem;
  align-self: center;
  line-height: 1;
  transition: background-color 0.15s ease-in-out;
}

.toastMessage > button > img {
  filter: var(--white-color-filter);
  width: 1.25rem;
}

.toastMessage > button[data-role='toast-close-button'] {
  cursor: pointer;
}

.toastMessage > button[data-role='toast-close-button']:hover {
  background-color: var(--brand-accent-color-gray-darker-1);
}

.toastMessage.success {
  background-color: var(--brand-accent-color-gray-darker-3);
}

.toastMessage.error {
  background-color: var(--brand-accent-color-pink-darker-1);
}

.toastMessage.warning {
  background-color: var(--brand-accent-color-orange);
}

.toastMessage.info {
  background-color: var(--brand-accent-color-gray-darker-3);
}

div.toastNotificationStyles a {
  color: white !important;
  text-decoration: underline !important;
}
