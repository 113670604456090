.bannerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: 0.6em;
  position: relative;
  padding-left: 0.5em;
  padding-right: 0.5em;
  line-height: 1.2;
}

/* BANNER DISMISSIBLE */
/* .bannerContainer[data-luru-notification-dismissible='true'] {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5 !important;
} */

.bannerContainer .messageContainer {
  margin-left: 0.5em;
  font-size: 1em;
  font-weight: var(--bold-font-weight);
}

.bannerContainer img.closeIcon {
  width: 1.2em;
  margin-left: 0.2em;
  /* position: absolute; */
  /* top: 0.3em; */
  /* right: 0.3em; */
  cursor: pointer;
}

/* INFO BANNER */
.bannerContainer[data-luru-notification-type='INFO'] {
  background-color: var(--banner-info-color);
  border: 0.5px solid var(--banner-info-color);
  color: white;
}
.bannerContainer[data-luru-notification-type='INFO'] img.closeIcon {
  filter: brightness(0) invert(1);
}
.bannerContainer[data-luru-notification-type='INFO'] .bannerBody .imagePlaceholder {
  background-image: url('../../../../images/fluent/info.svg');
  background-size: contain;
  background-repeat: no-repeat;
  width: 15px;
  height: 15px;
  filter: brightness(0) invert(1);
}

/* ERROR BANNER */
.bannerContainer[data-luru-notification-type='ERROR'] {
  background-color: var(--banner-error-color);
  border: 0.5px solid var(--banner-error-color);
  color: white;
}
.bannerContainer[data-luru-notification-type='ERROR'] img.closeIcon {
  filter: brightness(0) invert(1);
}
.bannerContainer[data-luru-notification-type='ERROR'] .bannerBody .imagePlaceholder {
  background-image: url('../../../../images/fluent/error.svg');
  background-size: contain;
  background-repeat: no-repeat;
  width: 15px;
  height: 15px;
  filter: brightness(0) invert(1);
}

/* REMINDER BANNER */
.bannerContainer[data-luru-notification-type='REMINDER'] {
  background-color: var(--banner-reminder-color);
  border: 0.5px solid var(--banner-reminder-color);
  color: white;
}
.bannerContainer[data-luru-notification-type='REMINDER'] img.closeIcon {
  filter: brightness(0) invert(1);
}
.bannerContainer[data-luru-notification-type='REMINDER'] .bannerBody .imagePlaceholder {
  background-image: url('../../../../images/fluent/clock.svg');
  background-size: contain;
  background-repeat: no-repeat;
  width: 15px;
  height: 15px;
  filter: brightness(0) invert(1);
}

/* ALERT BANNER */
.bannerContainer[data-luru-notification-type='ALERT'] {
  background-color: var(--banner-alert-color);
  border: 0.5px solid var(--banner-alert-color);
}
.bannerContainer[data-luru-notification-type='ALERT'] .bannerBody .imagePlaceholder {
  background-image: url('../../../../images/fluent/warning.svg');
  background-size: contain;
  background-repeat: no-repeat;
  width: 15px;
  height: 15px;
}

.bannerBody {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 1em;
}

.actionButtonsContainer {
  display: flex;
  align-items: center;
  gap: 0.5em;
  bottom: 0.5em;
  right: 0.5em;
}

div.messageContainer a {
  color: white !important;
  text-decoration: underline !important;
}
