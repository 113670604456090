div.parent {
  height: 100vh;
  width: 100vw;
  display: grid;
  justify-content: center;
  align-items: center;
  overflow: scroll;
}

div[data-luru-role='global-search-main-logged-out'] div.parent {
  height: 80vh;
  width: 80vw;
  border: 1px solid var(--brand-accent-color-gray);
  border-radius: 0.5em;
  box-shadow: 0 0 12px 3px var(--brand-accent-color-gray);
  background-color: var(--background-color);
}

div.parent > div.wrapper {
  width: 100vw;
}

@media (max-width: 640px) {
  div.parent > div.wrapper {
    width: 70vw;
  }
  div.parent > div.wrapper > div.header {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (min-width: 640px) {
  div.parent > div.wrapper {
    width: 70vw;
  }
}

div.parent > div.wrapper > div.header {
  text-align: center;
}

div.parent > div.wrapper > div.header > img {
  height: 3em;
}

@media (min-width: 640px) {
  div.parent > div.wrapper > div.header > img {
    height: 4em;
  }

  div.parent > div.wrapper > div.header {
    text-align: left;
  }
}

div.parent > div.wrapper > div.content {
  display: flex;
  flex-direction: row;
  align-items: center;
}

div.parent > div.wrapper > div.content > div.left {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (min-width: 640px) {
  div.parent > div.wrapper > div.content > div.left {
    width: 50%;
  }
}

div.parent > div.wrapper > div.content > div.right {
  width: 50%;
}

div.parent h1 {
  margin-bottom: 1em;
  text-align: center;
  font-weight: var(--bold-font-weight);
}

div.parent h1 em {
  display: block;
  color: var(--green-color);
  font-style: normal;
}

@media (min-width: 330px) {
  div.parent h1 {
    margin-bottom: 1em;
    font-size: var(--font-size-title);
    font-weight: var(--bold-font-weight);
  }

  div.parent h1 em {
    display: block;
    color: var(--green-color);
    font-style: normal;
  }
}

div.left button {
  width: 10em;
  margin-top: 1em;
  border: none;
  border-radius: 0.5em;
  padding: 0.5em 1em;
  background-color: var(--brand-accent-color-blue);
  color: white;
  font-weight: var(--bold-font-weight);
  cursor: pointer;
  transition: all 0.15s ease-in-out;
}

@media (min-width: 330px) {
  div.left button {
    width: 15em;
  }
}

div.left button:hover {
  background-color: var(--brand-accent-color-blue-lighter-1);
}

button + div.condition {
  margin-top: 3em;
}

div.condition {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1em 0 0;
  text-align: left;
  cursor: pointer;
}

div.condition img {
  height: 1.5em;
  margin-right: 0.3em;
  cursor: pointer;
}

div.condition a {
  text-decoration: underline;
}

div.left h3 {
  display: flex;
  align-items: center;
  margin: 4em 0 3em;
}

div.left h3 img {
  height: 1.5em;
  margin-right: 0.5em;
}

div.right {
  display: none;
}

@media (min-width: 640px) {
  div.right {
    display: flex;
    flex-direction: column;
    justify-self: center;
    align-items: center;
  }

  div.right img[data-role='hero'] {
    width: 65%;
    margin: 0 auto;
  }

  div.right h2 {
    margin: 1em 0;
    text-align: center;
    font-weight: var(--bold-font-weight);
  }

  div.features {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  div.features > div {
    display: flex;
    align-items: center;
    flex: 40% 0 0;
    margin: 1em 0 0;
  }

  div.features > div > img {
    width: 1.25em;
    height: 1.25em;
    margin: 0 0.5em 0.5em 0;
    vertical-align: middle;
  }
}
