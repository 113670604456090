.parent {
  display: flex;
  align-items: center;
  gap: 0.3em;
  width: 100%;
  padding: 0.2em;
  border: 1px solid var(--brand-accent-color-gray-lighter-2);
  border-radius: var(--luru-border-radius);
  /* flex-wrap: wrap; */
}

.parentDialog {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
}

.disabledParent {
  background-color: var(--brand-accent-color-gray);
  /* flex-wrap: wrap; */
}

.mainContainer {
  width: 100%;
}

.chipsContainer {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.3em;
  flex-wrap: wrap;
}

.inputContainer {
  flex: 1;
}

.textInput {
  width: 100% !important;
  flex: 1;
  margin: 0;
  padding: 0;
  background: transparent;
  border: none !important;
  outline: none !important;
  appearance: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  cursor: pointer;
  background-color: white !important;
}

.disabledTextInput {
  cursor: default;
  background-color: var(--brand-accent-color-gray) !important;
}

div.recordTypeContainer {
  display: flex;
  flex-direction: column;
}

div.recordTypeContainer .recordItem {
  height: 3em;
  width: 100%;
  display: flex;
  padding: 0.8em;
  cursor: pointer;
}

div.recordTypeContainer .recordItem > label {
  text-transform: capitalize;
}

div.recordTypeContainer .recordItem label {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis !important;
  text-align: left !important;
  flex: 1;
  font-weight: normal !important;
  cursor: pointer;
}

div.recordTypeContainer .baseRecordItem {
  font-weight: var(--bold-font-weight);
  /* background-color: var(--brand-accent-color-blue-lighter-2); */
}

div.recordTypeContainer .baseRecordItem label {
  font-weight: bold !important;
}

div.recordTypeContainer .recordItem[data-field-selected='true'] {
  background-color: var(--brand-accent-color-blue-lighter-2);
}

div.recordTypeContainer .recordItem:hover {
  background-color: var(--brand-accent-color-blue-lighter-2);
}

.checkedIcon {
  font-size: 1.2em !important;
  color: var(--brand-accent-color-blue);
  vertical-align: middle;
  font-weight: bold !important;
}

.errorContainer,
.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  width: 300px;
  flex-direction: column;
  gap: 0.2em;
}

.errorContainer {
  color: var(--warning-color);
}

/* New styles */
.container {
  height: 34em;
  display: flex;
  margin-top: 1em;
  border-radius: 1em;
  background-color: var(--brand-accent-color-blue-lighter-2);
}

.container section {
  height: 100%;
  width: 20em;
  background-color: white;
}

.container section[data-section='object'] {
  width: 25%;
}

.container section[data-section='available_fields'] {
  width: calc(75% / 2);
}

.container section[data-section='selected_fields'] {
  width: calc(75% / 2);
}

.container section header {
  height: 3em;
  background-color: var(--brand-color);
  font-weight: var(--bold-font-weight);
}

.container section > div:last-child {
  height: calc(100% - 3em);
  overflow: auto;
}

.container section:first-child,
.container section:first-child header {
  border-right: 0.5px solid white;
  border-top-left-radius: 1em;
}

.container section:last-child,
.container section:last-child header {
  border-left: 0.5px solid white;
  border-top-right-radius: 1em;
}

.container section:first-child {
  border-bottom-left-radius: 1em;
}

.container section:last-child {
  border-bottom-right-radius: 1em;
}

.container section header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.container section header span {
  color: white;
  font-size: 1.1em;
}

.container section:first-child > div,
.container section:first-child + section > div {
  border-right: 1px solid var(--brand-accent-color-blue-lighter-2);
}

.container section header.selectedColumnHeader {
  position: relative;
}

.container section header.selectedColumnHeader .hint {
  display: block !important;
  position: absolute;
  bottom: 0;
  color: var(--brand-accent-color-gray);
  font-size: var(--font-size-decrease-1);
}

@media (max-width: 640px) {
  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .container > section:nth-child(1) {
    height: 25%;
  }

  .container > section:nth-child(2) {
    height: 50%;
  }

  .container > section:nth-child(3) {
    height: 25%;
  }

  .container section:first-child,
  .container section:first-child header {
    border-top-right-radius: 0;
    border-top-left-radius: 0.5em;
  }

  .container section:last-child,
  .container section:last-child header {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0.5em;
  }

  .container section {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: space-between;
  }

  .container section header {
    height: 100%;
    width: 30%;
    text-align: center;
    border: none;
  }

  .container section div {
    width: 70%;
  }

  .container section:nth-child(1) div div,
  .container section:nth-child(2) div div {
    width: 100%;
  }

  .container section > div:last-child {
    height: 100%;
  }

  .container section[data-section='object'] {
    width: 100%;
  }

  .container section[data-section='available_fields'] {
    width: 100%;
  }

  .container section[data-section='selected_fields'] {
    width: 100%;
  }
}
