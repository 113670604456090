.selectedFieldsContainer {
  display: flex;
  flex-direction: column;
  /* height: 400px; */
  /* overflow-y: auto; */
  width: 100%;
}

.selectedFieldsContainer li {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
}

.selectedFieldsContainer .fieldItem {
  all: unset;
  padding: 0.8em;
  /* border-bottom: 1px solid var(--brand-accent-color-gray); */
  display: flex;
  align-items: center;
  position: relative;
  cursor: default !important;
  flex: 1;
}

.selectedFieldsContainer .fieldItem .drag {
  height: 32px;
  width: 16px;
  position: absolute;
  top: 5px;
  left: 0;
  background-image: url('../../../../../../images/drag-indicator.png');
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: 0px 8px;
  cursor: grab;
  user-select: none;
  z-index: 100;
  filter: invert(86%) sepia(8%) saturate(2%) hue-rotate(318deg) brightness(94%) contrast(94%);
}

.selectedFieldsContainer .fieldItem:hover .drag {
  filter: none;
}

.selectedFieldsContainer .fieldItem .beingGrabbed {
  opacity: 0.5 !important;
  border-style: dashed !important;
}

.beingDraggedOver {
  border: 1.5px solid var(--brand-accent-color-blue-lighter-1) !important;
}

.selectedFieldsContainer .fieldItem label {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis !important;
  text-align: left !important;
  flex: 1;
  font-weight: normal !important;
  /* cursor: pointer; */
  margin-left: 0.5em;
}

.selectedFieldsContainer .fieldItem span.mandatory {
  color: var(--brand-accent-color-gray);
}

.selectedFieldsContainer .fieldItem span.mandatory[data-mandatory='true'] {
  color: var(--brand-accent-color-blue) !important;
}

.mandatoryToggleIcon {
  display: inline-block;
  height: 0.7em !important;
  width: 0.7em;
  border: solid 1px var(--brand-accent-color-gray);
  border-radius: 0.7em;
  padding: auto;
  text-align: center;
  cursor: pointer;
  margin-left: 0.5em;
}

.mandatoryToggleIcon[data-mandatory='true'] {
  border: solid 1px var(--brand-accent-color-blue) !important;
}

.selectedFieldsContainer .fieldItem[data-field-selected='true'] {
  background-color: var(--brand-accent-color-blue-lighter-2);
}

.selectedFieldsContainer .fieldItem:hover {
  background-color: var(--brand-accent-color-blue-lighter-2);
  cursor: move;
}

.selectedFieldsContainer .fieldItem:hover span.mandatory {
  color: var(--text-subdued);
  border: solid 1px var(--text-subdued);
}

.removedIcon {
  height: 1em !important;
  vertical-align: middle;
  cursor: pointer;
  filter: invert(28%) sepia(30%) saturate(1437%) hue-rotate(317deg) brightness(96%) contrast(96%);
  margin-left: 0.5em;
}

span.errorType2SchemaLoading {
  color: red;
  cursor: pointer;
}

span.errorType2SchemaLoading img {
  height: 0.9em;
  vertical-align: middle;
}
