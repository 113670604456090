span.errorType2SchemaLoading {
  color: red;
  cursor: pointer;
}

span.errorType2SchemaLoading span {
  font-size: 0.9em;
  vertical-align: middle;
}

.chip {
  max-width: 200px;
}
.chipsTitle {
  max-width: 190px !important;
  cursor: grab;
}

.beingGrabbed {
  opacity: 0.5 !important;
  border-style: dashed !important;
}

.beingDraggedOver {
  border: 1.5px solid var(--brand-accent-color-blue-lighter-1) !important;
}

.chipTitleContainer {
  display: flex;
  align-items: center;
  position: relative;
}

.chipTitleContainer .drag {
  height: 16px;
  width: 14px;
  margin-left: -5px;
  margin: 0 4px 0 -5px;
  background-image: url('../../../../../../images/drag-indicator.png');
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: left;
  cursor: grab;
  user-select: none;
  filter: invert(86%) sepia(8%) saturate(2%) hue-rotate(318deg) brightness(94%) contrast(94%);
}

.chipTitleContainer:hover .drag {
  filter: none;
}

.chipTitleContainer > span.title {
  font-size: var(--font-size-decrease-1);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.moreField {
  border-radius: 0.3em;
  background-color: var(--brand-accent-color-blue-lighter-2);
  font-size: 0.9em;
  max-width: 150px;
  display: flex;
  align-items: center;
}

.moreField span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 130px;
  font-size: 0.9em;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  flex: 1;
  padding: 0.3em 0.4em 0.3em 0.6em;
}
