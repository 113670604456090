.parent {
  position: relative;
  display: inline-flex; /* Necessary because we're having a flex item inside */
  vertical-align: middle;
  width: 100%;
}

.popup {
  position: absolute;
  top: 1.8em;
  left: -1em;
  display: none;
  flex-direction: column;
  visibility: hidden;
  z-index: 300;
  padding: 0.5em 1em;
  border: var(--luru-border);
  box-shadow: var(--luru-box-shadow);
  border-radius: 0.3em;
  background-color: var(--background-color);
  opacity: 0;
  /* transition: all 0.15s ease-in-out; */
}

.popup {
  position: absolute;
  top: 1.8em;
  left: 0em;
  width: 303px;
  height: 400px;
}

.buttonPopup {
  position: absolute;
  top: 1.8em;
  left: -0.5em;
  width: 280px;
  height: 300px;
}

/* @media (max-width: 600px) {
  .popup,
  .buttonPopup {
    top: 1.8em;
    left: -0.5em;
    height: 300px;
    width: 300px;
  }
}
 */
@media (min-width: 600px) {
  .popup,
  .buttonPopup {
    top: 1.8em;
    left: 0;
    width: 300px;
    height: 400px;
  }
}

.modalPopup {
  position: fixed;
  top: 25%;
  left: 1em;
  width: (calc(100% - 1em));
  height: 50%;
}

@media (min-width: 600px) {
  .buttonPopup,
  .popup {
    width: 450px;
    height: 400px;
    min-height: 400px;
  }
  .popup {
    left: 0;
  }
  div[data-luru-role='global-search-main'] .popup {
    left: -5em;
  }
  .modalPopup {
    left: 25%;
    width: 50%;
  }
}

@media (min-width: 900px) {
  .buttonPopup,
  .popup {
    width: 450px;
    height: 400px;
    min-height: 400px;
  }
  .popup {
    left: 0;
  }
  div[data-luru-role='global-search-main'] .popup {
    left: -5em;
  }
  .modalPopup {
    left: 25%;
    width: 50%;
  }
}

div[data-luru-role='global-search-main'] .popup {
  left: 0;
}

div[data-luru-role='task-assignee'] .buttonPopup,
div[data-luru-role='task-assignee'] .popup {
  left: 0;
  height: 200px;
  min-height: 200px;
}

@media (min-width: 600px) {
  div[data-luru-role='task-assignee'] .popup,
  div[data-luru-role='task-assignee'] .buttonPopup {
    top: 1.8em;
    height: 300px;
    min-height: 300px;
  }
}

div[data-luru-role='task-crm-link'] .popup {
  height: 250px;
  top: calc(1.8em - 120px);
  left: 0;
}

@media (min-width: 600px) {
  div[data-luru-role='task-crm-link'] .popup {
    height: 400px;
    top: calc(1.8em - 220px);
    left: 0em;
  }
}

.popup > *:first-child {
  margin-top: 0.5em;
}

.popup > * + * {
  margin-top: 0.3em;
}

@media (min-width: 600px) {
  .popup > * + * {
    margin-top: 1em;
  }
}

.popup input[type='search'] {
  display: block;
  width: 100%;
  padding: 0.3em 1em;
}

.popup header {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
}

.hideButtonRecord {
  display: none;
}

.hideOrRecord {
  display: none;
}

.focusedInput input[type='search'] {
  width: 100%;
}

.createRecordSelect {
  width: 100%;
  margin: 0px;
  background-color: var(--brand-accent-color-blue) !important;
  color: white !important;
  font-size: 12px;
  padding: 5px 10px;
  font-weight: var(--bold-font-weight);
  /* border: 1px solid teal; */
  height: 30px;
}

.searchBoxWidth {
  width: 60%;
  transition: width 0.2s ease-in-out;
}

.searchBoxWidthIncrease {
  width: 100%;
  transition: width 0.2s ease-in-out;
}

.createButtonRender div {
  display: flex;
}

.hideFilters {
  display: none;
  /* visibility: hidden; */
}

.results {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 0;
}

.results ul {
  display: block;
  width: 100%;
  list-style-type: none;
  align-self: flex-start;
}

li[data-sor-result='true'][data-user-can-edit='false'] {
  color: var(--text-subdued);
}

li[data-sor-result='true'],
li[data-sor-result='true'] * {
  font-size: 0.95em;
}

li[data-sor-result='true'] {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.3em 0 0;
  cursor: pointer;
}

@media (min-width: 600px) {
  li[data-sor-result='true'] {
    padding: 0.5em 1em;
  }
  li[data-sor-result='true'],
  li[data-sor-result='true'] * {
    font-size: 1em;
  }
}

li[data-sor-result='true'] > * {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

li[data-sor-result='true'][data-user-can-edit='false']:hover {
  cursor: auto;
  color: var(--text-subdued);
}

li[data-sor-result='true']:hover {
  background-color: var(--brand-accent-color-blue-lighter-2);
  color: var(--brand-accent-color-blue-lighter-2-contrast);
  cursor: pointer;
}

li[data-sor-result='true'] img {
  height: 2em;
  vertical-align: middle;
}

.noresults,
.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  gap: 1em;
}

.retryButton {
  width: 10em;
  margin: 0 auto;
}

.loaded {
  justify-self: center;
}

.visible {
  visibility: visible;
  opacity: 1;
  display: flex;
}

.popup footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: auto;
  border-top: 1px solid var(--brand-accent-color-gray);
  padding-top: 0.3em;
}

.popup footer * {
  font-size: 0.8em;
}

li[data-sor-result='true'][data-user-can-edit='false'].selected {
  background-color: var(--brand-accent-color-blue-lighter-1);
  color: var(--brand-accent-color-blue-lighter-2);
}

.selected:hover,
.selected {
  background-color: var(--brand-accent-color-blue-lighter-1);
  color: var(--brand-accent-color-blue-lighter-2);
}

/* .selected img {
  filter: grayscale() invert(0) contrast(1000%) saturate(100%);
} */

.buttonBorder {
  border: 1px solid lavender;
  border: 1px solid var(--brand-accent-color-blue-lighter-2);
}

.label {
  font-size: var(--font-size-decrease-1);
  font-weight: var(--bold-font-weight);
  border-bottom: 0.5px solid var(--brand-accent-color-blue-lighter-1);
  padding-bottom: 0.2em;
}

.warningStatusButton {
  border: 1px solid var(--warning-color) !important;
  color: var(--brand-accent-color-pink-darker-2) !important;
}

img.warningIcon {
  filter: invert(21%) sepia(93%) saturate(2409%) hue-rotate(321deg) brightness(87%) contrast(88%);
  width: 1.2em;
  position: absolute;
  right: 0.5em;
  z-index: 999;
}
