.parent {
  display: inline-block;
  margin: 0;
  border: 0;
  padding: 0;
  list-style-type: none;
}

.pointer {
  cursor: pointer;
}
