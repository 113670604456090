div.parent {
  position: relative;
  margin-top: 1rem;
}

div.tiles {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1em;
  padding: 0 0.5em 1em 0.5em;
  max-height: 100px;
  overflow: auto;
}

li.tile {
  padding: 5px 10px;
  border-radius: 0.3em;
  background-color: var(--brand-accent-color-gray);
  font-weight: var(--bold-font-weight);
  font-size: 0.8em;
  cursor: pointer;
  list-style: none;
  max-width: 20em;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@media (max-width: 599px) {
  li.tile {
    max-width: 100%;
  }
}

li.selectedTile {
  background-color: var(--green-color);
  color: white;
}

li.specificity1 {
  background-color: var(--brand-accent-color-blue-lighter-2);
}

li.specificity2 {
  background-color: var(--brand-accent-color-blue-lighter-1);
  color: white;
}

li.specificityN {
  background-color: var(--brand-accent-color-blue);
  color: white;
}

div.containerStyle {
  border: 1px solid var(--brand-accent-color-blue-lighter-3);
  border-radius: var(--luru-border-radius);
  background-color: var(--app-bg-color);
  box-shadow: none;
  width: 98%;
  margin: 0 auto;
  padding: 0.3em;
}

div.contentStyles {
  max-height: 130px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: -0.2em;
  margin-left: 1em;
}

div.containerStyle > div {
  overflow: visible;
}

div.containerStyle > div > div {
  font-size: 0.9em !important;
}

div.containerStyle > div > img {
  height: 1.3em !important;
}

div.containerStyle > div {
  padding: 1em 0 0 0;
}

div.autoLinkMessage {
  display: flex;
  align-self: center;
  color: var(--brand-accent-color-blue);
  font-size: 0.7rem;
  font-weight: var(--bold-font-weight);
  text-overflow: ellipsis;
  justify-content: center;
  align-items: center;
  margin-left: 1em;
}

div.autoLinkMessage > span:last-child {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

div.autoLinkMessage span {
  font-size: 0.85rem;
}

div.autoLinkMessage span img {
  height: 0.85rem;
  filter: var(--brand-accent-color-blue-filter);
}

div.disableEditing {
  cursor: not-allowed;
}

div.disableEditing div {
  cursor: not-allowed;
}

div.disableEditing div div {
  cursor: not-allowed;
}

div.disableEditing div div li {
  cursor: not-allowed;
}

span.empty {
  color: var(--brand-accent-color-blue);
  cursor: pointer;
}

div.parentMessage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

div.parentMessage > div:nth-child(2) {
  display: flex;
  align-items: center;
  gap: 3px;
}

div.parentMessage > div:nth-child(2) > span:first-child {
  max-width: fit-content;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

div.parentMessage > div:nth-child(2) > span:last-child {
  width: 1.3em;
  cursor: pointer;
}

@media (min-width: 600px) {
  div.containerStyle {
    width: 98.3%;
    padding: 0.3em 1em;
  }

  div.parentMessage > div:nth-child(2) > span:first-child {
    max-width: 300px;
  }
}

@media (max-width: 599px) {
  div.containerStyle {
    width: 95%;
    padding: 0.3em 1em;
  }

  div.containerStyle > div {
    margin-left: -0.5em;
  }

  div.parentMessage > div:nth-child(2) > span:first-child {
    max-width: 250px;
  }
}

@media (min-width: 900px) {
  div.containerStyle {
    width: 98%;
  }
}

@media (max-width: 1050px) {
  div.autoLinkMessage > span:last-child {
    font-size: 85%;
    max-width: 350px;
  }
}

@media (max-width: 850px) {
  div.autoLinkMessage > span:last-child {
    font-size: 80%;
    max-width: 250px;
  }
}

@media (max-width: 450px) {
  div.autoLinkMessage > span:last-child {
    font-size: 75%;
    max-width: 100px;
  }
}

div.templateRefreshing {
  display: flex;
  margin: auto;
  gap: 10px;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* border: 1px solid teal; */
}
