.actionIcon {
  margin-left: auto;
  font-size: 1.5em;
}

.main .label {
  width: 100%;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

ul.menuList {
  overflow-y: auto;
  overflow-x: hidden;
}

div.filterBoxContainer {
  background-color: var(--background-color);
}

input.filterBox {
  width: calc(100% - 1.5em);
  margin: 0.5em 0.75em;
}

li.highlightedItem {
  background-color: var(--brand-accent-color-blue-lighter-2) !important;
}

ul.menuList li:hover > label {
  color: white !important;
}

img[data-role='list-item-icon'] {
  width: 1.4em;
  height: 1.4em;
  margin: 0;
  margin-right: 0.5em;
  padding: 0;
  background-color: var(--background-color);
  filter: var(--brand-accent-color-blue-filter);
}

ul.menuList {
  background-color: var(--background-color);
}

ul.menuList li {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: baseline;
  align-items: center;
  padding-left: 2.25em;
  background-color: var(--background-color);
}

ul.menuList.toggleSelectAllHidden li {
  padding-left: 0.7em;
  padding-right: 0.7em;
}

ul.menuList li.withIcon {
  padding-left: 0.75em;
}

ul.menuList li label {
  flex-basis: calc(100% - 1.3em);
  flex-grow: 0;
  overflow: hidden;
  font-weight: normal;
  color: var(--foreground-color);
  text-overflow: ellipsis;
  cursor: pointer;
}

ul.menuList li.toggleSelectAll {
  margin-left: -0.8em;
  /* border-bottom: 1px solid var(--brand-accent-color-gray); */
  padding-left: 1.5em;
}

ul.menuList li.toggleSelectAll label {
  font-weight: var(--bold-font-weight);
}

label.selectedItemDisplay {
  display: flex;
  flex-direction: row;
  justify-content: baseline;
  align-items: center;
}

label.selectedItemDisplay img {
  margin-right: 0.3em;
}

img[data-role='checked-icon'],
span[data-role='nonchecked-icon'] {
  width: 1.2em;
  height: 1.2em;
  margin-right: 0.5em;
}

div.buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  /* border-top: 1px solid var(--brand-accent-color-gray); */
  padding: 0 1em;
}

div.buttons button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  width: 40%;
  margin: 0.5em 0;
  border-radius: 0.3em;
  padding: 0.2em 1em;
  outline: 0;
  font-weight: var(--bold-font-weight);
  font-size: 0.8em;
  background-color: var(--brand-accent-color-gray-lighter-2);
}

/* div.buttons button:hover {
  background-color: var(--brand-accent-color-blue-lighter-2);
} */

div.buttons button img {
  width: 1.2em;
  height: 1.2em;
  vertical-align: middle;
}

[data-luru-multiselect-disabled='true'] {
  cursor: default;
}

[data-luru-multiselect-disabled='true'] img {
  filter: invert(69%) sepia(99%) saturate(1%) hue-rotate(356deg) brightness(96%) contrast(98%);
}

ul.menuList li[data-luru-multiselect-label-disabled='true'] label {
  color: var(--brand-accent-color-gray);
}

ul.menuList li.beingGrabbed {
  cursor: grabbing;
}

ul.menuList li {
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
}

ul.menuList li.beingGrabbed {
  opacity: 0.5;
  border-style: dashed;
  border-width: 2px;
  border-color: var(--brand-accent-color-gray);
}

ul.menuList li.beingDraggedOverBottom {
  border-bottom: 2px solid var(--brand-accent-color-blue);
}

ul.menuList li.beingDraggedOverTop {
  border-top: 2px solid var(--brand-accent-color-blue);
}

ul.menuList li.draggable[draggable='true'] {
  position: relative;
}

ul.menuList li.draggable[draggable='true']::after {
  content: ' ';
  height: 32px;
  width: 16px;
  position: absolute;
  top: -2px;
  right: 11px;
  display: none;
  background-image: url('../../../images/drag-indicator.png');
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: 0px 8px;
  cursor: pointer;
  user-select: none;
  z-index: 100;
  opacity: 0.5;
}

ul.menuList li.draggable[draggable='true']:hover::after {
  display: block;
}

div.doneButtonChange button {
  background-color: var(--brand-accent-color-beige);
  border: 1px solid var(--brand-accent-color-beige-darker-2);
}

div.emptyValue {
  padding: 0.6em;
  text-align: center;
}
