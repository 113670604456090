.luruTaskInfo {
  margin-left: 0.5em;
}

.luruTaskInfo > button,
.luruTaskInfo > button > * {
  --font-size: var(--font-size-decrease-1);
}

.luruTaskInfo > button {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-right: 0.5em;
  border: 1px solid var(--brand-accent-color-gray);
  border-radius: calc(0.5 * 1.1 * var(--font-size));
  padding: 0 1em;
  background-color: var(--background-color-secondary);
  font-size: var(--font-size);
  color: var(--foreground-color);
  user-select: none;
  cursor: pointer;
}

.luruTaskInfo > button > * {
  font-size: var(--font-size);
}

.separator {
  margin: 0 0.7em;
  font-size: var(--font-size-decrease-2);
}

.luruTaskInfo > button span.icon {
  margin-right: 0.3em;
  font-size: var(--font-size-decrease-1);
}

span.icon > img {
  height: var(--font-size-decrease-1);
  margin-right: 0.3em;
}
