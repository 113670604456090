.parent {
  display: block;
  border-radius: var(--luru-border-radius);
  transition: background-color 0.15s ease-in-out;
}

.edited {
  padding: 0.3em 0;
  background-color: var(--brand-accent-color-orange-darker-1);
}

.parent > label {
  display: block;
  padding-left: 3px;
  font-weight: var(--bold-font-weight);
  color: var(--brand-accent-color-blue-darker-1);
}

.valueContainer {
  --input-height: calc((1.15 + 0.5 * 2) * 1rem + 2px);
  --offset: 3px;
  position: relative;
  display: inline-block;
  width: calc(10em + 2 * var(--offset));
  height: calc(var(--input-height) + 2 * var(--offset));
}

.valueContainer[data-luru-field-type='textarea'] {
  --input-height: calc(4 * ((1.15 + 0.3 * 2) * 1em + 2px));
}

.normal {
  width: 50%;
}

.large {
  width: 80%;
}

.wide {
  width: 100%;
}

.referenceInput input[type='search'],
.datePickerContainer,
.multiObjectBox {
  width: 100%;
  height: var(--input-height);
  margin: 0;
  padding: 0 0.5em;
  border: var(--control-border-width) solid transparent;
  border-radius: var(--luru-border-radius);
  outline: none;
  background-color: transparent;
  text-overflow: ellipsis;
}

.datePickerTextBox {
  width: 100%;
}

.datePickerContainer {
  padding: 0;
}

.multiObjectBox {
  border: 1px solid var(--brand-accent-color-gray);
  border-radius: var(--luru-border-radius);
}

.referenceInput input[type='search'] {
  border: 1px solid var(--brand-accent-color-gray);
  border-radius: var(--luru-border-radius);
  padding-left: 1em;
  padding-right: 1em;
}

.referenceInput input[type='search']:focus {
  border: 1px solid var(--brand-accent-color-blue);
  /* outline: 3px solid var(--brand-accent-color-gray-lighter-1); */
}

.edit {
  position: absolute;
  top: var(--offset);
  right: var(--offset);
  bottom: var(--offset);
  left: var(--offset);
  padding: 0;
  background-color: transparent;
}

/* .edit * {
  background-color: transparent;
} */

.info {
  position: absolute;
  /* visibility: hidden; */
  top: var(--offset);
  right: var(--offset);
  bottom: var(--offset);
  left: var(--offset);
  text-align: right;
}

.select {
  display: none; /* Temporarily hide select layer until complete implementation */
  position: absolute;
  top: var(--offset);
  right: var(--offset);
  bottom: var(--offset);
  left: var(--offset);
  outline: var(--offset);
  background-color: var(--modal-background-color);
  backdrop-filter: var(--modal-background-filter);
  transition: var(--modal-background-transition);
}

.select:focus {
  border: 2px solid var(--brand-accent-color-blue);
}

.visible {
  visibility: visible;
}

.idle {
  background-color: transparent;
  transition: 1s linear background-color;
}

.success {
  position: absolute;
  top: var(--offset);
  right: var(--offset);
  bottom: var(--offset);
  left: var(--offset);
  background-color: lightgreen;
}

.error {
  position: absolute;
  top: var(--offset);
  right: var(--offset);
  bottom: var(--offset);
  left: var(--offset);
  background-color: lightpink;
}

.loading {
  position: absolute;
  top: var(--offset);
  right: var(--offset);
  bottom: var(--offset);
  left: var(--offset);
  display: flex;
  align-items: center;
  padding-left: 0.5em;
  font-style: italic;
  /* background-color: lightyellow; */
  background: linear-gradient(90deg, var(--brand-accent-color-blue-lighter-2), transparent);
  background-size: 10%;
  background-position: -100% -100%;
  background-repeat: no-repeat;
  animation: loading 1s infinite;
}

@keyframes loading {
  0% {
    background-size: 10%;
  }
  100% {
    background-size: 100%;
  }
}

.alert {
  position: absolute;
  top: calc(1em + var(--offset));
  right: calc(0.5em + var(--offset));
  width: 2em;
  height: 1em;
  padding: 0;
}

.percent .alert,
.int .alert,
.integer_noformat .alert,
.currency .alert,
.double .alert {
  left: calc(0.5em + var(--offset));
}

.alert img {
  height: 1em;
}

.alert img[data-role='revert-value'] {
  cursor: pointer;
}

/* Luru select box */
.edit div[data-luru-role='luru-popup-menu'],
.edit div[data-luru-role='luru-popup-menu'] > button {
  width: 100%;
}

.edit div[data-role='popup-menu'] {
  z-index: 500;
}

.selectBox {
  height: var(--input-height);
  border: 1px solid var(--brand-accent-color-gray);
  border-radius: var(--luru-border-radius);
  padding: 0.44em 1em;
}

.selectBox:hover {
  background-color: var(--brand-accent-color-blue-lighter-2);
  color: var(--brand-accent-color-blue-lighter-2-contrast);
}

.parent .warning {
  color: red;
}

span.selectBoxLevel1 {
  display: block;
  color: var(--text-subdued);
  font-size: 0.7em;
  font-weight: var(--bold-font-weight);
  line-height: 0.9;
}

span.selectBoxLevel2 {
  display: block;
}

div.selectBoxLabel {
  display: flex;
  align-items: center;
}

span.selectBoxLabelLevel1 {
  min-width: 5em;
  color: var(--text-subdued);
  font-size: 0.7em;
  font-weight: var(--bold-font-weight);
  overflow: hidden;
  text-overflow: ellipsis;
}

span.selectBoxLabelLevel2 {
  margin-left: 0.3em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fieldCreateUpdateError > .valueContainer > .edit > input,
.fieldCreateUpdateError > .valueContainer > .edit > div > button {
  border: 2px solid red;
}

.enumSelectBoxPopupMenu {
  width: 100%;
}
