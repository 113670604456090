.userLogo {
  display: table-cell;
  height: 2.5em;
  width: 2.5em;
  max-width: 2.5em;
  overflow: hidden;
  border-radius: 1.25em;
  background-color: var(--brand-accent-color-blue-lighter-2);
  color: var(--brand-accent-color-blue-lighter-2-contrast);
  line-height: 1em;
  vertical-align: middle;
  text-align: center;
}

.userLogoImage {
  display: block;
  width: 2.5em;
  height: 2.5em;
  clip-path: circle();
}
