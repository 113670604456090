.parent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: #8ec5fc;
  background-image: linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 100%);
}

.parent > div {
  width: 720px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: white;
  border-radius: 0.5rem;
  padding: 1rem 2rem;
  box-shadow: var(--luru-box-shadow);
}

.parent > div > header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.parent > div > header > h1 {
  color: var(--brand-accent-color-blue);
  font-size: var(--font-size-increase-2);
  font-weight: var(--bold-font-weight);
}

.parent > div > header > img {
  height: 1.5rem;
}

.parent > div > section {
  height: 320px;
  margin: 1rem 0;
  user-select: none;
}

.parent > div > section > .wrapper {
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.parent > div > section > .wrapper > h2 {
  /* width: 100%; */
  text-align: left;
  margin-bottom: 2rem;
  margin-top: -2rem;
  font-size: var(--font-size-increase-1);
  font-weight: var(--bold-font-weight);
}

.parent > div > section > p {
  font-size: var(--font-size-increase-1);
}

.parent > div > section > ol {
  margin: 1em 0 0 1.5em;
  font-size: var(--font-size-increase-1);
}

.parent > div > footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.parent > div > footer button {
  font-weight: var(--bold-font-weight);
}
