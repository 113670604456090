.main {
  position: relative;
  border: 1px solid var(--brand-accent-color-gray);
  border-radius: 0.3em;
  padding: 0.3em 0.7em;
  background-color: var(--background-color);
  color: var(--text-color);
  font-weight: var(--bold-font-weight);
  outline: none;
  cursor: pointer;
  user-select: none;
}

.main::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  border-radius: 0.3em;
  background-color: var(--brand-accent-color-gray);
  content: ' ';
  opacity: 0;
  transition-duration: 0.15s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transform: scale(0);
  transition-property: transform, opacity;
  z-index: 1;
}

.main:hover::before {
  opacity: 0.15;
  transform: scale(1);
}

.main:active::before {
  opacity: 0.25;
}
