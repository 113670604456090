.tabs {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
}

.tabs > nav {
  flex-grow: 0;
  border-bottom: 1px solid var(--brand-accent-color-gray-lighter-1);
}

.tabs > nav > ul {
  display: flex;
  list-style-type: none;
  gap: 1rem;
  margin: 0;
  padding: 0;
}

.tabs > nav > ul > li {
  margin-bottom: -1px;
  padding: 0.3em 1em;
  cursor: pointer;
}

.tabs > nav > ul > li:hover {
  color: var(--brand-accent-color-blue);
}

.tabs > nav > ul > li.selected {
  border-bottom: 2px solid var(--brand-accent-color-blue);
  font-weight: var(--bold-font-weight);
  color: var(--brand-accent-color-blue);
}

.tabs > main {
  display: flex;
  flex-direction: column;
  height: 100%;
  border: none;
}

.tabs > main > div {
  flex-grow: 1;
  overflow: auto;
  height: 0px;
}

.tabs > main > div.selected {
  display: block;
}

.tabs > main > div:not(.selected) {
  display: none;
}
