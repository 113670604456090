.parent {
  position: relative;
  display: inline-block;
  cursor: default;
}

.icon {
  position: absolute;
  top: calc(50% - 0.6em);
  right: 0.3em;
  font-size: var(--font-size-increase-1);
  cursor: pointer;
}

.icon img {
  width: 1.2em;
}

.textBox,
.textBox:focus,
.textBox[readonly]:focus {
  width: 100%;
  background-color: transparent;
  border: 1px solid var(--brand-accent-color-gray);
  outline: none;
}

.textBox[readonly] {
  background-color: inherit;
}

.parent [data-role='popup-menu'] {
  width: 100%;
}

.calendar {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.3em 1em;
}

.calendar header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.calendar header.centered {
  justify-content: center;
}

.calendar header button {
  height: 1.5em;
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--text-subdued);
  font-size: 1em;
  cursor: pointer;
}

.calendar .monthName > button,
.calendar .yearRangeTitle {
  font-weight: var(--bold-font-weight);
  font-size: 0.9em;
  color: var(--foreground-color);
  cursor: pointer;
}

.calendar .weekDays {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  user-select: none;
}

.calendar .weekDays div {
  margin-top: 0.7em;
  padding: 0.3em;
  text-transform: uppercase;
  font-weight: var(--bold-font-weight);
  font-size: 0.7em;
}

.calendar .weekContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.calendar .dayContainer {
  width: 2em;
  border: 1px solid transparent;
  border-radius: 0.3em;
  padding: 0.35em 0;
  background-color: transparent;
  text-align: center;
  font-size: 0.8em;
  color: var(--brand-accent-color-gray);
  cursor: pointer;
}

.calendar .dayContainer:hover {
  background-color: var(--brand-accent-color-blue-lighter-2);
  font-weight: var(--bold-font-weight);
}

.calendar .dayContainer.activeMonth {
  color: var(--foreground-color);
}

.calendar .yearCell.currentYear,
.calendar .monthCell.currentMonth,
.calendar .dayContainer.currentDate {
  border: 1px solid var(--brand-accent-color-blue-lighter-1);
  background-color: var(--brand-accent-color-blue-lighter-2);
  font-weight: var(--bold-font-weight);
}

.moreActions {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.calendarMoreActionButton {
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--brand-accent-color-blue);
  font-size: 0.8em;
  cursor: pointer;
}

.calendar .yearContainer,
.calendar .monthContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  margin: 0.8em 0;
}

.calendar .yearRow,
.calendar .monthRow {
  width: 100%;
  height: 2.5em;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
}

.calendar .monthRow {
  justify-content: space-between;
}

.calendar .yearCell,
.calendar .monthCell {
  width: 5em;
  border: 1px solid transparent;
  border-radius: 0.3em;
  padding: 0.3em 1em;
  background-color: transparent;
  font-size: 0.9em;
  font-weight: var(--bold-font-weight);
  color: var(--brand-accent-color-blue);
  text-align: center;
  cursor: pointer;
}

.calendar .monthCell:hover,
.calendar .yearCell:hover {
  background-color: var(--brand-accent-color-blue-lighter-2);
}

.clearButton {
  visibility: hidden;
}

.disabled {
  background-color: var(--disabled-background-color) !important;
}
