.actionIcon {
  margin-left: auto;
}

.actionIcon > img {
  vertical-align: middle;
  width: 1em;
  margin-right: 0.1em;
}

ul.menuList {
  overflow-y: auto;
  overflow-x: hidden;
}

div.filterBoxContainer {
  background-color: var(--brand-accent-color-blue-lighter-2);
}

input.filterBox {
  width: calc(100% - 1.5em);
  margin: 0.5em 0.75em;
}

ul.level2 {
  padding-left: 0;
}

.parent .label {
  width: 100%;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

label.selectedItemDisplay {
  display: flex;
  flex-direction: row;
  justify-content: baseline;
  align-items: center;
}

label.selectedItemDisplay img {
  margin-right: 0.3em;
}

li.highlightedItem {
  background-color: var(--brand-accent-color-blue-lighter-2);
}

ul.menuList {
  height: calc((2.5em + 2px) * 7 + 1px);
  max-height: calc((2.5em + 2px) * 7 + 1px);
  overflow: auto;
}

ul.menuList > li {
  padding: 0.3em 0 0.3em 0.5em;
}

ul.menuList > li > label {
  font-weight: var(--bold-font-weight);
}

ul.menuList > li:hover {
  background-color: var(--background-color);
}

ul.level2 {
  width: 100%;
}

ul.level2 > li {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

li.level1ListItem {
  width: 100%;
}
