.main {
  /* Relative positioning required for hover effect */
  position: relative;
  /* Flex display settings */
  display: flex;
  flex-direction: row;
  align-items: center;
  /* Dimensions */
  height: 1.8em;
  width: 10em;
  /* Other standard settings */
  border: none;
  border-radius: 0.25rem;
  padding: 0.3em 1em 0.3em 1em;
  /* Default appearance */
  background-color: var(--brand-accent-color-blue-lighter-2);
  color: var(--brand-accent-color-blue-lighter-2-contrast);
  outline: none;
  cursor: pointer;
  user-select: none;
  /* Since dimensions are specified by oval button, handle overflow */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (min-width: 600px) {
  .main {
    width: 12em;
  }
}

@media (min-width: 900px) {
  .main {
    width: 12em;
  }
}

.main:disabled {
  cursor: default;
}

.main:hover {
  /* background-color: var(--brand-accent-color-blue-lighter-1);
  color: white; */
  /* box-shadow: 0 1px 2px var(--brand-accent-color-gray-darker-1) !important; */
}

.main.subdued {
  background-color: var(--brand-accent-color-gray-lighter-2);
  color: var(--foreground-color);
}

.blueVarient {
  background-color: var(--brand-accent-color-blue-lighter-1);
  color: #ffffff;
  font-weight: var(--bold-font-weight);
  border: 1px solid var(--brand-accent-color-blue);
}

.blueVarient:hover {
  background-color: var(--brand-accent-color-blue-lighter-2);
  color: var(--brand-accent-color-blue);
}

.lightBlueVarient {
  background-color: var(--brand-accent-color-blue-lighter-2);
  color: var(--brand-accent-color-blue-darker-1) !important;
  font-weight: var(--bold-font-weight);
  border: 1px solid var(--brand-accent-color-blue);
}

.main.centered {
  justify-content: center;
}

.main::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  border-radius: 0.3em;
  background-color: var(--brand-accent-color-gray);
  content: ' ';
  opacity: 0;
  transition-duration: 0.15s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transform: scale(0);
  transition-property: transform, opacity;
  z-index: 1;
}

.main:hover::before {
  opacity: 0.15;
  transform: scale(1);
}

.main:active::before {
  opacity: 0.45;
}

.main img {
  height: 1.25em;
}

.main.transparent {
  background-color: transparent;
}

.pushRight {
  margin-left: auto;
}
