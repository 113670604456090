body {
  overflow: hidden !important;
}

.wrapperParent {
  --header-height: 2.6em;
  position: relative;
  height: calc(100% - 2.6em);
  padding: 1em 1em 1em 0.5em;
  background-color: white;
}

.notification {
  position: absolute;
  width: 65%;
  left: 35%;
  margin: 0 auto;
  border: 0;
  box-shadow: none;
  background-color: var(--background-color);
  color: var(--foreground-color);
  text-align: center;
  z-index: 100;
}

.popInButton {
  position: fixed;
  width: 36px;
  height: 36px;
  top: 0;
  right: 12px;
  border: none;
  outline: none;
  background-color: white;
  color: var(--brand-accent-color-gray-darker-2);
  font-size: 24px;
  z-index: 100;
  transform: scaleX(-1);
  cursor: pointer;
}

.popInButton > img {
  filter: invert(40%) sepia(8%) saturate(331%) hue-rotate(173deg) brightness(92%) contrast(89%);
  height: 24px;
}

.message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2em;
  height: 100%;
}

.message button {
  width: 20em;
  margin: 1em 0 5em;
}

.wrapperParent [data-luru-role='floating-sync-button'] button {
  height: 32px;
  right: 2em;
  bottom: 0.4em;
  border-radius: 16px;
  box-shadow: none;
  z-index: 1000;
  transform: scale(0.7);
}

.loading {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.loading .moreDetails {
  color: var(--brand-accent-color-gray);
  font-size: 0.85em;
}

.loadingModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 300;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 0.5em;
  background-color: var(--modal-background-color);
  backdrop-filter: var(--modal-background-filter);
  transition: var(--modal-background-transition);
}

button a:hover {
  color: var(--brand-accent-color-gray-lighter-2);
}

.loadingModal img {
  filter: var(--brand-accent-color-blue-filter);
  animation: refreshing 1s ease-in-out infinite;
}

.loadingMessage {
  position: absolute;
  width: 100%;
  height: 300px;
  top: calc(50% - 150px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;
}

.loadingMessage img {
  width: 100px;
}

@keyframes refreshing {
  100% {
    transform: rotate(360deg);
  }
}
