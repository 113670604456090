.loading,
.noResults {
  width: 15em;
  padding: 1em;
  font-style: italic;
  text-align: center;
}
/*
.main {
  position: relative;
}

.main .loading {
  position: absolute;
  top: 1em;
  right: 0.75em;
  width: 1em;
  height: 1em;
}

[data-luru-menuitem-disabled='true'] {
  cursor: default;
}

[data-luru-menuitem-disabled='true']:hover {
  background-color: transparent !important;
}

.cursor_active {
  background-color: var(--brand-accent-color-blue-lighter-2) !important;
} */

ul.list {
  overflow: auto;
  overflow-x: hidden;
  height: auto !important;
}

ul.list li {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  gap: 0.2em;
  align-items: center;
}

li[data-highlighted='yes'] {
  background-color: var(--brand-accent-color-blue-lighter-2);
}

ul.list li img {
  height: 1.2em;
  vertical-align: middle;
}

ul.list li span {
  vertical-align: middle;
}

span.container {
  position: relative;
}

img.clearIcon {
  display: none;
  position: absolute;
  top: 0.4em;
  right: 2em;
  filter: var(--text-subdued-filter);
  cursor: pointer;
  height: 1.2em;
}

.container:hover img.clearIcon,
div[data-luru-status='focused'] + img.clearIcon {
  display: inline-block;
}
