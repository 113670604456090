.parent {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  align-items: center;
}

.parent a[title='Luru'] img {
  width: 90px;
  margin-bottom: 2em;
}

.info {
  width: 90%;
  margin: 0 auto;
  text-align: center;
  font-size: var(--font-size-increase-1);
}

.parent ul {
  list-style-type: none;
  max-height: 50%;
  overflow-x: hidden;
  overflow-y: auto;
}

.parent button[data-luru-role='note-link'] {
  width: 250px;
  max-width: 250px;
  justify-content: flex-start;
  margin: 0.5em;
  border-radius: 0.5em;
  background-color: var(--brand-accent-color-blue-lighter-2);
  color: var(--brand-accent-color-blue-lighter-2-contrast);
  text-align: left;
}

.parent button[data-luru-role='note-link']:hover {
  background-color: var(--brand-accent-color-blue-lighter-1);
}

.parent button[data-luru-role='note-link']:hover span.left {
  color: white;
}

.parent button[data-luru-role='note-link'] span {
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.left {
  max-width: 160px;
}

.parent button[data-luru-role='note-link'] span.right {
  margin-left: auto;
  border-radius: 0.5em;
  padding: 0.5em;
  background-color: white;
  font-size: 0.7em;
}

button.newNoteButton {
  margin-top: 0.5em;
  border-radius: 2em;
  padding: 0;
}

button.newNoteButton img {
  height: 3em;
}

.newNote {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.newNote h2 {
  margin: 1em 0;
  font-weight: bolder;
  font-size: var(--font-size-increase-1);
}
