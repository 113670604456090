.parent div.display {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: baseline;
  align-items: center;
}

.parent div.display span.text {
  width: 100%;
  display: inline-block;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
}

.parent div.display span.actionIcon {
  margin-left: auto;
}

.parent div.display span.actionIcon > img {
  vertical-align: middle;
  width: 1em;
  margin-right: 0.1em;
}

div.emptyData {
  padding: 0.5em;
}

.disabled {
  background-color: var(--disabled-background-color) !important;
}

.selectedChipsContainer {
  display: flex;
  gap: 0.5em;
  flex-wrap: wrap;
}

.chipTitle {
  padding: 0em 0.6em 0em 0.6em !important;
  font-size: 0.8em !important;
}
