.createRecordDialog {
  margin-top: 1em;
  border-top: 1px solid var(--brand-accent-color-gray);
  padding-top: 1em;
  min-height: 340px;
  padding-bottom: 2em;
}

.actionButtons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 1rem;
  gap: 1rem;
  position: absolute;
  bottom: 1em;
  right: 1em;
}

.actionButtons button[data-role='create-crm-record-cancel-button'],
.actionButtons button[data-role='create-crm-record-save-button'] {
  width: 100px;
}

.actionButtons button[data-role='create-crm-record-save-button'] {
  font-weight: var(--bold-font-weight);
}

.fieldSet {
  max-height: min(60vh, 490px);
  overflow: auto;
}

.divider {
  border: 0.5px solid var(--brand-accent-color-blue-lighter-2) !important;
  margin: 0.7em 0em 0.3em 0em;
}

.fieldsetChooserContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.error {
  color: var(--warning-color);
}

.selected {
  border: 2px solid var(--brand-accent-color-blue);
  border-radius: 0.3em;
}

.alert {
  margin-bottom: 1em;
  padding: 0.3em 1em;
  text-align: center;
  color: red;
  font-size: 0.8em;
}

.quickAccessContainer {
  padding: 0.3em;
}

.modalTitle {
  display: flex;
  align-items: center;
}

.modalTitle span img {
  width: 2em;
  margin-right: 0.1em;
}

.img[data-crm-id='HUBSPOT'] {
  filter: var(--hubspot-icon-filter);
}
