.parent {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--modal-background-color);
  backdrop-filter: var(--modal-background-filter);
  transition: var(--modal-background-transition) opacity 0.15s ease-in-out, right 0.15s ease-in-out;
  font-size: 1rem;
  /* user-select: none; */
  opacity: 1;
}

.minimized {
  visibility: hidden;
  right: -10%;
  backdrop-filter: blur(0px);
  transform: scale(0);
  opacity: 0;
  background: none;
  transition: opacity 0.15s ease-in-out, transform 0.15s ease-in-out, visibility 0.15s ease-in-out;
}

.minimized.slideInParent {
  transition: opacity 0.15s;
}

.dialog {
  position: relative;
  --dialog-width: 300px;
  --dialog-height: 160px;
  justify-self: flex-start;
  min-width: 300px;
  max-width: 80vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  box-shadow: var(--luru-box-shadow);
  border-radius: 0.4em;
  background-color: var(--background-color);
  color: var(--foreground-color);
}

.parent.slideInParent {
  align-items: flex-end;
}

.slideInDialog {
  height: 100vh;
  width: 35vw;
  transform: scale(1) !important;
}

.dialog {
  width: 40%;
}

@media (min-width: 1000px) {
  .dialog {
    width: 60%;
  }
}

.dialog.centered {
  /* top: calc((100% - var(--dialog-height)) / 2); */
  justify-self: center;
}

@media (min-width: 600px) {
  .dialog {
    --dialog-width: 360px;
    --dialog-height: 200px;
  }
}

@media (-webkit-min-device-pixel-ratio: 1.25) {
  .dialog {
    transform: scale(0.9);
  }
}

@media (-webkit-min-device-pixel-ratio: 1.7) {
  .dialog {
    transform: scale(0.87);
  }
}

@media (min-width: 900px) {
  .dialog {
    --dialog-width: 400px;
    --dialog-height: 200px;
  }
}

.title {
  display: flex;
  justify-content: baseline;
  font-size: 0.9em;
}

.titleText {
  padding: 0 0.5em;
  color: var(--brand-color);
  font-weight: var(--bold-font-weight);
  font-size: var(--font-size-increase-2);
  text-transform: capitalize;
}

.titleSpacer {
  margin-left: auto;
}

.title button[data-luru-role='close-dialog-button'] {
  margin-left: 1em;
  border: 0;
  outline: 0;
  padding: 0;
  background-color: transparent;
  color: var(--text-subdued);
  font-size: 1em;
  cursor: pointer;
}

.title button[data-luru-role='close-dialog-button']:hover {
  color: var(--foreground-color);
}

.title button[data-luru-role='close-dialog-button'] img {
  width: 2em;
  filter: var(--brand-accent-color-blue-filter);
}

.content {
  text-align: left;
  padding: 0;
  max-height: 90vh;
  /* overflow: auto; */
}

.slideInDialog .content {
  max-height: calc(100vh - 50px);
  flex-grow: 1;
}

@media (min-width: 600px) {
  .content {
    padding: 0.5em;
  }
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 1rem;
}

@media (max-width: 1150px) {
  .dialog {
    width: 65%;
    font-size: 90%;
  }
}

@media (max-width: 850px) {
  .dialog {
    width: 70%;
    font-size: 80%;
  }
}

@media (max-width: 550px) {
  .dialog {
    width: 85%;
    font-size: 75%;
  }
}

@media (max-width: 450px) {
  .dialog {
    width: 95%;
    font-size: 73%;
  }
}
