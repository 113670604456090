div.parent {
  margin: 1em;
  margin-bottom: 0;
}

div.parent div[data-luru-role='type-ahead-search-box'] input[type='search'] {
  width: 100%;
}

div.parent h1 {
  margin-top: 1em;
  font-size: 1.25em;
  font-weight: var(--bold-font-weight);
}

.records {
  margin-top: 1em;
}

.records > ul {
  padding: 0;
  list-style-type: none;
}

.records > ul > li {
  /* height: 2em; */
  display: flex;
  flex-direction: row;
  justify-content: baseline;
  align-items: center;
  gap: 0.5em;
  margin-top: 0.5em;
  border-radius: 0.3em;
  padding: 0.5em 1em;
  cursor: pointer;
}

.records > ul > li:hover {
  background-color: var(--brand-accent-color-blue-lighter-2);
}

.records > ul > li > span {
  font-weight: 500;
  font-size: 1.1em;
}

.records > ul > li > img {
  width: 1.8em;
}

.records > ul > li > img[data-crm-id='HUBSPOT'] {
  width: 2.1em;
  margin-left: -0.15em;
}

.notesIcon {
  transform: scale(0.85);
}

h2.searchCategoryTitle {
  position: relative;
  margin-top: 0.5em;
  padding-left: 2.5em;
  font-weight: var(--bold-font-weight);
  font-size: var(--font-size-increase-1);
}

h2.searchCategoryTitle > img {
  position: absolute;
  left: 0.5em;
  width: 1.5em;
  filter: invert(74%) sepia(2%) saturate(3%) hue-rotate(318deg) brightness(83%) contrast(87%);
}

h2.searchCategoryTitle > img.noFilter {
  filter: none;
}

h2.searchCategoryTitle > img[data-luru-role='result-icon-NOTE'] {
  transform: scale(0.8);
}

h2.searchCategoryTitle > img[data-luru-role='result-icon-MEETING'] {
  transform: scale(0.7);
}

h2.searchCategoryTitle > img[data-luru-role='result-icon-DEAL'] {
  transform: scale(1.1);
}

h2.searchCategoryTitle > img[data-luru-role='result-icon-ACCOUNT'] {
  transform: scale(1.1);
}

h2.searchCategoryTitle > img[data-luru-role='result-icon-LEAD'] {
  transform: scale(1.1);
}

h2.searchCategoryTitle > img[data-luru-role='result-icon-CONTACT'] {
  transform: scale(1.1);
}

div.searchResults {
  min-height: 2.5em;
  margin: 0.5em 0.5em 0.5em 1.8em;
  padding-bottom: 0.5em;
  border-bottom: 1px solid var(--brand-accent-color-gray);
  font-size: 1rem;
  height: 100px;
  overflow: auto;
}

div.searchResults > div.loading {
  width: 100%;
  padding: 1.5em 0;
  color: var(--brand-accent-color-blue-lighter-1);
  text-align: center;
  vertical-align: middle;
}

div.searchResults > div.error {
  width: 100%;
  padding: 1.5em 0;
  color: var(--brand-accent-color-pink-darker-2);
  display: flex;
  justify-content: center;
  vertical-align: middle;
  font-size: 1rem;
}

div.searchResults ul.resultsList {
  list-style-type: none;
}

div.searchResults ul.resultsList > li {
  display: none;
  border-radius: 0.3em;
  padding: 0.3em 1em;
  cursor: pointer;
}

div.searchResults ul.resultsList > li:hover {
  background-color: var(--brand-accent-color-blue-lighter-2);
}

div.searchResults ul.resultsList > li:nth-child(1),
div.searchResults ul.resultsList > li:nth-child(2),
div.searchResults ul.resultsList > li:nth-child(3),
div.searchResults ul.resultsList > li:nth-child(4),
div.searchResults ul.resultsList > li:nth-child(5) {
  display: block;
}

div.searchResults ul.resultsList.expanded > li {
  display: block;
}

div.searchResults button.loadMore {
  width: 15em;
  display: block;
  margin: 0.5em auto;
  border: 1px solid var(--brand-accent-color-gray);
  border-radius: 0.3em;
  padding: 0.3em 1em;
  font-size: 0.8em;
  background-color: var(--background-color-secondary);
  color: var(--brand-accent-color-blue);
  cursor: pointer;
}

div.searchResults button.loadMore:hover {
  background-color: var(--brand-accent-color-blue-lighter-2);
}

div.searchResults div.noResults {
  padding: 0.5em 0;
  text-align: center;
  color: var(--text-subdued);
}

div.allSearchResults {
  max-height: 700px;
  margin-top: 1em;
  /* overflow: auto; */
}

div.noteResult,
div.meetingResult,
div.taskResult,
div.crmResult {
  display: flex;
  flex-direction: row;
  align-items: center;
}

div.crmResult span.recordIcon,
div.taskResult span.checkbox {
  width: 1.5em;
  flex: 1.2em 0 0;
  display: flex;
}

div.crmResult span.recordIcon img {
  width: 1.1em;
}

div.noteResult > label.title,
div.meetingResult > label.title,
div.taskResult > label.title,
div.crmResult > label.title {
  width: 40%;
  flex: 40% 0 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

div.taskResult > label.title {
  width: calc(40% - 1.4em);
  flex: calc(40% - 1.4em) 0 0;
}

div.crmResult > label.title {
  width: 90%;
  flex: 90% 0 0;
  margin-left: 0.5em;
}

div.noteResult > span.timestamp,
div.meetingResult > span.timestamp,
div.taskResult > span.timestamp {
  width: 20%;
  flex: 20% 0 0;
  margin: 0 0.5em;
  text-align: center;
  color: var(--text-subdued);
  font-size: 0.64em;
}

div.taskResult span.timestamp,
div.taskResult span.assignee {
  border: 1px solid var(--brand-accent-color-blue-lighter-2);
  border-radius: 0.5em;
  padding: 0.2em;
  font-size: 0.64em;
  background-color: var(--background-color-secondary);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  width: 20%;
  flex: 20% 0 0;
}

div.taskResult span.timestamp span.icon,
div.taskResult span.assignee span.icon {
  margin-right: 0.5em;
  font-size: 0.9em;
  vertical-align: middle;
}

span.icon > img {
  height: 0.9em;
  vertical-align: middle;
}

div.taskResult span.assignee > span.assigneeNames {
  color: var(--text-subdued);
  font-size: 1em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

div.noteResult > span.icon,
div.taskResult > span.icon,
div.meetingResult > span.icon {
  width: 1.5em;
  flex: 1.5em 0 0;
  font-size: 0.8em;
}

div.noteResult > span.icon > img {
  width: 1em;
}

div.noteResult > span.icon > img[alt='Private note'] {
  width: 1em;
  filter: invert(74%) sepia(2%) saturate(3%) hue-rotate(318deg) brightness(83%) contrast(87%);
}

div.meetingResult > span.icon > img {
  width: 1em;
}

div.meetingResult > span.icon > img[alt='Add note'] {
  filter: invert(74%) sepia(2%) saturate(3%) hue-rotate(318deg) brightness(83%) contrast(87%);
}

div.noteResult > span.recordName,
div.meetingResult > .meetingNoteTitle {
  flex: 30% 0 0;
  border-radius: 0.3em;
  font-size: 0.64em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

div.meetingResult > .meetingNoteTitle {
  padding: 0.3em 1em;
  cursor: pointer;
}

div.meetingResult > .meetingNoteTitle > a {
  width: 100%;
  display: inline-block;
  font-size: 1em;
}

div.meetingResult > .meetingNoteTitle:hover {
  background-color: var(--brand-accent-color-blue-lighter-2);
}

div.meetingResult > span.buttonish {
  --font-size: 0.64em;
  /* height: calc(1.5 * var(--font-size)); */
  flex: 20% 0 0;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  border: 1px solid var(--brand-accent-color-blue-lighter-2);
  border-radius: calc(0.5 * 1.1 * var(--font-size));
  padding: 0.2em 1em;
  background-color: var(--background-color-secondary);
  text-align: center;
  color: var(--foreground-color);
  font-size: var(--font-size);
  user-select: none;
  cursor: pointer;
}

div.meetingResult > span.buttonish:hover {
  background-color: var(--brand-accent-color-blue-lighter-2);
}

button.info span.icon {
  margin-right: 0.3em;
  font-size: 0.9em;
}

div.taskResult span.checkbox > span {
  font-size: 0.9em;
}

span.box > img {
  height: 1.1em;
}

div.taskResult span.icon > img {
  width: 1.2em;
  vertical-align: middle;
}

div.taskResult span.overdue {
  border: 1px solid var(--brand-accent-color-pink-darker-1);
  /* background-color: lightcoral; */
  color: var(--brand-accent-color-pink-darker-1);
  font-weight: var(--bold-font-weight);
}

div.taskResult span.noduedate {
  border: 1px solid var(--brand-accent-color-orange-darker-2) !important;
  color: var(--brand-accent-color-orange-darker-2);
}

span.trailingIcon {
  margin-left: auto;
}

span.trailingIcon > img {
  width: 1.2em;
  height: 1.2wem;
}

div.searchResults > ul.resultsList[data-luru-role='search-results-MEETING'] > li {
  cursor: default;
}

div.searchResults > ul.resultsList[data-luru-role='search-results-MEETING'] > li:hover {
  background-color: transparent;
}

span.meetingNoteTitle > div[data-luru-role='luru-popup-menu'] > button {
  width: auto;
  height: 1em;
  padding: 0.3em 0.3em 0.3em 0;
  background-color: transparent;
}

span.meetingNoteTitle > div[data-luru-role='luru-popup-menu'] > button > span:first-child {
  font-size: 0.64em;
}

div[data-luru-role='create-or-search-record-dialog'] div[data-role='popup-menu'] ul {
  font-size: 0.64em;
}

div[data-luru-role='create-or-search-record-dialog'] div[data-role='popup-menu'] li {
  font-size: 1em;
}

div.taskResult span.checkbox span.spinnerContainer {
  display: none;
}

div.taskResult span.checkbox.toggling span.spinnerContainer {
  display: inline-block;
}

div.taskResult span.checkbox.toggling span.box {
  display: none;
}

.records img[data-crm-id='HUBSPOT'][data-record-type='deal'] {
  transform: scale(0.8);
}

.records img[data-crm-id='HUBSPOT'][data-record-type='account'] {
  transform: scale(0.75);
}

.records img[data-crm-id='HUBSPOT'][data-record-type='contact'] {
  transform: scale(0.8);
}

.records img[data-crm-id='PIPEDRIVE'][data-record-type='deal'] {
  transform: scale(1);
}

.records img[data-crm-id='PIPEDRIVE'][data-record-type='account'] {
  transform: scale(0.75);
}

.records img[data-crm-id='PIPEDRIVE'][data-record-type='contact'] {
  transform: scale(0.8);
}

.records img[data-crm-id='PIPEDRIVE'][data-record-type='lead'] {
  transform: scale(0.75);
}

.records img[data-crm-id='SFDC'][data-record-type='deal'] {
  transform: scale(0.75);
}

.records img[data-crm-id='SFDC'][data-record-type='account'] {
  transform: scale(0.75);
}

.records img[data-crm-id='SFDC'][data-record-type='contact'] {
  transform: scale(0.8);
}

.records img[data-crm-id='SFDC'][data-record-type='lead'] {
  transform: scale(0.8);
}

img[data-crm-id='HUBSPOT'] {
  filter: var(--hubspot-icon-filter);
}
