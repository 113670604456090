.availableFieldsContainer {
  display: flex;
  flex-direction: column;
  /* height: 400px; */
  /* overflow-y: auto; */
  /* width: 300px; */
}

.availableFieldsContainer .fieldItem {
  padding: 0.8em;
  cursor: pointer;
  /* border-bottom: 1px solid var(--brand-accent-color-gray); */
  display: flex;
  width: 100%;
}

.availableFieldsContainer .fieldItem label {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis !important;
  text-align: left !important;
  flex: 1;
  font-weight: normal !important;
  cursor: pointer;
}

.emptyFields {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
}

.availableFieldsContainer .fieldItem[data-highlighted='yes'] {
  background-color: var(--brand-accent-color-blue-lighter-2);
}
.availableFieldsContainer .fieldItem[data-highlighted='yes'] {
  background-color: var(--brand-accent-color-blue-lighter-2);
}

.availableFieldsContainer .fieldItem[data-locked='true'] label {
  cursor: default !important;
}

.availableFieldsContainer .fieldItem:hover {
  background-color: var(--brand-accent-color-blue-lighter-1);
}

.availableFieldsContainer .filterTextInputItemContainer:hover {
  background-color: white;
}

.availableFiledsFilterTextInputItemContainer {
  position: sticky;
  top: 0;
  height: 3em;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--brand-accent-color-blue-lighter-2);
  padding: 0.6em;
  background-color: white !important;
  cursor: pointer;
}

.availableFiledsFilterTextInputItemContainer img {
  height: 1.5em;
  filter: invert(87%) sepia(73%) saturate(7461%) hue-rotate(177deg) brightness(100%) contrast(102%);
}

.availableFiledsFilterTextInput {
  width: 100% !important;
  flex: 1;
  border: none !important;
  border-radius: 0 !important;
  display: flex;
  outline: none !important;
  appearance: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  padding-left: 0em !important;
}

.checkedIcon {
  height: 1em !important;
  filter: var(--brand-accent-color-blue-filter);
  vertical-align: middle;
}

.errorContainer,
.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  width: 300px;
  flex-direction: column;
  gap: 0.2em;
}

.errorContainer {
  color: var(--warning-color);
}

.lockIcon {
  height: 1em;
  vertical-align: middle;
  cursor: default !important;
}

li.dataMoreLabel {
  cursor: default;
}

li.dataMoreLabel:hover {
  cursor: default;
  background-color: transparent !important;
}

li.dataMoreLabel:hover em {
  color: var(--brand-accent-color-blue-lighter-1) !important;
}

li.refreshSchemaInfoContainer {
  width: 100%;
  cursor: default;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  margin-top: 1em;
  padding: 0 0.5em;
}

li.refreshSchemaInfoContainer span {
  text-align: center;
  color: var(--brand-accent-color-gray-darker-1);
}

li.refreshSchemaInfoContainer > span > span.refreshLink {
  color: var(--brand-accent-color-blue);
  cursor: pointer;
}
