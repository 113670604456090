div.parent {
  width: 100%;
}

div.info {
  color: var(--text-subdued);
}

div.container {
  position: relative;
  width: 100%;
  /* border-top-color: transparent; */
  border-radius: var(--luru-border-radius);
  /* border: 1px solid var(--border-gray-color); */
  border: none;
  padding: 0.3em 1em;
  background-color: var(--background-color-secondary);
}

@media (min-width: 800px) {
  div.container {
    width: 50%;
  }
}

div.container > div {
  margin-top: 0;
}

div.container > label {
  height: 1.7em;
  margin: 0;
  font-size: var(--font-size-decrease-1);
  user-select: none;
  color: var(--brand-accent-color-blue);
  font-weight: var(--bold-font-weight);
  display: flex;
}

div.container > div[data-luru-role='editable-cell'] {
  height: calc((1.15 + 0.3 * 2) * 1em + 2px);
  margin-bottom: 0.5em;
  padding: 0.3em 1em;
}

div.container > div[data-luru-role='editable-cell'][data-luru-field-type='textarea'] {
  height: calc(4 * ((1.15 + 0.3 * 2) * 1em + 2px));
  margin-bottom: 0.5em;
  padding: 0.3em 1em;
}

button.warning {
  position: absolute;
  right: 2.2em;
  top: 0.25em;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

button.warning img {
  width: 0.9em;
  height: 0.9em;
}

@media (min-width: 600px) {
  button.warning {
    right: -4em;
    top: calc(50% - 0.75em);
  }
  button.warning img {
    width: 1.4em;
    height: 1.4em;
  }
}

button.delete {
  /* position: absolute;
  right: 1em;
  top: 0.3em;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer; */
  /* background-color: teal; */
  cursor: pointer;
}

button.delete img {
  width: 1em;
  height: 1em;
}

@media (min-width: 600px) {
  button.delete {
    right: -2em;
    top: calc(50% - 0.75em);
  }
  button.delete img {
    width: 1.5em;
    height: 1.5em;
  }
}

div.action {
  display: flex;
  justify-content: flex-end;
  padding: 0em 1.2em 1em;
}

div.container > div div > label {
  height: 1.5em;
}

div.container > div div [data-luru-role='luru-popup-menu'] > button {
  background-color: transparent;
  border-radius: 0.3em;
}

div.container input[type] {
  width: 100%;
  border-radius: 0.3em;
  background-color: transparent;
}

button.delete,
button.warning {
  display: none;
}

div.parent:hover button.delete,
div.parent:hover button.warning {
  display: block;
}

button.delete,
button.warning {
  user-select: none;
  z-index: 100;
}

div.userAlert {
  padding: 0.2em 0.2em 0.5em 0.2em;
  color: var(--brand-accent-color-pink-darker-2);
  line-height: 1.15em;
  font-weight: normal;
  font-size: var(--font-size-decrease-1);
  user-select: none;
}

span.error {
  color: var(--warning-color);
}

.mainContainer {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}

.readOnlyIconStyles {
  margin: 2px 5px 2px 5px;
  /* margin-top: 1em;  */
}

.readOnlyIconStyles img {
  width: 1em;
}
