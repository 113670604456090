.gradientText {
  background: linear-gradient(-30deg, hsl(200deg 100% 65%), #a463f2);
  background-clip: text;
  font-size: 2rem;
  font-weight: var(--bold-font-weight);
  color: transparent;
}

.translucentBox {
  border: 1px solid #f6f6f7;
  border-radius: 12px;
  padding: 1.5rem;
  background-color: #f6f6f780;
  backdrop-filter: blur(10px);
  line-height: 1.35;
  transition: border-color 0.25s, background-color 0.25s;
}

.translucentBox:hover {
  border-color: #a463f2;
  border-color: var(--brand-accent-color-blue-lighter-1);
}
