.main .label {
  width: 100%;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.disabled {
  background-color: var(--disabled-background-color) !important;
}
.multiSelectMenu + div div[data-role='popup-menu'] > ul,
.multiSelectMenu + div div[data-role='popup-menu'] {
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}

.main .actionIcon {
  margin-left: auto;
}

.main .actionIcon > img {
  vertical-align: middle;
  width: 1em;
  margin-right: 0.1em;
}

ul.menuList {
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
}

div.filterBoxContainer {
  background-color: var(--brand-accent-color-blue-lighter-2);
}

input.filterBox {
  width: calc(100% - 1.5em);
  margin: 0.5em 0.75em;
}

li.highlightedItem {
  background-color: var(--brand-accent-color-blue-lighter-2);
}

/* img[data-role='list-item-icon'] {
  width: 1.2em !important;
  height: 1.2em !important;
  margin: 0;
  margin-right: 0.5em;
  padding: 0;
  background-color: transparent !important;
} */

ul.menuList li img {
  width: 1.2em !important;
  height: 1.2em !important;
  margin: 0;
  margin-right: 0.5em;
  padding: 0;
  background-color: transparent !important;
}

ul.menuList li {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100% !important;
}

ul.menuList li.withIcon {
  padding-left: 0.75em;
}

label.selectedItemDisplay {
  display: flex;
  flex-direction: row;
  align-items: center;
}

label.selectedItemDisplay img {
  margin-right: 0.3em;
}

label.selectedItemDisplay img {
  --crm-icon-padding-x: 1px;
  --crm-icon-padding-y: 1px;
}

.propsIcon {
  margin-right: 3px;
}

.propsIcon img {
  width: 20px;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(65deg) brightness(101%) contrast(103%);
}

div.emptyData {
  padding: 0.5em;
}

li.selectBoxFooter {
  font-style: italic;
}

li.selectBoxFooter:hover {
  background-color: transparent;
  color: var(--foreground-color);
  cursor: default;
}

ul.multiSelectMenu {
  padding-left: 2rem;
}

li.multiSelectMenuItem {
  padding-left: 2.5rem;
  font-weight: normal;
}

li[data-selected='yes'] {
  position: relative;
}

li[data-selected='yes']::before {
  content: '';
  position: absolute;
  top: 0.75rem;
  left: 1rem;
  width: 0.5rem;
  height: 1rem;
  border: 2px solid var(--brand-accent-color-blue);
  border-top-width: 0;
  border-left-width: 0;
  transform: rotate(45deg);
}
