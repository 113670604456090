.infoBlock {
  position: relative;
}

.disabled::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: ' ';
  background-color: gray;
  opacity: 0;
  z-index: 200;
}

.noteTitle {
  --note-info-border-color: var(--brand-accent-color-blue-lighter-1);
  width: 95%;
  margin: 0 5px 0.2em 0;
  border: 1px solid var(--background-color);
  padding: 0.5em 0.5em 0.5em 0.75em;
  background-color: var(--background-color);
  color: var(--foreground-color);
  font-size: var(--font-size-increase-1);
  font-weight: var(--bold-font-weight);
}

.titleRow {
  display: flex;
  flex-direction: row;
}

.titleRow button {
  border: none;
  outline: none;
  background-color: var(--background-color);
  color: var(--brand-accent-color-blue);
  font-size: 2em;
  cursor: pointer;
}

.noteTitle:focus {
  outline: none;
  border: 1px solid var(--brand-accent-color-blue);
  box-shadow: 0 0 0 3px var(--brand-accent-color-blue-lighter-3);
  border-radius: 0.3em;
  transition: box-shadow 0.2s ease-in;
}

.noteInfo {
  width: 95%;
  margin: 0 auto;
  border: 1px solid var(--brand-accent-color-blue-lighter-3);
  border-radius: var(--luru-border-radius);
  padding: 0.3em;
  background-color: var(--app-bg-color);
  user-select: none;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 0em;
}

@media (min-width: 600px) {
  .noteInfo {
    width: 98%;
    padding: 0.3em 1em;
  }
}

@media (min-width: 900px) {
  .noteInfo {
    width: 98%;
    padding: 0.5em 1em;
  }
}

.noteInfoRow {
  display: flex;
  flex-wrap: wrap;
  min-height: 2.5em;
  gap: 0.5em;
  justify-content: baseline;
  align-items: center;
}

.noteInfoRow[data-type='logas'] {
  margin-top: 0.15em;
}

.noteInfoRow:first-child {
  margin-top: 0.4em;
}

.noteInfoRow:last-child {
  margin-bottom: 0.4em;
}

.noteInfoRow > .noteInfoRight {
  text-align: left;
  margin-left: 2.3em;
}

@media (min-width: 900px) {
  .noteInfoRow > .noteInfoRight {
    text-align: right;
  }
}

.noteInfoRow > div {
  padding: 0;
  padding-left: 0.5em;
  vertical-align: middle;
}

.noteInfoRow > div:first-child {
  display: none;
  width: 6em;
  color: var(--text-subdued);
}

.noteInfoRow > div:nth-child(2) {
  padding-left: 0;
  flex-grow: 1;
}

@media (min-width: 600px) {
  .noteInfoRow > div:first-child {
    display: inline-block;
    width: 6em;
  }

  .noteInfoRow > div:nth-child(2) {
    padding-left: 0.5em;
  }
}

@media (min-width: 900px) {
  .noteInfoRow > div:first-child {
    width: 6em;
  }

  .noteInfoRow > div:nth-child(2) {
    padding-left: 0.5em;
  }
}

.actionMenuButton {
  margin-left: 5px;
}

.noSelect {
  user-select: none;
}

.timestamp {
  flex-grow: 1;
  font-size: var(--font-size-decrease-1);
}

.crmlog {
  flex-grow: 1;
}
