.recordView {
  margin: 0;
  padding: 0.5em 0 0 0.2em;
  list-style-type: none;
  border-top: 1px solid var(--brand-accent-color-gray);
}

.recordViewContainer {
  /* overflow-x: auto; */
  overflow-y: auto;
  height: 100%;
  max-height: 65vh;
  min-height: 50vh;
}

.recordView > li {
  margin-top: 1em;
}

.recordView > li > label {
  display: block;
  margin: 0.2em 0;
  font-size: 0.9em;
  font-weight: var(--bold-font-weight);
  color: var(--brand-accent-color-blue) !important;
}

.recordView > li > label:hover {
  color: var(--brand-accent-color-blue) !important;
}

.recordView div.fieldInput[data-luru-field-type='textarea'] {
  height: calc(4 * ((1.15 + 0.3 * 2) * 1em + 2px));
}

.recordView div.fieldInput > div {
  height: 2em;
  border-radius: 0.3em;
}

.recordView div.fieldInput > div[data-luru-field-type='textarea'] {
  height: calc(4 * ((1.15 + 0.3 * 2) * 1em + 2px));
}

.recordView div.fieldInput [data-luru-role='luru-text-box'] {
  background-color: transparent;
}

.recordView div.fieldInput [data-luru-role='editable-cell-info-layer'] {
  border-radius: 0.3em;
}

.recordView div.fieldInput [data-luru-role='editable-cell-alert'] {
  top: 0.5em;
}

.recordView div[data-luru-role='luru-popup-menu'] {
  padding: 0;
  width: 100%;
}

.recordView div[data-luru-role='luru-popup-menu'] > button {
  height: 24px;
  border: 1px solid var(--brand-accent-color-gray);
  border-radius: 0.3em;
}

.recordView div[data-luru-role='luru-popup-menu'] > button {
  height: 2em;
  padding: 0 1em;
}

.recordView div[data-luru-role='type-ahead-search-box'] input[type='search'] {
  padding: 0 1em;
}

.recordView input[type] {
  height: 2em;
  width: 280px;
  border: 1px solid var(--brand-accent-color-gray);
  border-radius: 0.3em;
}

ul.recordView input[type] {
  border: 1px solid var(--brand-accent-color-gray);
}

.recordView input[type]:focus {
  border: 1px solid var(--brand-accent-color-blue);
  box-shadow: 0 0 0 3px var(--brand-accent-color-blue-lighter-2);
  transition: box-shadow 0.2s ease-in;
}

.recordView li[data-item-chooser-key] {
  width: 100%;
}

.collectionViewContainer {
  margin-bottom: 0.5em;
}

.errorMessage {
  margin: 0 1em 1em;
  text-align: center;
  color: red;
  font-size: 0.8em;
}
