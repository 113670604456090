.toastClickHereActionText {
  font-style: italic;
  text-decoration: underline;
  cursor: pointer;
}

.container {
  width: 95%;
  height: 100%;
  min-height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin: 0 auto;
}

.title {
  margin: -1.5em 0 0 0;
  font-size: 1.5em;
  font-weight: var(--bold-font-weight);
  color: var(--brand-accent-color-blue);
  /* text-align: center; */
}

.body > * {
  margin: 0.3em auto;
  /* text-align: center; */
}

.body > p {
  margin: 0.3em 20% 1em 20%;
  font-size: 1em;
}

.body > button {
  margin: 0 auto;
}

.footer {
  display: flex;
  flex-direction: column;
  gap: 1em;
  justify-content: center;
  align-items: flex-end;
  text-align: center;
  margin: 1em;
  width: 100%;
}

.footer button {
  border: none;
  padding: 0.3em 1.25em;
  background-color: var(--background-color);
  color: var(--brand-accent-color-blue);
  outline: none;
  font-size: 0.8em;
}

.connectButtonsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
  flex-wrap: wrap;
}

/* From: https://developers.google.com/identity/sign-in/web/build-button */
.gcalButton,
.o365Button {
  /* width: 190px; */
  margin-bottom: 0.5em !important;
  height: 35px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid var(--brand-accent-color-gray-darker-2);
  border-radius: 2px;
  outline: none;
  box-shadow: var(--brand-accent-color-gray-darker-2) 1px 1px 1px;
  padding-right: 18px;
  background: white;
  white-space: nowrap;
}

.gcalButton:hover,
.gcalButton:active,
.o365Button:hover,
.o365Button:active {
  background-color: var(--brand-accent-color-gray-lighter-2);
  border: none;
  cursor: pointer;
}

.gcalButton > div,
.o365Button > div {
  display: inline-block;
}

.gcalButton .icon {
  width: 18px;
  height: 18px;
  margin-left: 8px;
  margin-right: 24px;
  background-image: url('../../../images/google_logo.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  vertical-align: middle;
}

/* .gcalButton .icon[data-luru-cal='O365CAL'] {
  background-image: url('../../../images/microsoft_logo.png') !important;
} */

.gcalButton .buttonText,
.o365Button .buttonText {
  color: var(--brand-accent-color-gray-darker-2);
  font-size: 14px;
  font-weight: 500;
  font-family: 'Roboto', Arial, sans-serif;
}

.o365Button .icon {
  width: 18px;
  height: 18px;
  margin-left: 8px;
  margin-right: 24px;
  background-image: url('../../../images/microsoft_logo.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  vertical-align: middle;
}

.hidden {
  display: none;
}

/* @media (min-width: 600px) {
  .title {
    margin: 0 auto;
  }

  .container {
    width: auto;
  }

  .connectButtonsContainer {
    margin: 0 auto;
  }

  .body > * {
    margin: 0 auto;
  }

  .body > p {
    margin: 0.1em 20% 1em 20%;
  }

  .footer {
    margin-bottom: 3em;
  }
} */

div[data-luru-role='zoom-message'] {
  display: flex;
  flex-direction: column;
  margin: 2em 0;
}

div[data-luru-role='zoom-message'] strong {
  color: black;
  font-weight: bold;
}

div.bodyInfo {
  width: max-content;
  padding: 5px 10px;
  margin-left: 1em;
  /* font-size: 0.7em; */
}

div.bodyInfo p:nth-child(2),
div.bodyInfo p:nth-child(3),
div.bodyInfo p:nth-child(4),
div.bodyInfo p:nth-child(6) {
  margin: 5px 0;
}

div.bodyInfo p:nth-child(4) {
  margin-bottom: 10px;
}

div.bodyInfo .eightPtage {
  margin: 5px 0 15px 0;
  font-weight: bold;
}

div.bodyInfo p:nth-child(7) {
  margin-bottom: 10px;
}

div.bodyInfo ul {
  margin-left: 1em;
  text-align: left;
  margin-top: 5px;
}

.skipButtonStyle {
  font-size: 12px !important;
}

.skipButtonContainer {
  display: flex;
  justify-content: flex-end;
  border: 1px solid teal;
}

.smallerFont {
  font-size: 0.6em;
}

b {
  font-weight: bold;
}

.insteadUl {
  display: flex;
  flex-direction: column;
}

@media (max-width: 600px) {
  div.bodyInfo {
    width: fit-content;
  }
}
