.modalContainer {
  user-select: none;
}

.modalContent {
  justify-content: flex-start !important;
  overflow: auto;
  max-height: 95vh !important;
  min-width: 500px !important;
  max-width: 95vw !important;
  position: relative;
  padding-bottom: 20px;
  height: calc(fit-content + 20px) !important;
  min-height: calc(10% + 20px) !important;
}

.modalBody {
  padding: 1em;
}

.modalContent_ERROR {
  border: 1px solid var(--banner-error-color);
}

.modalContent_ALERT {
  border: 1px solid var(--banner-alert-color);
}

.modalContent_REMINDER {
  border: 1px solid var(--banner-reminder-color);
}

.imagePlaceholder[data-luru-notification-type='INFO'] {
  background-image: url('../../../../images/fluent/info.svg');
  background-size: contain;
  background-repeat: no-repeat;
  width: 2em;
  height: 2em;
  filter: var(--brand-accent-color-blue-filter);
}

.imagePlaceholder[data-luru-notification-type='ERROR'] {
  background-image: url('../../../../images/fluent/error.svg');
  background-size: contain;
  background-repeat: no-repeat;
  width: 2em;
  height: 2em;
  filter: var(--brand-accent-color-pink-darker-2-filter);
}

.imagePlaceholder[data-luru-notification-type='REMINDER'] {
  background-image: url('../../../../images/fluent/clock.svg');
  background-size: contain;
  background-repeat: no-repeat;
  width: 2em;
  height: 2em;
  filter: var(--brand-color-filter);
}

.imagePlaceholder[data-luru-notification-type='ALERT'] {
  background-image: url('../../../../images/fluent/warning.svg');
  background-size: contain;
  background-repeat: no-repeat;
  width: 2em;
  height: 2em;
  filter: var(--hubspot-icon-filter);
}

.actionButtonsContainer {
  display: flex;
  gap: 0.5em;
  position: absolute;
  bottom: 0.5em;
  right: 0.5em;
}

.actionButtonsContainer .closeBtn {
  border-color: var(--warning-color);
}

div.modalBody a {
  color: var(--brand-accent-color-blue) !important;
  text-decoration: underline !important;
}
