.parent {
  background-color: var(--modal-background-color);
  backdrop-filter: var(--modal-background-filter);
  transition: var(--modal-background-transition);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 700;
  user-select: none;
  font-size: 1rem;
}

.minimized {
  visibility: hidden;
  backdrop-filter: blur(0px);
  transition: backdrop-filter 0.1s ease-out;
}

.dialog {
  --dialog-width: 300px;
  --dialog-height: 160px;
  position: absolute;
  top: calc((100% - var(--dialog-height)) / 2);
  top: 10px;
  left: calc((100% - var(--dialog-width)) / 2);
  height: var(--dialog-height);
  width: var(--dialog-width);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: var(--luru-border);
  box-shadow: var(--luru-box-shadow);
  border-radius: 0.4em;
  padding: 1em;
  background-color: var(--background-color);
  color: var(--foreground-color);
}

@media (min-width: 600px) {
  .dialog {
    --dialog-width: 360px;
    --dialog-height: 200px;
  }
}

@media (min-width: 900px) {
  .dialog {
    --dialog-width: 600px;
    --dialog-height: 200px;
  }
}

.title {
  font-weight: var(--bold-font-weight);
  font-size: 0.9em;
  text-transform: uppercase;
  text-align: left;
}

.content {
  text-align: left;
  padding: 0 1em;
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  text-align: center;
  overflow: hidden;
}

.buttons button {
  min-width: 150px;
  margin: 1em 0 0.5em 1em;
  padding: 0.5em;
  font-size: 0.85em;
  font-weight: var(--bold-font-weight);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* .buttons button:focus {
  outline: 2px solid var(--brand-accent-color-blue-lighter-2);
} */

.okButton {
  background-color: var(--brand-accent-color-blue);
  color: white;
}
