.card {
  border-radius: 0.5rem;
  padding: 1rem;
  border: 1.5px solid var(--brand-accent-color-gray-lighter-3);
  background-color: var(--brand-accent-color-gray-lighter-3);
  backdrop-filter: blur(10px);
  line-height: 1.35;
  transition: border-color 0.25s, background-color 0.25s;
}

.card:hover {
  border-color: var(--brand-accent-color-blue-lighter-1);
}

.card h4 {
  font-weight: var(--bold-font-weight);
  font-size: var(--font-size-increase-1);
}
