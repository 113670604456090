.parent label {
  display: block;
  font-weight: var(--bold-font-weight);
}

.parent .filterUsers {
  margin: 1em 0;
}

.parent input[type='text'] {
  margin: 0 0.5em 0 0;
  border: 1px solid var(--brand-accent-color-gray);
  border-radius: 0.5em;
  outline: none;
  padding: 0.3em 1em;
}

.parent .filterUsers input[type='text'] {
  width: 100%;
}

.parent .saveChangesButton,
.parent .copyLinkButton {
  width: 150px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  margin-right: 0;
  border: 1px solid var(--brand-accent-color-blue);
  border-radius: 0.5em;
  outline: none;
  padding: 0.3em 1em;
  background-color: var(--background-color);
  font-weight: var(--bold-font-weight);
  color: var(--brand-accent-color-blue);
  cursor: pointer;
}

.parent .saveChangesButton {
  width: 200px;
  justify-content: center;
  background-color: var(--brand-accent-color-blue);
  color: var(--background-color);
}

.parent .copyLinkButton img {
  margin-right: 0.75em;
}

.copyLinkButton img {
  filter: var(--brand-accent-color-blue-filter);
}

.parent .filterUsers button {
  background-color: var(--background-color);
  color: var(--brand-accent-color-blue);
}

.parent input[type='text']:focus {
  outline: none;
  border: 1px solid var(--brand-accent-color-blue);
  box-shadow: 0 0 0 3px var(--brand-accent-color-blue-lighter-2);
  border-radius: 0.5em;
  transition: box-shadow 0.2s ease-in;
}

.parent > * {
  margin: 1rem 0;
}

/* .parent ul {
  height: calc(5 * (1.75em + 2px));
  margin: 0 auto;
  padding: 0;
  list-style-type: none;
  overflow-y: auto;
}

.parent p {
  width: 100%;
  height: calc(5 * (1.75em + 2px));
  padding-top: calc(2 * (1.75em + 2px));
  vertical-align: middle;
  text-align: center;
}

.parent li {
  position: relative;
  border: 1px solid var(--background-color);
  padding: 0.3em 1em;
} */

.parent li:hover {
  /* border: 1px solid hsla(0, 0%, 90%, 50%); */
  border-radius: 0.3em;
  background-color: var(--brand-accent-color-gray);
}

.parent li .deleteButton {
  visibility: hidden;
  width: 1.15em;
  height: 1.15em;
  position: absolute;
  right: 1em;
  top: 0.15em;
  margin: 0;
  border: none;
  outline: none;
  padding: 0;
  background-color: transparent;
  color: var(--brand-accent-color-gray-darker-1);
  content: ' ';
  cursor: pointer;
}

.parent li:hover .deleteButton {
  visibility: visible;
}

.parent .tip {
  font-size: 0.8em;
}

.parent .tip strong {
  font-weight: var(--bold-font-weight);
}

.actionButtons {
  position: relative;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2em;
  border-bottom: 1px solid var(--brand-accent-color-gray);
  padding-bottom: 2em;
}

.actionButtons::after {
  position: absolute;
  right: 50%;
  bottom: -1.1em;
  padding: 0.5em;
  background-color: white;
  content: 'OR';
}

.okButton,
.cancelButton {
  width: 30%;
  border-radius: 0.5em;
  padding: 0.3em 1em;
  font-weight: var(--bold-font-weight);
  cursor: pointer;
}

.okButton {
  border: 1px solid var(--brand-accent-color-blue);
  background-color: var(--brand-accent-color-blue);
  color: white;
}

.userNameContainer {
  display: flex;
  align-items: center;
  padding: 0.5em;
}

.userNameContainer > div {
  padding: 0;
}

.nameEmailContainer {
  margin-left: 1em;
}

.userName {
  margin-bottom: 0.3em;
  font-weight: var(--bold-font-weight);
}

.userEmail,
.generalAccessInfo {
  font-size: 0.85em;
}

.generalAccessInfo {
  margin-left: 1.2em;
}

.sharingDetailsTable {
  height: 270px;
  overflow-y: auto;
  overflow-x: hidden;
}

.access {
  width: 210px;
  vertical-align: middle;
  /* text-align: right; */
}

.footer {
  display: flex;
  justify-content: space-between;
}

.parent h2 {
  margin-top: 2em;
}

.generalAccessTitle {
  margin-bottom: 0.3em;
  font-weight: normal;
}

.generalAccessTable .generalAccessButton {
  background-color: var(--brand-accent-color-blue-lighter-2);
  font-weight: var(--bold-font-weight);
  /* color: var(--brand-blue-color); */
}

.access .accessButton {
  background-color: var(--brand-accent-color-blue-lighter-2);
  /* color: var(--brand-blue-color); */
}
/* 
.access,
.generalAccessTable,
.sharingDetailsTable {
  border: 1px solid blue;
} */

.generalAccessTable .access {
  vertical-align: top;
  padding-top: 0.5em;
}
