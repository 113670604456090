div.parent {
  position: relative;
  width: 100%;
  border-radius: var(--luru-border-radius);
  background-color: var(--background-color-secondary);
}

@media (min-width: 800px) {
  div.parent {
    width: 50%;
  }
}

div.info {
  color: var(--text-subdued);
}

div.container {
  padding: 0 1em 0.5em;
}

div.container > div {
  margin-top: 0;
}

button.info {
  position: absolute;
  right: 5em;
  right: 18ch;
  top: 0.6em;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

button.info img {
  width: 1.2em;
  height: 1.2em;
}

button.delete {
  position: absolute;
  right: 13ch;
  top: 0.5em;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

button.deleteRightPos {
  right: 1em;
}

button.delete img {
  width: 1.2em;
  height: 1.2em;
  vertical-align: top;
}

button.update {
  position: absolute;
  right: 1em;
  top: 0.6em;
  border: none;
  border-radius: 0.3em;
  padding: 0.1em 1em;
  background-color: var(--brand-accent-color-blue);
  color: white;
  font-size: var(--font-size-decrease-1);
  font-weight: var(--bold-font-weight);
  outline: none;
  cursor: pointer;
}

button.update > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: var(--font-size-decrease-1);
}

button.update:hover {
  /* background-color: var(--brand-accent-color-blue-lighter-2); */
  box-shadow: 0 1px 4px var(--brand-accent-color-gray-darker-2);
}

button.info,
button.update,
button.delete {
  user-select: none;
  z-index: 100;
}

button.update {
  user-select: none;
  z-index: 0;
}

button.update img {
  width: 1.25em;
  height: 1.25em;
}

div.heading {
  border: 1px solid var(--brand-accent-color-blue-lighter-2);
  padding: 0.3em 1em;
  background-color: var(--brand-accent-color-gray);
  font-weight: var(--bold-font-weight);
  user-select: none;
}

div.heading > span {
  font-size: var(--font-size-decrease-2);
  margin-left: 0.6em;
}

div.action {
  display: flex;
  justify-content: flex-end;
  height: 2em;
  padding: 0em 1.2em 1em;
}

div.container > div div > label {
  height: 1.5em;
  user-select: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

div.container > div div [data-luru-role='luru-popup-menu'] > button {
  background-color: var(--background-color);
}

div.heading {
  transition: background 1s linear;
}

div.heading.updating {
  border: 1px solid var(--brand-accent-color-beige-lighter-1);
  background: linear-gradient(
    90deg,
    var(--brand-accent-color-orange-lighter-1),
    var(--brand-accent-color-orange-lighter-2)
  );
  background-size: 5%;
  background-position: -100% -100%;
  background-repeat: no-repeat;
  animation: loading 1s infinite;
}

@keyframes loading {
  0% {
    background-size: 5%;
  }
  100% {
    background-size: 100%;
  }
}

div.heading.successUpdating {
  border: 1px solid lightgreen;
  background-color: lightgreen;
}

div.heading.errorUpdating {
  border: 1px solid lightpink;
  background-color: lightpink;
  color: chocolate;
}

div.heading,
div.heading.updating,
div.heading.errorUpdating,
div.heading.successUpdating {
  border: 1px solid transparent;
  border-top-left-radius: 0.3em;
  border-top-right-radius: 0.3em;
}

span.errorMessage {
  display: block;
  width: 25vw;
  font-size: var(--font-size-decrease-1);
  white-space: break-spaces;
}

div.userAlert {
  padding: 0.2em;
  color: chocolate;
  font-weight: normal;
  font-size: var(--font-size-decrease-1);
  user-select: none;
  line-height: 1.15;
}

div.modified {
  color: orange;
}

div.parent[data-luru-role='embedded-collection-noteTemplate'] button.info {
  right: 3em;
}

div.heading > label {
  max-width: calc(100% - 11em);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

div.heading {
  display: flex;
}

div.heading > span {
  font-size: var(--font-size-decrease-1);
}

button.bottom {
  top: unset;
  bottom: 0.5em;
}

button.update[disabled] {
  background-color: var(--brand-accent-color-gray);
  color: var(--text-subdued);
  cursor: not-allowed;
}
