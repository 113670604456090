.taskView {
  width: 100%;
  max-height: 570px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 1em 3px 0;
}

.taskViewDialog {
  overflow-y: auto;
}

.taskViewHeader {
  display: block;
  user-select: none;
  margin: 0.5em;
  font-size: 0.9rem;
  font-weight: var(--bold-font-weight);
  text-align: left;
  color: var(--foreground-color);
  text-transform: uppercase;
}

.taskViewBody {
  /* height: 480px; */
  /* margin: 0 10% 3em 10%; */
  font-size: 0.8em;
}

@media (min-width: 600px) {
  .taskViewHeader {
    font-size: 1em;
  }

  .taskViewBody {
    /* margin: 0 10% 3em 10%; */
    font-size: 1.1em;
  }
}

.taskViewProperty {
  display: flex;
  flex-direction: column;
  margin: 0 0 1em;
}

@media (min-width: 900px) {
  .taskViewProperty {
    margin: 0 0 1em;
  }
}

.taskViewLabel {
  display: block;
  font-size: 0.9em;
  font-weight: var(--bold-font-weight);
  color: var(--brand-accent-color-blue);
}

@media (min-width) {
  .taskViewLabel {
    margin-bottom: 0.5em;
  }
}

.taskViewValue {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding: 0.3em 0.7em;
  font-size: 0.9em;
}

@media (min-width: 600px) {
  .taskViewValue {
    width: 400px;
  }
}

.taskViewValuePriority {
  display: flex;
  font-size: 1em;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  width: 100%;
}

textarea.taskViewBodyValue,
input[type='date'].taskViewValue,
input[type='text'].taskViewValue {
  border: var(--luru-border);
  border-radius: 0.3em;
  outline: 0;
}

textarea.taskViewBodyValue:focus,
input[type='date'].taskViewValue:focus,
input[type='text'].taskViewValue:focus {
  border: 1px solid var(--brand-accent-color-blue);
  box-shadow: 0 0 0 3px var(--brand-accent-color-blue-lighter-2);
  transition: box-shadow 0.2s ease-in;
}

.taskViewRadioValue {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  font-size: 0.9em;
}

.taskViewRadioValue > span {
  color: var(--brand-accent-color-blue-lighter-1);
}

.taskViewRadioValue > img {
  width: 23px;
  height: 23px;
}

.taskViewBodyValue {
  padding: 0.3em 0.7em;
  justify-content: space-around;
  /* overflow-wrap: normal; */
  outline: none;
  resize: none;
}

.taskView input {
  border-radius: 5px;
}

.taskView input:focus {
  outline: none;
  border-color: var(--brand-accent-color-blue);
  box-shadow: 0 0 0 3px var(--brand-accent-color-blue-lighter-2);
  transition: box-shadow 0.2s ease-in;
}

.taskView textarea {
  border-radius: 5px;
}

.taskView textarea:focus {
  outline: none;
  border-color: var(--brand-accent-color-blue);
  box-shadow: 0 0 0 3px var(--brand-accent-color-blue-lighter-2);
  transition: box-shadow 0.2s ease-in;
}

.taskView input[type='date'] {
  position: relative;
}

/* create a new arrow, because we are going to mess up the native one
see "List of symbols" below if you want another, you could also try to add a font-awesome icon.. */
.taskView input[type='date']:after {
  content: '\25BC';
  color: var(--brand-accent-color-blue-lighter-2-contrast);
  padding: 0 5px;
}

/* change color of symbol on hover */
.taskView input[type='date']:hover:after {
  color: var(--brand-accent-color-blue);
}

/* make the native arrow invisible and stretch it over the whole field so you can click anywhere in the input field to trigger the native datepicker*/
.taskView input[type='date']::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent;
}

/* adjust increase/decrease button */
.taskView input[type='date']::-webkit-inner-spin-button {
  z-index: 1;
}

/* adjust clear button */
.taskView input[type='date']::-webkit-clear-button {
  z-index: 1;
}

.taskViewFooter {
  display: flex;
  flex-direction: row;
  gap: 1em;
  justify-content: space-between;
  margin: 1em 0 0;
}

.taskViewFooter [data-role='create-task'] {
  font-weight: var(--bold-font-weight);
}

/* .taskViewFooter button[data-luru-role='cancel-button'],
.taskViewFooter button[data-luru-role='ok-button'] {
  justify-content: center;
  width: 100px;
  height: 32px;
  margin-left: auto;
  border: none;
  outline: none;
  border-radius: 0.3em;
  cursor: pointer;
} */

/* .taskViewFooter button[data-luru-role='cancel-button'] {
  border: 1px solid var(--brand-accent-color-gray);
}

.taskViewFooter button[data-luru-role='ok-button'] {
  margin-left: 1em;
  background-color: var(--brand-color);
  color: white;
  font-weight: var(--bold-font-weight);
} */

.taskViewFooter .cancelButton {
  border: 1px solid var(--brand-accent-color-gray);
}

.modalTitle {
  display: flex;
  align-items: center;
}

.modalTitle .imgContainer {
  width: 2em;
  margin-right: 0.1em;
}
.modalTitle span img {
  width: 100%;
}

.modalTitle span img[alt='deals'],
.modalTitle span img[alt='contacts'],
.modalTitle span img[alt='companies'] {
  width: 1.25em;
}

img[data-crm-id='SFDC'] {
  transform: scale(0.6);
}

img[data-crm-id='PIPEDRIVE'] {
  transform: scale(0.6);
}

div.taskWarningMessage {
  text-align: center;
  white-space: wrap;
  font-weight: var(--bold-font-weight);
  color: var(--brand-accent-color-pink-darker-1);
  margin-bottom: 1em;
  margin-top: -1em;
  overflow: hidden;
}

div.taskSuccessMessage {
  text-align: center;
  white-space: wrap;
  font-weight: var(--bold-font-weight);
  color: var(--green-color);
  margin-bottom: 1em;
  margin-top: -1em;
  overflow: hidden;
}
