.dropTargetBefore,
.dropTargetAfter {
  background-color: var(--background-color-secondary);
}

.dropTargetBefore {
  margin-top: -2px;
  border-top: 2px solid var(--brand-accent-color-blue-lighter-1);
}

.dropTargetAfter {
  margin-bottom: -2px;
  border-bottom: 2px solid var(--brand-accent-color-blue-lighter-1);
}
