.infoBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.info {
  margin-bottom: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info div.question {
  font-size: var(--font-size-increase-1);
  margin-bottom: 0.3em;
}
