.row {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1em;
  margin-bottom: 1em;
}

.actionTypeContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.fieldSelectionContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5em;
  flex-grow: 1;
}

.actionTypeContainer > label,
.fieldSelectionContainer > label {
  font-size: var(--font-size-decrease-1);
  font-weight: var(--bold-font-weight);
  color: var(--brand-accent-color-gray-darker-1);
}

.chipsInputContainer {
  user-select: none;
  flex-grow: 1;
}

.addExpression,
.removeExpression {
  width: 30px;
  vertical-align: middle;
}

.addExpression button,
.removeExpression .nullButton,
.removeExpression button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.8rem;
  height: 1.8rem;
  border: 0;
  border-radius: 0.3em;
  padding: 0.3em;
  outline: none;
  background-color: transparent;
  color: var(--brand-accent-color-blue-lighter-1);
  cursor: pointer;
}
.addExpression button img,
.removeExpression button img {
  filter: var(--brand-accent-color-blue-filter);
}

.addExpression button:hover,
.removeExpression button:hover {
  background-color: var(--background-color-secondary);
}

.addExpression button img,
.removeExpression button img {
  height: var(--font-size-increase-1);
}

.addExpression button:active,
.removeExpression button:active {
  background-color: var(--brand-accent-color-blue-lighter-2);
  font-weight: var(--bold-font-weight);
}

.removeExpression .nullButton,
.removeExpression .nullButton:hover,
.removeExpression .nullButton:active {
  background-color: transparent;
  cursor: default;
}

.fieldSelectionHelperText {
  font-size: var(--font-size-decrease-1);
}

.wfSorIcon {
  width: 1em;
}

/* New action button UI for V2 */
.newActionButtonContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1em;
  margin-bottom: 1em;
  border-bottom: 1px solid var(--brand-accent-color-gray-lighter-1);
  padding-bottom: 1.5em;
}

.newActionButtonContainer > div:first-child {
  flex: 5%;
  display: flex;
  flex-direction: row;
  gap: 0.5em;
  margin-top: 1.6em;
}

.newActionButtonContainer > div:first-child button {
  /* border: 1px solid var(--brand-accent-color-blue-lighter-1); */
  border-radius: 50%;
  width: 2em;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--brand-accent-color-gray-lighter-2);
}

.newActionButtonContainer > div:first-child button img {
  filter: var(--brand-accent-color-blue-filter);
  transform: scale(0.6);
}

.newActionButtonContainer > div:first-child + div {
  flex: 25%;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.newActionButtonContainer > div:last-child {
  flex: 70%;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.newActionButtonContainer > div > label {
  font-weight: var(--bold-font-weight);
  font-size: var(--font-size-decrease-1);
}

.appletChooser {
  width: 260px;
}

button.appletChooser > span[data-luru-role='luru-select-box-label'] > label > span {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.newActionButtonContainer div.spacer {
  padding-bottom: 0.5em;
}

.newActionButtonContainer > div:first-child button.nullButton {
  background-color: transparent;
  border: 0;
  outline: none;
  cursor: default;
}
