.main {
  display: block;
  font-size: var(--font-size-decrease-1);
  user-select: none;
}

.shortcuts {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0.5em 0 1em;
}

.shortcuts > * {
  width: 7em;
}

.periodName {
  cursor: default;
  font-size: var(--font-size-increase-1);
  font-weight: var(--bold-font-weight);
}

.navigate {
  display: flex;
  justify-content: flex-end;
}

.navigate img {
  height: 2em;
  margin: 0;
  border-radius: 1em;
  cursor: pointer;
}

.navigate img:hover {
  background-color: var(--brand-accent-color-gray-lighter-2);
}

.days,
.overlapWeek,
.normalWeek {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.days {
  padding: 0 0.3em;
}

.days > div,
.currentMonthDay,
.prevMonthDay {
  height: 2.5em;
  width: 2.5em;
  border-radius: 1.25em;
  background-color: var(--background-color-secondary);
  color: var(--foreground-color);
  font-size: 0.75em;
  text-align: center;
  cursor: pointer;
}

.days > div {
  background-color: var(--background-color);
  height: 1.5em;
}

.overlapWeek .prevMonthDay {
  color: var(--text-subdued);
}

.currentMonthDay,
.prevMonthDay {
  padding-top: 0.6em;
  font-size: 1em;
}

.overlapWeek .prevMonthDay.filtered,
.filtered {
  background-color: var(--brand-accent-color-blue-lighter-1);
  color: var(--brand-accent-color-blue-lighter-2);
}

.notVisible {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

.today {
  border: 1px solid var(--brand-accent-color-blue);
  font-weight: var(--bold-font-weight);
}
