.explorer {
  --spacing: 0.75em;
  display: flex;
  flex-direction: column;
  width: 25%;
  height: 100%;
  padding: 1em;
  padding-top: 0;
  overflow: hidden;
  user-select: none;
  transition: width 0.3s ease;
}

.explorerTemplate {
  width: 35%;
  overflow-y: auto;
  overflow-x: hidden;
}

@media (min-width: 900px) {
  .explorer {
    display: flex;
  }
}

@media (max-width: 900px) {
  .explorer {
    display: none;
  }
}

.explorer > * {
  flex-shrink: 0;
  margin: 0;
}

.explorer > div > div:not([data-luru-role='type-ahead-search-box']) {
  flex-grow: 0;
  flex-shrink: 1;
}

.explorer > div > .spacer {
  display: inline-block;
  height: 1em;
}

.explorer > div > button {
  margin: 0;
  height: 25.5px;
}

.explorer div input:first-child,
.explorer div div[data-luru-role='type-ahead-search-box'] {
  margin: 1.25rem 0 0 0;
  border-radius: 2rem;
  width: 100%;
}

.explorer div div[data-luru-role='type-ahead-search-box'] input:first-child {
  width: 100%;
  margin: 0;
  padding: 0.5em 1rem;
}

.explorer div h2 {
  margin-top: calc(1 * var(--spacing));
  margin-bottom: calc(1 * var(--spacing));
  font-size: 0.8em;
  font-weight: var(--bold-font-weight);
  text-transform: uppercase;
}

.noResults {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 0.5em;
  font-size: 0.9em;
  text-align: center;
}

.noResults > * {
  margin: 1em auto;
}

.widgetData {
  height: calc(40% - calc(var(--spacing) * 3));
  /* margin-top: var(--spacing); */
  /* font-size: 0.9em; */
  overflow-y: auto;
  overflow-x: hidden;
}

.explorerButton {
  height: calc(40% - calc(var(--spacing) * 3));
  /* margin-top: var(--spacing); */
  font-size: 0.9em;
  overflow: visible;
}

.fullHeight {
  height: 73vh;
}

.widgetData::-webkit-scrollbar-track {
  border-radius: 0.4em;
  background-color: var(--background-color-secondary);
}

.widgetData::-webkit-scrollbar-thumb {
  border-color: var(--background-color-secondary);
}

.explorer > div > button {
  width: 35%;
  margin-top: 0.5em;
  margin-right: auto;
  margin-left: auto;
}

.warning {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.warning img {
  height: 1.2em;
}

.warning > * + * {
  margin-left: var(--spacing);
}

.explorer > div > h1 {
  font-weight: var(--bold-font-weight);
}

.explorer > div > div.expandWidget {
  flex-grow: 1;
}

.refreshing {
  animation: rotate-refresh 1s linear infinite;
}

@keyframes rotate-refresh {
  100% {
    transform: rotate(360deg);
  }
}

h1 button.disabled {
  opacity: 0.5;
}

/* UpcomingMeetings connect calendar button */
.connectCalendar {
  width: 15em;
  margin-top: 2em;
}

.connectCalendar span {
  white-space: nowrap;
}

.closeAsideTemplate {
  height: 80vh;
  width: 0px;
}

main {
  margin: 0px;
}
