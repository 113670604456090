/** Colors for editor */
.editorContainer {
  --note-title-bg-color: var(--background-color-secondary);
  --note-title-bg-color: var(--background-color-secondary);
  --question-answer-border-color: var(--brand-accent-color-blue-lighter-1);
  --question-answer-border-color: var(--brand-accent-color-beige-darker-2);
  --placeholder-color: var(--brand-accent-color-gray-darker-1);
}

.editorContainer {
  position: relative;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: auto;
  /* position: absolute; */
  width: auto;
  max-width: 100%;
}

.editorContainerForTemplates {
  --note-title-bg-color: var(--background-color-secondary);
  --note-title-bg-color: var(--background-color-secondary);
  --question-answer-border-color: var(--brand-accent-color-blue-lighter-1);
  --question-answer-border-color: var(--brand-accent-color-beige-darker-2);
  --placeholder-color: var(--brand-accent-color-gray-darker-1);
  position: relative;
  width: 100%;
  width: auto;
  max-width: 100%;
  width: fit-content;
  height: 75vh;
  overflow-y: scroll;
  overflow-x: hidden;
  /* overflow: visible; */
}

.expand {
  width: 100%;
}

.banner {
  display: flex;
  position: sticky;
  top: 0;
  flex-direction: row;
  align-items: center;
  margin: 0.5em 1.5em;
  border: 1px solid var(--brand-accent-color-gray);
  border-left-width: 0;
  border-top-width: 0;
  border-radius: 0.5em;
  padding: 0.5em 2em;
  background-color: var(--brand-accent-color-pink-lighter-2);
  font-size: 0.8em;
  text-align: center;
  align-items: center;
  align-content: center;
  z-index: 201;
}

/* Make ready-only note blur */
.editorContainer[data-luru-editable-status='ReadonlySorNote'] .canvas,
.editorContainer[data-luru-editable-status='ReadonlyCRMVersionDifferent'] .canvas,
.editorContainer[data-luru-editable-status='ReadonlyCRMRecordDeleted'] .canvas,
.editorContainer[data-luru-editable-status='ReadonlyNoEditAccessInCRM'] .canvas {
  filter: blur(1px);
}

@media (max-width: 600px) {
  .banner {
    display: flex;
    position: sticky;
    top: 0;
    flex-direction: column;
    align-items: center;
    margin: 0.5em 1.5em;
    border: 1px solid var(--brand-accent-color-gray);
    border-left-width: 0;
    border-top-width: 0;
    border-radius: 0.5em;
    padding: 0.5em 2em;
    background-color: var(--brand-accent-color-pink-lighter-2);
    font-size: 0.8em;
    text-align: center;
    align-items: center;
    align-content: center;
    z-index: 99;
  }
  .bannerButtonContainer {
    margin-left: 0em;
  }
}

@media (min-width: 600px) {
  .bannerButtonContainer {
    margin-left: auto;
  }
}

.bannerButtonContainer {
  width: 10em;
  text-align: right;
}

[data-role='editor-banner'] {
  user-select: none;
}

[data-role='editor-banner-message'] {
  text-align: left;
  font-size: 1.2em;
  line-height: 150%;
}

[data-role='editor-banner-button'] {
  width: 10em;
  min-width: 10em;
  text-align: center;
}

[data-role='editor-banner-button'] span {
  position: relative;
}

[data-role='editor-banner-button'] span::after {
  top: 0;
  right: -1em;
}

.hidden {
  display: none;
  visibility: hidden;
}

[data-luru-editable-status='ReadonlyNoEditAccessInCRM'] .bannerButtonContainer {
  display: none;
  visibility: hidden;
}

.editorLoading {
  display: table;
  position: relative;
  width: 100%;
  height: 100%;
}

.editorLoading .loadingMessage {
  display: flex;
  position: absolute;
  top: calc(50% - 150px);
  width: 100%;
  height: 300px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;
}

.editorLoading .loadingMessage img {
  width: 100px;
}

.editorLoading .loadingTemplate {
  display: flex;
  width: 200px;
  height: 200px;
  align-items: center;
  justify-content: center;
  margin-left: 20em;
}

.editorLoading .canvasTemplate {
  width: 100%;
}

.canvas {
  position: relative;
  min-height: calc(100% - 2em);
  margin: 0;
  border-radius: 0.3em;
  -webkit-user-select: text;
  user-select: none;
  width: 98%;
}

@media (min-width: 600px) {
  .canvas {
    margin: 0.5em 1em;
  }
}

@media (min-width: 900px) {
  .main {
    margin: 1em;
  }
}

[contenteditable] {
  outline: 0;
}

/** Colors */
.noteTable {
  --question-background-color: var(--brand-accent-color-blue-lighter-1);
  --answer-background-color: var(--brand-accent-color-blue-lighter-2);
  --question-background-color: var(--brand-accent-color-gray);
  --answer-background-color: var(--brand-accent-color-gray-lighter-2);
}

.noteTable {
  table-layout: fixed;
  width: 100%;
  margin: 0 auto;
  border-spacing: 0;
  border-collapse: collapse;
  tab-size: 4;
  line-height: 1.5em;
  vertical-align: top;
}

@media (min-width: 600px) {
  .noteTable {
    width: 100%;
  }
}

@media (min-width: 900px) {
  .noteTable {
    width: 100%;
  }
}

.noteTable .note[contenteditable]:after {
  color: var(--background-color);
}

.ruler .tabstop {
  width: 1.5em;
  height: 1em;
  /* border: 1px solid cornflowerblue; */
}

.tabstop:first-child {
  width: 1em;
}

.tabstop:first-child + .tabstop {
  width: 3em;
}

.lastTabstop {
  width: 10em;
  /* border: 1px solid burlywood; */
}

.prefix,
.note {
  height: calc(2.1em + 2px);
  min-height: calc(2.1em + 2px);
}

.prefix {
  width: 1em;
  padding: 0.3em;
  text-align: right;
  vertical-align: top;
  user-select: none;
}

.note {
  padding: 0.3em 0.5em;
  font-weight: var(--regular-font-weight);
  white-space: break-spaces;
  vertical-align: top;
}

.noteTable:focus .note[contenteditable]:after {
  content: attr(placeholder) !important;
  color: var(--placeholder-color) !important;
}

/** Basic formatting */
.note b {
  font-weight: var(--bold-font-weight);
}

.note i {
  font-style: italic;
}

.note u {
  text-decoration: underline;
}

/** Heading */
.heading1 .note,
.heading2 .note,
.heading3 .note,
.answerHeading1 .note,
.answerHeading2 .note,
.answerHeading3 .note {
  font-weight: var(--bold-font-weight);
}

.heading1 .note,
.answerHeading1 .note {
  padding: 0.3em;
  font-size: 1.7em;
}

.heading2 .note,
.answerHeading2 .note {
  padding: 0.3em;
  font-size: var(--font-size-increase-1);
}

.heading3 .note,
.answerHeading3 .note {
  padding: 0.4em;
  font-size: var(--font-size-increase-1);
}

.answerHeading1 .prefix,
.answerHeading2 .prefix,
.answerHeading3 .prefix,
.answerHeading1 .note,
.answerHeading2 .note,
.answerHeading3 .note {
  background-color: var(--answer-background-color);
}

/** Paragraph */
.paragraph .note,
.answerParagraph .note {
  height: calc(2.1em + 2px);
  min-height: calc(2.1em + 2px);
}

/** Unordered list */
.unorderedListLevel1 .prefix,
.unorderedListLevel2 .prefix,
.unorderedListLevel3 .prefix,
.answerUnorderedListLevel1 .prefix,
.answerUnorderedListLevel2 .prefix,
.answerUnorderedListLevel3 .prefix {
  vertical-align: top;
  padding-top: 1.8em;
  padding-right: 1.1em;
  font-size: 0.4em;
}

/** Ordered list */
.content {
  counter-reset: orderedList1 orderedList2 orderedList3 answerOrderedList1 answerOrderedList2 answerOrderedList3;
}

.orderedListLevel1 .prefix {
  padding-top: 0.4em;
}

.orderedListLevel3 .prefix {
  padding-top: 0.33em;
}

.answerOrderedListLevel1 .prefix,
.orderedListLevel2 .prefix {
  padding-top: 0.33em;
}

.orderedListLevel1:first-child {
  counter-reset: orderedList1;
}

.orderedListLevel2:first-child {
  counter-reset: orderedList2;
}

.orderedListLevel3:first-child {
  counter-reset: orderedList3;
}

.answerOrderedListLevel1:first-child {
  counter-reset: answerOrderedList1;
}

.answerOrderedListLevel2:first-child {
  counter-reset: answerOrderedList2;
}

.answerOrderedListLevel3:first-child {
  counter-reset: answerOrderedList3;
}

:not(.orderedListLevel1):not(.orderedListLevel2):not(.orderedListLevel3) + .orderedListLevel1 {
  counter-reset: orderedList1;
}

:not(.orderedListLevel2):not(.orderedListLevel3) + .orderedListLevel2 {
  counter-reset: orderedList2;
}

:not(.orderedListLevel3) + .orderedListLevel3 {
  counter-reset: orderedList3;
}

/** Solving for corner case */
.orderedListLevel1 + .orderedListLevel3 + .orderedListLevel2 {
  counter-reset: orderedList3;
}

.orderedListLevel1 + .orderedListLevel3 + .orderedListLevel3 + .orderedListLevel2,
.orderedListLevel1 + .orderedListLevel3 + .orderedListLevel3 + .orderedListLevel3 + .orderedListLevel2,
.orderedListLevel1
  + .orderedListLevel3
  + .orderedListLevel3
  + .orderedListLevel3
  + .orderedListLevel3
  + .orderedListLevel2,
.orderedListLevel1
  + .orderedListLevel3
  + .orderedListLevel3
  + .orderedListLevel3
  + .orderedListLevel3
  + .orderedListLevel3
  + .orderedListLevel2,
.orderedListLevel1
  + .orderedListLevel3
  + .orderedListLevel3
  + .orderedListLevel3
  + .orderedListLevel3
  + .orderedListLevel3
  + .orderedListLevel3
  + .orderedListLevel2,
.orderedListLevel1
  + .orderedListLevel3
  + .orderedListLevel3
  + .orderedListLevel3
  + .orderedListLevel3
  + .orderedListLevel3
  + .orderedListLevel3
  + .orderedListLevel3
  + .orderedListLevel2,
.orderedListLevel1
  + .orderedListLevel3
  + .orderedListLevel3
  + .orderedListLevel3
  + .orderedListLevel3
  + .orderedListLevel3
  + .orderedListLevel3
  + .orderedListLevel3
  + .orderedListLevel3
  + .orderedListLevel2,
.orderedListLevel1
  + .orderedListLevel3
  + .orderedListLevel3
  + .orderedListLevel3
  + .orderedListLevel3
  + .orderedListLevel3
  + .orderedListLevel3
  + .orderedListLevel3
  + .orderedListLevel3
  + .orderedListLevel3
  + .orderedListLevel2,
.orderedListLevel1
  + .orderedListLevel3
  + .orderedListLevel3
  + .orderedListLevel3
  + .orderedListLevel3
  + .orderedListLevel3
  + .orderedListLevel3
  + .orderedListLevel3
  + .orderedListLevel3
  + .orderedListLevel3
  + .orderedListLevel3
  + .orderedListLevel2 {
  counter-reset: orderedList2;
}

:not(.answerOrderedListLevel1):not(.answerOrderedListLevel2):not(.answerOrderedListLevel3) + .answerOrderedListLevel1 {
  counter-reset: answerOrderedList1;
}

:not(.answerOrderedListLevel2):not(.answerOrderedListLevel3) + .answerOrderedListLevel2 {
  counter-reset: answerOrderedList2;
}

:not(.answerOrderedListLevel3) + .answerOrderedListLevel3 {
  counter-reset: answerOrderedList3;
}

/** Solving for corner case */
.answerOrderedListLevel1 + .answerOrderedListLevel3 + .answerOrderedListLevel2 {
  counter-reset: answerOrderedList3;
}

.answerOrderedListLevel1 + .answerOrderedListLevel3 + .answerOrderedListLevel3 + .answerOrderedListLevel2,
.answerOrderedListLevel1
  + .answerOrderedListLevel3
  + .answerOrderedListLevel3
  + .answerOrderedListLevel3
  + .answerOrderedListLevel2,
.answerOrderedListLevel1
  + .answerOrderedListLevel3
  + .answerOrderedListLevel3
  + .answerOrderedListLevel3
  + .answerOrderedListLevel3
  + .answerOrderedListLevel2,
.answerOrderedListLevel1
  + .answerOrderedListLevel3
  + .answerOrderedListLevel3
  + .answerOrderedListLevel3
  + .answerOrderedListLevel3
  + .answerOrderedListLevel3
  + .answerOrderedListLevel2,
.answerOrderedListLevel1
  + .answerOrderedListLevel3
  + .answerOrderedListLevel3
  + .answerOrderedListLevel3
  + .answerOrderedListLevel3
  + .answerOrderedListLevel3
  + .answerOrderedListLevel3
  + .answerOrderedListLevel2,
.answerOrderedListLevel1
  + .answerOrderedListLevel3
  + .answerOrderedListLevel3
  + .answerOrderedListLevel3
  + .answerOrderedListLevel3
  + .answerOrderedListLevel3
  + .answerOrderedListLevel3
  + .answerOrderedListLevel3
  + .answerOrderedListLevel2,
.answerOrderedListLevel1
  + .answerOrderedListLevel3
  + .answerOrderedListLevel3
  + .answerOrderedListLevel3
  + .answerOrderedListLevel3
  + .answerOrderedListLevel3
  + .answerOrderedListLevel3
  + .answerOrderedListLevel3
  + .answerOrderedListLevel3
  + .answerOrderedListLevel2,
.answerOrderedListLevel1
  + .answerOrderedListLevel3
  + .answerOrderedListLevel3
  + .answerOrderedListLevel3
  + .answerOrderedListLevel3
  + .answerOrderedListLevel3
  + .answerOrderedListLevel3
  + .answerOrderedListLevel3
  + .answerOrderedListLevel3
  + .answerOrderedListLevel3
  + .answerOrderedListLevel2,
.answerOrderedListLevel1
  + .answerOrderedListLevel3
  + .answerOrderedListLevel3
  + .answerOrderedListLevel3
  + .answerOrderedListLevel3
  + .answerOrderedListLevel3
  + .answerOrderedListLevel3
  + .answerOrderedListLevel3
  + .answerOrderedListLevel3
  + .answerOrderedListLevel3
  + .answerOrderedListLevel3
  + .answerOrderedListLevel2 {
  counter-reset: answerOrderedList2;
}

.orderedListLevel1 .prefix::before {
  counter-increment: orderedList1;
  content: counter(orderedList1, decimal);
}

.orderedListLevel2 .prefix::before {
  counter-increment: orderedList2;
  content: counter(orderedList2, lower-alpha);
}

.orderedListLevel3 .prefix::before {
  counter-increment: orderedList3;
  content: counter(orderedList3, lower-roman);
}

.answerOrderedListLevel1 .prefix::before {
  counter-increment: answerOrderedList1;
  content: counter(answerOrderedList1, decimal);
}

.answerOrderedListLevel2 .prefix::before {
  counter-increment: answerOrderedList2;
  content: counter(answerOrderedList2, lower-alpha);
}

.answerOrderedListLevel3 .prefix::before {
  counter-increment: answerOrderedList3;
  content: counter(answerOrderedList3, lower-roman);
}

/** Question and answer */
.question .prefix,
.question .note {
  background-color: var(--question-background-color);
  color: var(--question-foreground-color);
}

.question .prefix {
  border-top-left-radius: 0.5em;
  font-size: 0.8em;
  text-align: center;
}

.question .prefix,
.question .note {
  font-weight: var(--bold-font-weight);
}

.question .note {
  border-top-right-radius: 0.5em;
}

.answer .prefix,
.answer .note {
  background-color: var(--answer-background-color);
}

/** Tasks */
.taskComplete .prefix,
.taskIncomplete .prefix,
.answerTaskComplete .prefix,
.answerTaskIncomplete .prefix {
  font-weight: var(--bold-font-weight);
  cursor: pointer;
}

.answerTaskComplete .note,
.answerTaskIncomplete .note,
.answerTaskComplete .prefix,
.answerTaskIncomplete .prefix {
  background-color: var(--answer-background-color);
}

.dragHandle {
  background-image: url('../../../images/drag-indicator.png');
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: 0px 8px;
  opacity: 0;
  cursor: pointer;
  user-select: none;
}

.jotting:hover .dragHandle,
.dragHandle:hover {
  opacity: 1;
}

/** Testing */
.prefix,
.note,
.jotting {
  border: 1px solid lightblue;
  border: none;
}

.crmFieldValue {
  background-color: transparent;
}

.fieldContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.crmLabel {
  width: 90%;
  border: 1px solid var(--brand-accent-color-blue-lighter-2);
  border-radius: 0.2em;
  padding: 0 0.5em 0 0.5em;
  /* height: 2.5em; */
}

@media (min-width: 600px) {
  .crmLabel {
    width: 50%;
  }
}

@media (min-width: 900px) {
  .crmLabel {
    width: 50%;
  }
}

[data-field-type='multipicklist'] .crmLabel {
  width: 96%;
}

.crmLabel legend {
  padding: 0 0.5em 0 0.5em;
  color: var(--brand-accent-color-blue);
  font-size: 0.7em;
  font-weight: var(--bold-font-weight);
  line-height: 1em;
}

/** CRM field value  types */
.note .date,
.note .datetime,
.note .int,
.note .double,
.note .currency,
.note .percent,
.note .string,
.note .boolean,
.note .multipicklist,
.note .reference,
.note .textarea,
.note .address,
.note .phone,
.note .email,
.note .url,
.note .integer_noformat {
  display: block;
  width: 100%;
  border: 1px solid var(--brand-accent-color-blue-lighter-2);
  border: none;
  outline: none;
  border-radius: 0em;
  background-color: var(--background-color);
  padding: 0 0.5em 0.2em 0.5em;
  font-weight: var(--regular-font-weight);
  font-size: 0.9em;
}

.crmFieldNote {
  margin: 0.3em 0;
  padding: 0.3em;
  border-radius: 0.3em;
  background-color: var(--brand-accent-color-gray-lighter-2);
  font-size: 0.9em;
}

.updateFieldInfo {
  padding: 0.4em 0 0 1em;
  font-size: 0.8em;
}

.updateFieldInfo.success {
  color: var(--brand-accent-color-blue);
}

.updateFieldInfo.error {
  color: darkred;
}

.updateFieldInfo.loading {
  color: var(--brand-accent-color-blue);
}

.updateFieldInfo.fieldChangedTip {
  color: var(--brand-accent-color-blue-lighter-1);
}

.fieldChangedTip::before {
  display: inline-block;
  content: ' ';
  width: 2em;
  background-image: url('../../../images/info.svg');
  background-size: 1em 1em;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  filter: invert(0.5) saturate(5) hue-rotate(247deg);
}

.note input:invalid {
  color: darkred;
}

.jotting:hover .crmFieldDeleteButton {
  opacity: 1;
  transition: opacity 0.15s ease-out;
}

.crmFieldDeleteButton {
  display: inline-block;
  width: 1em;
  height: 1em;
  margin: 1.3em 0em 1em 0.5em;
  border: none;
  background-color: var(--background-color);
  background-image: url('../../../images/remove-icon.svg');
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 1em 1em;
  outline: none;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.15s ease-in;
}

/* Links */
.note [data-luru-role='note-hyperlink'] {
  position: relative;
  color: var(--brand-accent-color-blue);
  text-decoration: underline;
}

.note strong {
  font-weight: var(--bold-font-weight);
}

/* Formatter */
.note strong,
.note i,
.note a,
.note mark {
  text-decoration-line: inherit;
}

.note mark {
  background-color: yellow;
}

/* Select CSS */
.noteTable {
  user-select: none;
}

.jotting {
  user-select: none;
}

.note {
  user-select: all;
  -webkit-user-select: auto !important;
}

div.noBorder {
  width: 100%;
  margin-bottom: -0.5em;
}

div.noBorder input[type='search'] {
  width: 100%;
  padding: 0 0 0 0.4em;
  border-color: transparent;
  border-radius: 0;
  font-size: 0.9em;
}

div.noBorder input[type='search']:focus {
  box-shadow: none;
}
