.modalContent {
  justify-content: flex-start;
  overflow: auto;
  max-height: 95vh;
  overflow: visible;
  background-color: #f9fcfc;
}

.modalBody {
  padding: 0em 2em 2em 2em;
  width: 600px;
  overflow-x: hidden;
  overflow: visible;
}

.parentStyle {
  width: 100%;
  display: grid;
  /* grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); Responsive columns */
  grid-template-areas:
    'error error error'
    'name name name'
    'date time duration'
    'contacts contacts contacts';
  gap: 10px 2em;
}

.parentStyle > div.nameDiv {
  grid-area: name;
  align-self: center;
}

.parentStyle > div.dateDiv {
  grid-area: date;
  align-self: center;
  margin-top: 5px;
}

.parentStyle > div.timeDiv {
  grid-area: time;
  align-self: center;
}

.parentStyle > div.durationDiv {
  grid-area: duration;
  align-self: center;
}

.parentStyle > div.contactDiv {
  grid-area: contacts;
  align-self: center;
  width: 45%;
}

div.contactDiv button {
  width: 100%;
}

div.timeDiv div {
  background-color: white;
}

.parentStyle label {
  margin-left: 2px;
}

@media (max-width: 500px) {
  .modalContent {
    overflow-x: hidden !important;
  }
  .modalBody {
    width: 350px;
    transform: scale(0.9);
  }

  .parentStyle {
    grid-template-areas:
      'error error error'
      'name name name'
      'date date date'
      'time time time'
      'duration duration duration'
      'contacts contacts contacts';
  }
}

@media screen and (max-width: 400px) {
  .parentStyle {
    grid-template-rows: repeat(1, 1fr);
  }
}

@media (max-width: 600px) and (min-width: 501px) {
  .modalContent {
    overflow-x: hidden !important;
  }
  .modalBody {
    width: 450px;
    transform: scale(0.96);
  }

  .parentStyle {
    grid-template-areas:
      'error error error'
      'name name name'
      'date date date'
      'time time duration'
      'contacts contacts contacts';
  }
}

.actionButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1em;
  width: 90%;
  overflow: hidden;
  gap: 1em;
}

.actionButtons button[data-role='create-crm-log-cancel-button'] {
  margin-left: auto;
}

.actionButtons button[data-role='create-crm-log-button'] {
  font-weight: var(--bold-font-weight);
}

.formItemGroupHorizontal {
  display: flex;
  align-items: center;
  gap: 0.5em;
  justify-content: space-between;
}

.wrap {
  flex-wrap: wrap;
}

.formItem {
  display: flex;
  flex-direction: column;
  gap: 0.3em;
  margin-bottom: 1em;
}

.formItem label {
  font-weight: var(--bold-font-weight);
}

.error {
  color: var(--warning-color);
  margin-bottom: 0.1em;
  grid-area: error;
}
