.main {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5em;
  width: 100%;
}

img.crmIcon[data-luru-icon-name] {
  vertical-align: middle;
  width: 1.3em;
  margin: 0.15em 0.25em;
}

img.crmIcon[data-luru-icon-name='sfdc-opportunity'] {
  height: 1.3em;
  min-width: 1.3em;
  transform: scale(1.8);
}

img.crmIcon[data-luru-icon-name='sfdc-contact'] {
  height: 1.3em;
  min-width: 1.3em;
  transform: scale(1.8);
}

img.crmIcon[data-luru-icon-name='sfdc-account'] {
  height: 1.3em;
  min-width: 1.3em;
  transform: scale(1.8);
}

img.crmIcon[data-luru-icon-name='sfdc-lead'] {
  height: 1.3em;
  min-width: 1.3em;
  transform: scale(1.8);
}

img.crmIcon[data-luru-icon-name='pipedrive-deal'] {
  height: 1.3em;
  min-width: 1.3em;
  transform: scale(1.8);
}

img.crmIcon[data-luru-icon-name='pipedrive-organization'] {
  height: 1.3em;
  min-width: 1.3em;
  transform: scale(1.8);
}

img.crmIcon[data-luru-icon-name='pipedrive-person'] {
  height: 1.3em;
  min-width: 1.3em;
  transform: scale(1.8);
}

img.crmIcon[data-luru-icon-name='pipedrive-lead'] {
  height: 1.3em;
  min-width: 1.3em;
  transform: scale(1.8);
}

.cloudIcon {
  vertical-align: middle;
  height: 1.3em;
  min-width: 1.3em;
  margin: 0.15em 0.25em;
}

.meetingIcon {
  vertical-align: middle;
  height: 1.3em;
  min-width: 1.3em;
  margin: 0.15em 0.14em;
  /* padding: 0.2em; */
}

.text {
  display: inline-block;
  /* width: 90%; */
  flex-grow: 1;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.9em;
}

.buttonBorder {
  border: 1px solid var(--brand-accent-color-blue-lighter-2);
}

.ovalButton {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.ovalButtonRegular {
  width: auto !important;
  flex-grow: 1;
  max-width: 50% !important;
}

@media (max-width: 800px) {
  .ovalButtonRegular {
    width: auto !important;
    flex-grow: 1;
    max-width: 75% !important;
  }
}

/* @media (max-width: 600px) {
  .ovalButtonRegular {
    width: auto !important;
    flex-grow: 1;
    max-width: 75% !important;
  }
}

@media (min-width: 600px) {
  .ovalButtonRegular {
    width: auto !important;
    flex-grow: 1;
    max-width: 50% !important;
  }
}

@media (min-width: 1200px) {
  .ovalButtonRegular {
    width: auto !important;
    flex-grow: 1;
    max-width: 50.5% !important;
  }
} */

.ovalButtonEmbedded {
  width: auto !important;
  flex-grow: 1;
}
