.parent {
  display: inline-block;
}

.parent .userContainer {
  display: flex;
  flex-direction: row;
  justify-content: baseline;
  align-items: center;
  gap: 0;
}

.parent .userContainer .nameEmailContainer {
  margin: 0 0 0 1em;
}

.parent .userContainer .nameEmailContainer .userName {
  margin: 0 0 0.3em 0;
  border: none;
  padding: 0;
  font-weight: var(--bold-font-weight);
}

.parent .userContainer .nameEmailContainer .userEmail {
  margin: 0;
  border: none;
  padding: 0;
  font-size: 0.85em;
  font-weight: normal;
}

.parent .userContainer .nameEmailContainer .selected {
  margin: 0;
  border: none;
  padding: 0 0.3em 0 0;
  font-size: 0.85em;
  font-weight: normal;
}
