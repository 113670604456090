.parent {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.inputRow {
  display: flex;
  flex-direction: row;
}

.inputBox {
  flex-grow: 1;
  border: none;
  padding: 0 0.5em 0 0.5em;
  font-size: var(--font-size-decrease-1);
  cursor: pointer;
}

.inputBox:focus {
  outline: none;
}

.dropdownButton {
  margin-left: auto;
  border: none;
  outline: none;
  background-color: var(--background-color);
  color: var(--brand-accent-color-blue-lighter-1);
  cursor: pointer;
}

.dropdownButton > img {
  filter: var(--brand-accent-color-blue-lighter-1-filter);
}

.dropdownList {
  --list-item-line-height: 1.2em;
  --list-item-padding-x: calc(1em + 1px);
  --list-item-padding-y: 0.3em;
  --list-items-max-count: 7;
  --dropdown-height: calc(
    var(--list-items-max-count) * (2 * var(--list-item-padding-y) + var(--list-item-line-height))
  );
  position: absolute;
  width: 100%;
  border: var(--luru-border);
  border-radius: var(--luru-border-radius);
  box-shadow: var(--luru-box-shadow);
  background-color: var(--background-color);
  font-size: var(--font-size-decrease-1);
  z-index: 50;
  user-select: none;
}

.dropdownList input {
  width: calc(100% - 2em);
  margin: 1em 1em 0;
  padding: 0.3em 0.5em;
}

.dropdownList ul {
  max-height: var(--dropdown-height);
  list-style-type: none;
  margin: 0.5em;
  overflow-y: auto;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dropdownList li {
  display: flex;
  flex-direction: row;
  justify-content: baseline;
  align-items: center;
  padding: var(--list-item-padding-y) var(--list-item-padding-x);
  line-height: var(--list-item-line-height);
}

.dropdownList li span:first-child {
  margin-right: var(--list-item-padding-y);
  font-size: 1.5em;
}

.dropdownList li span.searchResult {
  white-space: pre;
}

.dropdownList li span.searchResult b {
  background-color: var(--brand-accent-color-orange-lighter-1);
  color: black;
  font-weight: var(--regular-font-weight);
}

.dropdownList.expandedDown {
  visibility: visible;
  display: block;
  opacity: 1;
  transform: translateY(2em);
  transition: transform 0.5s linear;
}

.dropdownList.collapsed {
  visibility: hidden;
  display: none;
  opacity: 0;
  transform: translateY(calc((-1) * var(--dropdown-height)));
  transition: transform 0.5s linear;
}

.dropdownList li {
  cursor: pointer;
}

.dropdownList li:hover {
  background-color: var(--brand-accent-color-blue-lighter-2);
}

.dropdownList li.selected,
.dropdownList li.selected:hover {
  background-color: var(--brand-accent-color-blue-lighter-1);
  color: white;
}

li.selected > img {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(290deg) brightness(105%) contrast(102%);
}
