.parent {
  --explorer-height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  /* padding: 0.3em 1em; */
  border-top: 1px solid var(--brand-accent-color-gray);
  background-color: var(--background-color);
  transform: translateY(calc(var(--explorer-height) - 0 * var(--header-height)));
  transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 200;
  user-select: none;
}

.parent.expanded {
  transform: translateY(0);
  transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.3em 1em;
}

.leftPanel,
.rightPanel {
  /* margin: 1em; */
  border: 0;
  outline: 0;
  background: none;
  color: var(--text-subdued);
  color: var(--brand-accent-color-blue);
  cursor: pointer;
}

.leftPanel {
  display: flex;
  flex-direction: row;
  justify-self: center;
  align-items: center;
}

.rightPanel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.3em;
  height: 2em;
  margin: 0;
  margin-left: auto;
  border-radius: 50%;
  padding: 0;
}

.leftPanel img,
.rightPanel img {
  height: 1.2em;
}

.leftPanel span,
.rightPanel span {
  white-space: nowrap;
}

.explorer {
  display: flex;
  flex-direction: column;
  gap: 1em;
  height: calc(var(--explorer-height) - var(--header-height));
  padding: 0.3em 1em;
  overflow-x: hidden;
  overflow-y: auto;
}

.explorer div[data-luru-role='type-ahead-search-box'] input:first-child {
  width: 100%;
  margin: 0;
  padding: 0.3em 0.5em;
}

.explorer h2 {
  margin: 1em 0;
  font-weight: var(--bold-font-weight);
}

.explorer li {
  border: 1px solid var(--background-color);
}

.explorer summary {
  padding: 0;
  padding-top: 0.3em;
}

.explorer details {
  padding: 0;
}

.explorer summary img {
  margin-right: 0.5em;
}

.explorer details li {
  padding-top: 0.3em;
  padding-bottom: 0.3em;
}

.explorer > button {
  margin-left: auto;
  margin-right: auto;
}

.explorer > div.expandWidget {
  flex-grow: 1;
  max-height: 50%;
}

.leftPanel span,
.rightPanel span {
  white-space: nowrap;
}
