.l-layout-row {
  display: flex;
  flex-direction: row;
}

.l-layout-column {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.l-layout-justify-start {
  justify-content: flex-start;
}

.l-layout-justify-end {
  justify-content: end;
}

.l-layout-justify-center {
  justify-content: center;
}

.l-layout-flex-wrap {
  flex-wrap: wrap;
}

.l-layout-justify-space-around {
  justify-content: space-around;
}

.l-layout-justify-space-between {
  justify-content: space-between;
}

.l-layout-align-top {
  align-items: top;
}

.l-layout-align-bottom {
  align-items: bottom;
}

.l-layout-align-center {
  align-items: center;
}
