div.detailsTab {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.omnibox {
  height: 290px;
  flex-grow: 1;
  overflow: auto;
}

.actionButtons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 1rem;
}

.fieldsetChooserContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.error {
  color: var(--warning-color);
}

.selected {
  border: 2px solid var(--brand-accent-color-blue);
  border-radius: 0.3em;
}

.loading,
.error {
  color: var(--brand-accent-color-blue-lighter-1);
  text-align: center;
}

.alert {
  margin-bottom: 1em;
  padding: 0.3em 1em;
  color: red;
  font-size: var(--font-size-decrease-1);
  text-align: center;
}

div.omniboxBody {
  height: 500px;
}

.modalTitle {
  display: flex;
  align-items: center;
}

.modalTitle span img {
  width: 2em;
  margin-right: 0.1em;
}

.tabContainer {
  display: flex;
  justify-content: space-between;
  gap: 2em;
  margin: 0.8em 0em 2em 0em;
}
.tab {
  cursor: pointer;
}
.tabSelected {
  text-decoration: none;
  position: relative;
}
.tabSelected:after {
  position: absolute;
  content: '';
  height: 2px;
  bottom: -4px;
  left: 0;
  right: 0;
  width: 100%;
  background: var(--brand-accent-color-blue);

  /* optional animation */
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

h2.notesTitle {
  margin-top: 0.5em;
  font-weight: var(--bold-font-weight);
  display: flex;
  align-items: center;
}

h2.notesTitle > img {
  height: 1.4em;
  margin-right: 0.5em;
  filter: invert(74%) sepia(2%) saturate(3%) hue-rotate(318deg) brightness(83%) contrast(87%);
}

div.searchResults {
  overflow: auto;
}

div.loadingBlockContainer,
div.errorBlockContainer,
div.noCalendarContainer {
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: center;
  justify-content: center;
  height: 300px;
}

div.errorBlockContainer button {
  align-self: center;
}

div.searchResults > div.loading {
  width: 100%;
  padding: 1.5em 0;
  color: var(--brand-accent-color-blue-lighter-1);
  text-align: center;
  vertical-align: middle;
}

div.searchResults ul.resultsList {
  list-style-type: none;
}

div.searchResults ul.resultsList > li {
  border-radius: 0.3em;
  padding: 0.6em 1em;
  cursor: pointer;
}

div.searchResults ul.resultsList > li:hover {
  background-color: var(--brand-accent-color-blue-lighter-2);
}

/* div.searchResults ul.resultsList > li:nth-child(1),
div.searchResults ul.resultsList > li:nth-child(2),
div.searchResults ul.resultsList > li:nth-child(3),
div.searchResults ul.resultsList > li:nth-child(4),
div.searchResults ul.resultsList > li:nth-child(5) {
  display: block;
}

div.searchResults ul.resultsList.expanded > li {
  display: block;
} */

div.searchResults button.loadMore {
  width: 8em;
  display: block;
  margin: 0 auto 0.5em;
  border: 1px solid var(--brand-accent-color-gray);
  border-radius: 0.3em;
  padding: 0.3em 1em;
  background-color: var(--background-color-secondary);
  color: var(--brand-accent-color-blue-lighter-1);
  cursor: pointer;
}

div.searchResults button.loadMore:hover {
  background-color: var(--brand-accent-color-blue-lighter-2);
}

div.searchResults div.noResults {
  font-size: var(--font-size-decrease-1);
  padding: 0.5em 0;
  text-align: center;
  color: var(--text-subdued);
}

div.allSearchResults {
  max-height: 700px;
  margin-top: 1em;
  /* overflow: auto; */
}

div.noteResult,
div.meetingResult,
div.taskResult,
div.crmResult {
  height: 1.2em;
  display: flex;
  flex-direction: row;
  align-items: center;
}

div.noteResult > label.title,
div.meetingResult > label.title,
div.taskResult > label.title,
div.crmResult > label.title {
  width: 40%;
  flex: 40% 0 0;
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 0.8em;
  cursor: pointer;
}

span.timestamp > span.icon > img {
  margin-left: -2 em;
}

div.noteResult > span.timestamp,
div.meetingResult > span.timestamp,
div.taskResult > span.timestamp {
  width: 20%;
  flex: 20% 0 0;
  margin: 0 0.5em;
  text-align: center;
  color: var(--text-subdued);
  font-size: var(--font-size-decrease-1);
}

span.icon {
  margin-right: 0.7em;
  vertical-align: middle;
}

span.icon > img {
  height: 1.5em;
  float: left;
}

div.noteResult > span.icon > img {
  width: 1.5em;
}

div.noteResult > span.recordName,
div.meetingResult > .meetingNoteTitle {
  /* flex: 30% 0 0; */
  /* border-radius: 0.3em; */
  font-size: var(--font-size-decrease-1);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

div.meetingResult > .meetingNoteTitle {
  padding: 0.3em 1em;
  cursor: pointer;
}

div.taskResult span.checkbox {
  width: 1.2em;
  flex: 1.2em 0 0;
}

div.taskResult span.checkbox span.spinnerContainer {
  display: none;
}

div.taskResult span.checkbox.toggling span.spinnerContainer {
  display: inline-block;
}
div.taskResult span.checkbox.toggling span.box {
  display: none;
}

div.taskResult span.timestamp,
div.taskResult span.assignee {
  border: 1px solid var(--brand-accent-color-blue-lighter-2);
  border-radius: 0.5em;
  padding: 0.2em;
  font-size: var(--font-size-decrease-2);
  background-color: var(--background-color-secondary);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
  flex: 20% 0 0;
}

div.taskResult span.timestamp span.icon img,
div.taskResult span.assignee span.icon img {
  margin-right: 0.5em !important;
  vertical-align: middle;
}

div.taskResult span.timestamp > span.dueDateText {
  flex-grow: 1;
  color: var(--text-subdued);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

div.taskResult span.assignee > span.assigneeNames {
  flex-grow: 1;
  color: var(--text-subdued);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

span.w {
  margin-left: auto;
  /* font */
}

div.noteResult > span.icon,
div.taskResult > span.icon,
div.meetingResult > span.icon {
  width: 1.5em;
  flex: 1.5em 0 0;
  height: var(--font-size-decrease-1);
}

div.meetingResult > span.icon > img {
  width: 1em;
}
div.meetingResult > span.icon > img[alt='Add note'] {
  filter: invert(74%) sepia(2%) saturate(3%) hue-rotate(318deg) brightness(83%) contrast(87%);
}

div.noteResult > span.recordName,
div.meetingResult > .meetingNoteTitle {
  flex: 30% 0 0;
  border-radius: 0.3em;
  font-size: var(--font-size-decrease-2);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

div.meetingResult > .meetingNoteTitle {
  padding: 0.3em 1em;
  cursor: pointer;
}

div.meetingResult > .meetingNoteTitle > a {
  width: 100%;
  display: inline-block;
}

div.meetingResult > .meetingNoteTitle:hover {
  background-color: var(--brand-accent-color-blue-lighter-2);
}

div.meetingResult > span.buttonish {
  --font-size: var(--font-size-decrease-2);
  flex: 20% 0 0;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  border: 1px solid var(--brand-accent-color-blue-lighter-2);
  border-radius: calc(0.5 * 1.1 * var(--font-size));
  padding: 0.2em 1em;
  background-color: var(--background-color-secondary);
  text-align: center;
  color: var(--foreground-color);
  font-size: var(--font-size);
  user-select: none;
  cursor: pointer;
}

div.meetingResult > span.buttonish:hover {
  background-color: var(--brand-accent-color-blue-lighter-2);
}

.meetingTrailingContainer {
  width: 8em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

span.trailingIcon > img {
  width: 1.5em;
}

span.icon > img {
  width: 1.5em;
}

span.meetingIcon > img {
  width: 1em;
}

img.trailingImg {
  width: 1.5em;
}

span.trailingIcon > img {
  height: var(--font-size-decrease-1);
}

span.trailingIcon .participant {
  height: var(--font-size-increase-1);
}

div.taskResult span.overdue {
  border: 1px solid var(--brand-accent-color-pink-darker-2);
  /* background-color: lightcoral; */
  color: var(--brand-accent-color-pink-darker-2);
  font-weight: var(--bold-font-weight);
}

div.taskResult span.noduedate {
  border: 1px solid var(--brand-accent-color-orange-darker-2);
  color: var(--brand-accent-color-orange-darker-2);
}

div.loadMoreContainer {
  display: flex;
  justify-content: center;
  margin-top: 0.5em;
}

.divider {
  border: 0.5px solid var(--brand-accent-color-blue-lighter-2) !important;
  margin: 0.7em 0em 0.3em 0em;
}

.quickAccessContainer {
  padding: 0.3em;
}

img[data-crm-id='HUBSPOT'] {
  transform: scale(0.6);
}

img[data-crm-id='SFDC'] {
  transform: scale(0.6);
}
