.root {
  min-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1em !important;
}
.appLogo {
  display: flex;
  justify-content: center;
  margin-bottom: 3em;
  margin-top: 2em;
}
.appLogo img {
  width: 5em;
  /* height: 80px; */
  padding: 0;
}
.modalTitle h4 {
  font-size: 1.1em;
  font-weight: var(--bold-font-weight);
  color: var(--brand-accent-color-blue-lighter-1);
  text-align: center;
  margin-bottom: 2em;
}

.images {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2em;
  margin-bottom: 4em;
}
.images img {
  width: 3em;
}
.images h2 {
  scale: 2.5;
}

.images .rocketIcon {
  margin-left: 1em;
}

.modalBody {
  text-align: center;
}

.modalBody > p {
  margin-left: 10%;
  margin-right: 10%;
  font-size: 0.9em;
  margin-bottom: 4em;
  font-weight: var(--bold-font-weight);
}

.modalBody > button {
  margin: 0 auto;
}

.installButton {
  font-weight: var(--bold-font-weight);
  cursor: pointer;
  width: 200px;
}
