.parent {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 0.5em;
  width: 30em;
  z-index: 100;
}

[data-luru-role='embedded-meeting-note-parent'] .parent {
  width: 20em;
}

.visible {
  display: flex;
}

.hidden {
  display: none;
}

.templateChip {
  width: 10em;
  height: 2em;
  border: 1px solid var(--brand-accent-color-gray);
  border-radius: 0.3em;
  padding: 0.3em 1em;
  background-color: transparent;
  color: var(--brand-accent-color-gray);
  font-size: 0.8em;
  font-weight: var(--bold-font-weight);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.templateChip:hover {
  background-color: var(--background-color-secondary);
}
