@import-normalize html,
body,
div#root,
main,
div.main,
div.globalSearchMain {
  height: 100%;
}

:root {
  --bold-font-weight: 550;
  --regular-font-weight: 350;
  font-family: 'InterVariable', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  /* font-feature-settings: 'liga' 1, 'calt' 1, 'cv02', 'cv03', 'cv04', 'cv11'; */
}

@supports (font-variation-settings: normal) {
  :root {
    font-family: 'InterVariable', sans-serif;
  }
}

/* This seems to be important for avoiding scrollbars in ext. newtab */
html {
  overflow: hidden;
}

div#root {
  position: relative;
}

*,
html,
body {
  font-size: inherit;
  font-family: 'InterVariable', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-feature-settings: 'tnum', 'ss01', 'ss08', 'cpsp', 'liga' 1, 'calt' 1, 'cv02', 'cv03', 'cv04', 'cv11';
}

html {
  font-size: 14.4px;
}

/* Colors */
/* Light theme */
body {
  /* Colors that change between light and dark themes */
  --background-color: hsla(0, 0%, 100%, 100%);
  --background-color-secondary: hsla(256, 100%, 98%, 100%);
  --brand-accent-color-gray-lighter-2: #f9f9f9;
  --background-color-semi-transparent: hsla(180, 1%, 97%, 95%);
  --foreground-color: rgb(51, 65, 85);
  --text-subdued: #666;
  --text-subdued-filter: invert(65%) sepia(11%) saturate(25%) hue-rotate(341deg) brightness(91%) contrast(96%);
  --text-light-label: hsla(247, 100%, 80%, 100%);
  --disabled-background-color: hsla(0, 0%, 90%, 50%);
  --row-highlight-color: hsla(198, 86%, 97%, 100%);
  --notification-color-normal: hsla(0, 0%, 30%, 100%);
  --notification-color-warning: hsla(0, 50%, 40%, 100%);

  /* Colors that don't change between light and dark themes */
  --shadow-color: rgba(0, 0, 0, 0.24);
  --info-color: hsla(60, 90%, 90%, 1);
  --warning-color: hsla(0, 40%, 60%, 1);
  --warning-color-filter: invert(51%) sepia(19%) saturate(931%) hue-rotate(314deg) brightness(98%) contrast(92%);
  --warning-color-lighter: hsla(0, 80%, 80%, 1);
  --warning-color-background: hsla(0, 80%, 95%, 1);
  --white-color: hsla(0, 0%, 100%, 1);
  --black-color: hsla(0, 0%, 30%, 1);

  /* Brand colors */
  --brand-color: #6a45ff;
  --brand-color-filter: invert(23%) sepia(75%) saturate(3028%) hue-rotate(245deg) brightness(103%) contrast(102%);
  --brand-color-light: hsla(276, 85%, 70%, 100%);
  --brand-color-lighter: hsla(276, 85%, 90%, 100%);
  --brand-color-contrast: hsl(276, 85%, 99%, 100%);
  --brand-color-lighter-contrast: hsla(276, 85%, 10%, 100%);

  --brand-accent-color-blue-lighter-3: hsl(256, 100%, 95%);
  --brand-accent-color-blue-lighter-2: hsl(256, 100%, 90%);
  --brand-accent-color-blue-lighter-1: #b388ff;
  --brand-accent-color-blue: #7c4dff;
  --brand-accent-color-blue-semi: #7c4dff;
  --brand-accent-color-blue-darker-1: #651fff;
  --brand-accent-color-blue-darker-2: #6200ea;

  /* Alternate theme */
  --hue-blue: 235;
  --saturation-blue: 75%;
  --app-bg-color: hsl(235, 100%, 96%);
  --brand-accent-color-blue-lighter-3: hsl(var(--hue-blue), var(--saturation-blue), 95%);
  --brand-accent-color-blue-lighter-2: hsl(var(--hue-blue), var(--saturation-blue), 90%);
  --brand-accent-color-blue-lighter-1: hsl(var(--hue-blue), var(--saturation-blue), 85%);
  --brand-accent-color-blue: hsl(var(--hue-blue), var(--saturation-blue), 50%);
  --brand-accent-color-blue-semi: hsl(var(--hue-blue), var(--saturation-blue), 50%);
  --brand-accent-color-blue-darker-1: hsl(var(--hue-blue), var(--saturation-blue), 40%);
  --brand-accent-color-blue-darker-2: hsl(var(--hue-blue), var(--saturation-blue), 30%);

  --brand-accent-color-blue-filter: invert(30%) sepia(53%) saturate(5634%) hue-rotate(243deg) brightness(103%)
    contrast(111%);
  --brand-accent-color-blue-lighter-1-filter: invert(67%) sepia(38%) saturate(3178%) hue-rotate(208deg) brightness(103%)
    contrast(101%);
  --brand-accent-color-blue-lighter-2-contrast: hsla(247, 85%, 10%, 100%);

  /* Popup styles */
  --luru-box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  --luru-border: 1px solid var(--brand-accent-color-gray);
  --luru-border-colored: 1px solid var(--brand-accent-color-blue-lighter-1);
  --luru-border-colored-darker: 1px solid var(--brand-accent-color-blue-darker-1);
  --luru-border-radius: 0.5rem;
  --luru-border-radius-xxl: 1.2rem;

  /* Font sizes */
  --font-size-default: 1rem;
  --font-size-decrease-1: 0.8rem;
  --font-size-decrease-2: 0.6rem;
  --font-size-increase-1: 1.2rem;
  --font-size-increase-2: 1.5rem;
  --font-size-increase-3: 2rem;
  --font-size-title: calc(2rem / 1.1);

  /* Standard colors */
  --brand-accent-color-orange: #ffb83d;
  --brand-accent-color-orange-darker-1: #cc8e21;
  --brand-accent-color-orange-darker-2: #99660d;
  --brand-accent-color-orange-lighter-1: #ffd894;
  --brand-accent-color-orange-lighter-2: #fff8eb;
  --brand-accent-color-neon-blue: #4a7dff;
  --brand-accent-color-neon-blue-darker-1: #2d58cc;
  --brand-accent-color-neon-blue-darker-2: #173b99;
  --brand-accent-color-neon-blue-lighter-1: hsl(224, 100%, 81%);
  --brand-accent-color-neon-blue-lighter-2: hsl(224, 100%, 93%);
  --brand-accent-color-neon-blue-lighter-3: hsl(224, 100%, 98%);
  --brand-accent-color-pink: #ff4588;
  --brand-accent-color-pink-darker-1: #cc2863;
  --brand-accent-color-pink-darker-2: hsl(338.51, 77.91%, 33.73%);
  --brand-accent-color-pink-darker-3: hsl(338.51, 77.91%, 23.73%);
  --brand-accent-color-pink-lighter-1: #ff99bd;
  --brand-accent-color-pink-lighter-2: #ffecf3;
  --brand-accent-color-pink-lighter-3: #fff3f3;
  --brand-accent-color-pink-filter: invert(46%) sepia(86%) saturate(3506%) hue-rotate(314deg) brightness(102%)
    contrast(101%);
  --brand-accent-color-beige: #e2d6c5;
  --brand-accent-color-beige-darker-1: #b9ad9c;
  --brand-accent-color-beige-darker-2: #908575;
  --brand-accent-color-beige-lighter-1: #fff5e6;
  --brand-accent-color-beige-lighter-2: #fffefc;
  --brand-accent-color-gray: hsl(0, 0%, 87%);
  --brand-accent-color-gray-darker-1: hsl(0, 0%, 57%);
  --brand-accent-color-gray-darker-2: hsl(0, 0%, 30%);
  --brand-accent-color-gray-darker-3: hsl(0, 0%, 15%);
  --brand-accent-color-gray-lighter-1: hsl(0, 0%, 90%);
  --brand-accent-color-gray-lighter-2: hsl(0, 0%, 95%);
  --brand-accent-color-gray-lighter-3: #f6f6f780;
  --brand-accent-color-gray-darker-2-filter: invert(30%) sepia(9%) saturate(18%) hue-rotate(88deg) brightness(95%)
    contrast(95%);
  --brand-accent-color-pink-darker-2-filter: invert(7%) sepia(88%) saturate(5576%) hue-rotate(323deg) brightness(108%)
    contrast(115%);
  --brand-accent-color-orange-filter: invert(85%) sepia(13%) saturate(3764%) hue-rotate(328deg) brightness(105%)
    contrast(101%);
  --green-color: green;
  --green-color-lighter-1: hsl(120, 50%, 95%);
  --green-color-lighter: hsl(159, 61%, 87%);
  --green-color-filter: invert(44%) sepia(98%) saturate(277%) hue-rotate(108deg) brightness(92%) contrast(102%);
  --brand-accent-color-gray-filter: invert(29%) sepia(1%) saturate(14%) hue-rotate(15deg) brightness(90%) contrast(83%);
  --white-color-filter: invert(100%) sepia(100%) saturate(38%) hue-rotate(321deg) brightness(110%) contrast(110%);
  --border-gray-color: #1d1c1d40;

  /* Banner/Notification colors */
  --banner-info-color: var(--brand-accent-color-blue);
  --banner-error-color: var(--brand-accent-color-pink-darker-2);
  --banner-alert-color: var(--brand-accent-color-orange);
  --banner-reminder-color: var(--brand-color);

  /** Link color */
  --link-color: var(--foreground-color);

  /* Hubspot color filter */
  --hubspot-icon-filter: invert(76%) sepia(9%) saturate(5904%) hue-rotate(319deg) brightness(99%) contrast(104%);

  /* Modal background color */
  --modal-background-color: hsla(240, 0%, 95%, 65%);
  --modal-background-filter: blur(0.5px);
  --modal-background-transition: background-filter 0.2s ease-in-out;
}

/* Dark theme */
/* Based on OS selection */
/* @media (prefers-color-scheme: dark) {
  body {
    --background-color: hsla(0, 0%, 20%, 100%);
    --background-color-secondary: hsla(218, 16%, 19%, 100%);
    --brand-accent-color-gray-lighter-2: hsla(218, 16%, 30%, 100%);
    --background-color-semi-transparent: hsla(218, 16%, 19%, 95%);
    --foreground-color: hsla(0, 0%, 95%, 100%);
    --text-subdued: hsla(0, 0%, 90%, 100%);
    --text-light-label: hsla(247, 100%, 95%, 100%);
    --brand-accent-color-gray: hsla(0, 0%, 40%, 100%);
    --row-highlight-color: hsla(198, 50%, 40%, 100%);
    --link-color: hsla(0, 0%, 100%, 100%);
    --brand-accent-color-blue-lighter-2-contrast: hsla(247, 85%, 10%, 100%);
    --notification-color-normal: hsla(0, 0%, 85%, 100%);
  }
  aside a:not([aria-current="page"]) img {
    filter: invert(1);
  }
} */

div.main.darkTheme,
div.globalSearchMain.darkTheme {
  --background-color: hsla(220, 15%, 16%, 100%);
  --background-color-secondary: hsla(218, 16%, 19%, 100%);
  --brand-accent-color-gray-lighter-2: hsla(218, 16%, 25%, 100%);
  --background-color-semi-transparent: hsla(218, 16%, 19%, 95%);
  --foreground-color: hsla(228, 2%, 91%, 100%);
  --text-subdued: hsla(221, 13%, 47%, 100%);
  --brand-accent-color-gray: hsla(0, 0%, 40%, 100%);
  --row-highlight-color: hsla(198, 50%, 40%, 100%);
  --link-color: hsla(0, 0%, 100%, 100%);
  --brand-accent-color-blue-lighter-2-contrast: hsla(247, 85%, 10%, 100%);
  --notification-color-normal: hsla(0, 0%, 85%, 100%);
}

div.main.darkTheme aside a:not([aria-current='page']) img,
div.globalSearchMain.darkTheme aside a:not([aria-current='page']) img {
  filter: invert(1);
}

body[data-luru-entry-point] a {
  text-decoration: none;
}

button.cta:hover a,
button.cta:hover a:hover,
button.cta a:hover {
  color: white;
}

/* Font sizes */
:root {
  text-rendering: optimizeLegibility;
}

:root {
  box-sizing: border-box;
}

*,
*::after,
*::before {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

div.main,
div.globalSearchMain {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: var(--app-bg-color);
  color: var(--foreground-color);
}

div.globalSearchMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: hsla(0, 10%, 100%, 0.75);
}

input {
  color: var(--foreground-color);
}

div#root {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  font-weight: var(--regular-font-weight);
}

/* Buttons */
button.cta,
button.ctaBlue {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 0.75em;
  padding: 0.75em 1.25em;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.2s ease-in;
}

button.cta > * + *,
button.ctaBlue > * + * {
  margin-left: 0.3em;
}

button.cta {
  background-color: var(--brand-color);
}

button.cta:hover {
  background-color: var(--brand-accent-color-blue-lighter-1);
}

button.ctaBlue {
  background-color: var(--brand-accent-color-blue);
}

button.ctaBlue:hover {
  background-color: var(--brand-accent-color-blue-lighter-1);
}

textarea.luruTextAreaBox,
input.luruTextBox,
input[type='search'] {
  outline: none;
  border: 1px solid var(--brand-accent-color-blue-lighter-3);
  border-radius: var(--luru-border-radius);
  padding: 0.3em 1em;
  background-color: var(--background-color);
  color: var(--foreground-color);
}

textarea.luruTextAreaBox:focus,
input.luruTextBox:focus,
input[type='search']:focus {
  border: 1px solid var(--brand-accent-color-blue);
  /* outline: 3px solid var(--brand-accent-color-gray-lighter-1); */
  outline-offset: unset;
  background-color: var(--background-color);
  transition: box-shadow 0.1s ease-in, background-color 0.2s ease-in;
}

textarea.luruTextAreaBox[data-luru-size='small'],
input.luruTextBox[data-luru-size='small'] {
  padding: 0em;
  padding-left: 0.5em;
  padding-right: 0.2em;
}

input[type='search']::-webkit-search-cancel-button {
  border-bottom: 1px solid var(--background-color);
}

textarea.luruTextAreaBox:invalid,
input.luruTextBox:invalid {
  border: 1px solid var(--warning-color);
  box-shadow: 0 0 0 3px var(--warning-color-lighter);
  color: var(--warning-color);
}

.invisible,
.visHidden {
  visibility: hidden;
}

/* Error pages */
.error-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5em;
  width: 75%;
  height: 100%;
  margin: 0 auto;
  padding-top: 20px;
}

.error-page h1 {
  color: hsla(0, 50%, 50%, 1);
}

.error-page img {
  height: 40%;
}

.error-page a,
.error-page button {
  display: block;
  margin: 1em;
  border: none;
  border-radius: var(--luru-border-radius);
  outline: none;
  padding: 0.3em 1em;
  background-color: var(--brand-color);
  color: white;
  cursor: pointer;
}

.error-page pre {
  height: 25%;
  font-family: 'Courier New', Courier, monospace;
  color: hsla(0, 50%, 60%, 1);
  overflow-y: auto;
}

.rotate-45 {
  rotate: 45deg;
}
.rotate-135 {
  rotate: 135deg;
}
.rotate-315 {
  rotate: 315deg;
}

#hubspot-messages-iframe-container {
  transform: scale(0.8);
}

/* Default table display styles for Luru */
.table {
  display: table;
  width: 100%;
  border-collapse: collapse;
}

.table-header {
  display: table-header-group;
}

.table-header .table-cell {
  padding: 0.6em;
  background-color: var(--brand-blue-color-lightest);
  font-weight: var(--bold-font-weight);
}

.table-row {
  display: table-row;
}

.table-cell {
  display: table-cell;
  padding: 0.3em 0.6em;
}

.table-header .table-cell:first-child {
  border-top-left-radius: 0.5em;
}

.table-header .table-cell:last-child {
  border-top-right-radius: 0.5em;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.error {
  color: var(--warning-color);
}

input[type='date'],
input[type='datetime-local'] {
  position: relative;
}

input[type='date']::-webkit-calendar-picker-indicator,
input[type='datetime-local']::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  color: transparent;
  background: transparent;
}

input[type='date']::after,
input[type='datetime-local']::after {
  position: absolute;
  right: 0.5em;
  content: '\1F5D3';
  z-index: -1;
}

input[readonly] {
  position: relative;
  background-color: var(--disabled-background-color);
}

input[readonly]:focus {
  box-shadow: none;
  border: none;
  outline: none;
  background-color: var(--disabled-background-color);
}

.livelyButton {
  position: relative;
}

.livelyButton::before {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--luru-border-radius);
  background-color: var(--brand-accent-color-gray);
  content: ' ';
  opacity: 0;
  transition-duration: 0.15s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transform: scale(0);
  transition-property: transform, opacity;
  z-index: 1;
}

.livelyButton:hover::before {
  opacity: 0.15;
  transform: scale(1);
}

.livelyButton:active::before {
  opacity: 0.45;
}

.flexCentered {
  display: 'flex';
  flex-direction: row;
  justify-content: 'center';
  align-items: 'center';
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

/** Global Scroll Style */
* {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background-color: var(--background-color);
}

::-webkit-scrollbar-thumb {
  border: 3px solid var(--background-color);
  border-radius: 6px;
  background-color: var(--brand-accent-color-gray);
}

aside::-webkit-scrollbar-thumb {
  background-color: var(--background-color);
}

aside:hover::-webkit-scrollbar-thumb {
  background-color: var(--brand-accent-color-gray);
}

div.luru-app-error {
  /* Luru app error modal should be above anyone else */
  z-index: 10000 !important;
}

div.luru-app-error div[data-luru-role='modal-screen-content'] {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.5em;
}

div.luru-app-error div[data-luru-role='modal-screen-content'] p {
  width: 100%;
}

div.luru-app-error strong {
  font-weight: var(--bold-font-weight);
}

div[data-uf-content='modal'] > div > div:last-child {
  display: none;
}

div.test-ui {
  margin: 3em;
}

img[data-luru-icon-name='hubspot-meeting'] {
  filter: var(--hubspot-icon-filter);
}

img[data-luru-icon-name='sfdc-meeting'] {
  filter: var(--brand-accent-color-blue-filter);
}

/* Usetiful */
body[data-luru-entry-point] a[data-uf-button='button-main'] {
  color: white;
}

.shadow-sm {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.shadow {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.shadow-md {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.shadow-lg {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.grid-hor {
  display: flex;
  flex-direction: row;
  align-items: center;
}

main {
  background-color: var(--background-color);
  border-top-left-radius: 1rem;
  border: 1px solid var(--brand-accent-color-blue-lighter-3);
  border-right: 0;
  border-bottom: 0;
}
