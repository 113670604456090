.parent label {
  display: block;
  font-weight: var(--bold-font-weight);
}

.parent label[data-validation] {
  display: block;
  color: var(--warning-color);
  font-weight: normal;
}

.parent .emailInput {
  width: 100%;
}

.parent .shareLink {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.parent .emailInput {
  margin: 1em 0 0.5em 0;
}

.parent input[type='text'] {
  width: 100%;
  /* width: calc(50% - 0.5em); */
  margin-right: 0.5em;
  border: 1px solid var(--brand-accent-color-gray);
  border-radius: 0.5em;
  outline: none;
  padding: 0.3em 1em;
}

.parent .shareLink input[type='text'] {
  width: calc(85% - 0.5em);
}

.parent .emailInput button {
  width: 30%;
  display: block;
  margin-left: auto;
  border: 1px solid var(--brand-accent-color-blue);
  border-radius: 0.5em;
  outline: none;
  padding: 0.3em 1em;
  background-color: var(--brand-accent-color-blue);
  font-weight: var(--bold-font-weight);
  color: white;
  cursor: pointer;
}

.parent .shareLink button {
  width: 60%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid var(--brand-accent-color-blue);
  border-radius: 0.5em;
  outline: none;
  padding: 0.3em 1em;
  background-color: var(--brand-accent-color-blue);
  font-weight: var(--bold-font-weight);
  color: white;
  cursor: pointer;
}

.parent .emailInput button {
  background-color: var(--background-color);
  color: var(--brand-accent-color-blue);
}

.parent input[type='text']:focus {
  outline: none;
  border: 1px solid var(--brand-accent-color-blue);
  box-shadow: 0 0 0 3px var(--brand-accent-color-blue-lighter-2);
  border-radius: 0.5em;
  transition: box-shadow 0.2s ease-in;
}

.parent > * {
  margin: 1rem 0;
}

.parent ul.emailList {
  height: calc(5 * (1.75em + 2px));
  margin: 0 auto;
  padding: 0;
  list-style-type: none;
  overflow-y: auto;
}

.parent ul li[data-invalid-email='true'] {
  border: 1px solid var(--warning-color);
  border-radius: 0.3em;
}

.parent p {
  width: 100%;
  height: calc(5 * (1.75em + 2px));
  padding-top: calc(2 * (1.75em + 2px));
  vertical-align: middle;
  text-align: center;
}

.parent li {
  position: relative;
  border: 1px solid var(--background-color);
  padding: 0.3em 1em;
  margin-bottom: 0.3em;
}

.parent li:hover {
  border: 1px solid var(--brand-accent-color-gray-lighter-1);
  border-radius: 0.3em;
  background-color: var(--brand-accent-color-gray-lighter-1);
}

.parent li span.actions {
  display: flex;
  align-items: center;
  gap: 0.5em;
  position: absolute;
  right: 1em;
  top: 0.5em;
  margin: 0;
  border: none;
  outline: none;
  padding: 0;
  background-color: transparent;
}

.parent li span.actions .deleteButton {
  visibility: hidden;
  width: 1.15em;
  height: 1.15em;
  color: var(--brand-accent-color-gray);
  content: ' ';
  cursor: pointer;
}

.parent li span.actions .warningButton {
  visibility: hidden;
  content: ' ';
  cursor: pointer;
}

.deleteButton > img {
  filter: var(--text-subdued-filter);
}

.warningButton > img {
  filter: var(--warning-color-filter);
  width: 1.2rem;
}

.parent li:hover span.actions .deleteButton {
  visibility: visible;
}

.parent li[data-invalid-email='true'] span.actions .warningButton {
  visibility: visible;
}

.parent .tip {
  margin: 1em 0;
  font-size: var(--font-size-decrease-1);
}

.parent .tip strong {
  font-weight: var(--bold-font-weight);
}

.actionButtons {
  position: relative;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2em;
  border-bottom: 1px solid var(--brand-accent-color-gray);
  padding-bottom: 2em;
}

.actionButtons::after {
  position: absolute;
  right: 50%;
  bottom: -1.1em;
  padding: 0.5em;
  background-color: white;
  content: 'OR';
}

.okButton,
.cancelButton {
  width: 40%;
  border-radius: 0.5em;
  padding: 0.3em 1em;
  font-weight: var(--bold-font-weight);
  cursor: pointer;
}

.okButton {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  border: 1px solid var(--brand-accent-color-blue);
  background-color: var(--brand-accent-color-blue);
  color: white;
}

.parent div[data-role='luru-user-select-box-popup-menu'] {
  max-height: calc(12 * (1.75em + 2px));
  overflow-y: auto;
  overflow-x: hidden;
}

.disabled {
  color: var(--brand-accent-color-gray);
}

.noteShareModal {
  max-width: 500px;
}
