.modal {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--modal-background-color);
  backdrop-filter: var(--modal-background-filter);
  transition: var(--modal-background-transition);
  z-index: 400;
}

.modalHeader {
  width: 95%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0.6em auto 0 auto;
}

.titleText {
  margin-left: 1em;
  font-weight: var(--bold-font-weight);
  color: var(--foreground-color);
  text-transform: uppercase;
}

.modalClose {
  margin: 0;
  margin-left: auto;
  cursor: pointer;
}

.modalClose img {
  padding: 3px;
  border-radius: 50%;
}

.modalClose img:hover {
  background-color: var(--brand-accent-color-gray-lighter-2);
}

.modalContent {
  width: 95%;
  min-width: 300px;
  max-width: 900px;
  height: auto;
  min-height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin: 0 auto;
  box-shadow: var(--luru-box-shadow);
  border-radius: 0.5em;
  background-color: var(--background-color);
}

.modalTitle {
  margin: 0.3em 1em;
  font-size: 1.5em;
  font-weight: var(--bold-font-weight);
  color: var(--brand-accent-color-blue);
  text-align: center;
}

.modalBody > * {
  margin: 0.3em auto;
  text-align: center;
}

.modalBody > p {
  margin: 0.3em 20% 1em 20%;
  font-size: 1em;
}

.modalBody > button {
  margin: 0 auto;
}

.modalFooter {
  display: flex;
  flex-direction: column;
  gap: 1em;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 1em;
}

.modalFooter button {
  border: none;
  padding: 0.3em 1.25em;
  background-color: var(--background-color);
  color: var(--brand-accent-color-blue);
  outline: none;
  font-size: 0.8em;
}

/* From: https://developers.google.com/identity/sign-in/web/build-button */
.gcalButton {
  /* width: 190px; */
  margin-bottom: 0.5em !important;
  height: 35px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid var(--brand-accent-color-gray-darker-1);
  border-radius: 2px;
  outline: none;
  box-shadow: var(--brand-accent-color-gray-darker-1) 1px 1px 1px;
  padding-right: 18px;
  background: white;
  white-space: nowrap;
}

.gcalButton:hover,
.gcalButton:active {
  background-color: var(--brand-accent-color-gray-lighter-2);
  border: none;
  cursor: pointer;
}

.gcalButton > div {
  display: inline-block;
}

.gcalButton .icon {
  width: 18px;
  height: 18px;
  margin-left: 8px;
  margin-right: 24px;
  background-image: url('../../images/google_logo.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  vertical-align: middle;
}

.gcalButton .buttonText {
  color: var(--brand-accent-color-gray-darker-2);
  font-size: 14px;
  font-weight: 500;
  font-family: 'Roboto', Arial, sans-serif;
}

.hidden {
  display: none;
}

@media (min-width: 600px) {
  .modalHeader {
    margin: 1em auto;
  }

  .modalTitle {
    margin: 0 auto;
  }

  .modalContent {
    width: auto;
  }

  .modalBody > * {
    margin: 0 auto;
  }

  .modalBody > p {
    margin: 0.1em 20% 1em 20%;
  }

  .modalFooter {
    margin-bottom: 3em;
  }
}

div[data-luru-role='zoom-message'] {
  display: flex;
  flex-direction: column;
  margin: 2em 0;
}

div[data-luru-role='zoom-message'] strong {
  color: black;
  font-weight: var(--bold-font-weight);
}
