/* Spinning animation definition */
.loading {
  position: relative;
  margin-left: 1em;
  --loading-color: var(--brand-color);
}
.white {
  --loading-color: white;
}
.loading::after {
  position: absolute;
  content: '';
  width: 1em;
  height: 1em;
  top: -0.5em;
  right: -0.5em;
  border-style: dotted;
  border-width: 1px 2px 0 0;
  border-color: var(--loading-color);
  border-radius: 50%;
  animation: spin 0.75s ease-in-out infinite;
}
.loading.middle::after {
  bottom: 30%;
}
.loading.center::after {
  right: 50%;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* End of spinning animation definition */
