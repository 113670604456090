.cell {
  position: relative;
}

.edit {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0;
  background-color: transparent;
}

.edit * {
  background-color: transparent;
}

.info,
.omniboxLayer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: right;
}

.omniboxLayer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  gap: 0.3em;
  align-items: center;
  padding: 0;
  text-align: left;
}

.omniboxActions {
  display: none; /* Omnibox action buttons hidden by default */
}

.omniboxLayer:hover .omniboxActions {
  display: flex;
  align-items: center;
  justify-content: center;
  /* gap: 0.5em !important; */
  font-size: 0.5em;
  margin-left: 0.5em;
}

.omniboxActions > img {
  height: 2em !important;
  cursor: pointer;
  vertical-align: middle;
}

.omniboxActions > span > img {
  width: 1.1em;
  height: 1.1em;
  vertical-align: middle;
}

.omniboxPositioning {
  left: 2.5em;
}

.omniboxLayer {
  right: unset;
  width: 2.5em;
}

.select {
  display: none; /* Temporarily hide select layer until complete implementation */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  outline: 0;
  background-color: var(--modal-background-color);
  backdrop-filter: var(--modal-background-filter);
  transition: var(--modal-background-transition);
}

.select:focus {
  border: 2px solid var(--brand-accent-color-blue);
}

.visible {
  visibility: visible;
}

.idle {
  background-color: var(--background-color);
  transition: 1s linear background-color;
}

.success {
  background-color: lightgreen;
}

.error {
  background-color: lightpink;
}

.loading {
  /* background-color: lightyellow; */
  background: linear-gradient(
    90deg,
    var(--brand-accent-color-orange-lighter-1),
    var(--brand-accent-color-orange-lighter-2)
  );
  background-size: 10%;
  background-position: -100% -100%;
  background-repeat: no-repeat;
  animation: loading 1s infinite;
}

@keyframes loading {
  0% {
    background-size: 10%;
  }
  100% {
    background-size: 100%;
  }
}

.alert {
  position: absolute;
  top: 1em;
  right: 0.5em;
  width: 2em;
  height: 1em;
  padding: 0;
}

.percent .alert,
.int .alert,
.integer_noformat .alert,
.currency .alert,
.double .alert {
  left: 0.5em;
}

.alert img {
  height: 1em;
}

.alert img[data-role='revert-value'] {
  cursor: pointer;
}

/* Luru select box */
.edit > div:first-child,
.edit > div:first-child > button {
  width: 100%;
  background-color: transparent;
}

/* Luru date picker */
/* .edit input[data-role='luru-date-picker'] {
  border: none;
}

.edit input[data-role='luru-date-picker']:hover {
  border: none;
  background-color: transparent;
} */

.referenceInput [data-role='popup-menu'],
.edit div[data-role='popup-menu'] {
  background-color: var(--background-color);
  z-index: 500;
}

.pipelineViewSelectBox {
  padding: 0.44em 1em;
  height: 40px;
  border-radius: 0;
}

.pipelineViewSelectBox:hover {
  background-color: var(--brand-accent-color-blue-lighter-2);
  color: var(--brand-accent-color-blue-lighter-2-contrast);
}

.referenceInput {
  width: 100%;
  max-height: 40px;
  padding: 0.44em;
}

.referenceInput input[type='search'] {
  border: none;
  text-overflow: ellipsis;
  background-color: transparent;
}

span.selectBoxLevel1 {
  height: 1.2em;
  display: block;
  color: var(--text-subdued);
  font-size: 0.7em;
  font-weight: var(--bold-font-weight);
}

span.selectBoxLevel2 {
  display: block;
}

div.selectBoxLabel {
  display: flex;
  align-items: center;
}

span.selectBoxLabelLevel1 {
  min-width: 5em;
  color: var(--text-subdued);
  font-size: 0.7em;
  font-weight: var(--bold-font-weight);
  overflow: hidden;
  text-overflow: ellipsis;
}

span.selectBoxLabelLevel2 {
  margin-left: 0.3em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.omniboxPositioning div[data-luru-role='luru-popup-menu'] input[type] {
  width: 100%;
}

div[data-luru-edit-status='FAILURE'] span[data-luru-role='date-picker-icon'] {
  display: none;
}

.edit .textarea {
  height: calc(4 * ((1.15 + 0.3 * 2) * 1em + 2px));
  border: 1px solid var(--brand-accent-color-gray);
  border-radius: 0.3em;
  padding: 0.3em 1em;
  resize: none;
}

.edit .textarea:focus {
  border: 1px solid var(--brand-accent-color-blue);
  box-shadow: 0 0 0 3px var(--brand-accent-color-blue-lighter-2);
  transition: box-shadow 0.2s ease-in;
  transition: box-shadow 0.2s ease-in;
}

.edit [data-role='popup-menu'] {
  width: 100%;
}

.edit [data-role='popup-menu'] input[type='text'] {
  width: calc(100% - 1.5em);
}

.edit [data-role='popup-menu'] li[data-item-chooser-key] {
  padding: 0.3em 1em;
}

.edit [data-luru-role='multi-select-popup-list-item'] {
  padding: 0;
  padding-left: 2.25em;
}
