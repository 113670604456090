.parent {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.parent aside {
  width: 100%;
  background-color: transparent;
  overflow: auto;
}
