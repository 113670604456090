[data-luru-role='hyperlink-helper'] {
  position: fixed;
  border: var(--luru-border);
  border-radius: var(--luru-border-radius);
  padding: 0.3em 1em;
  background-color: var(--background-color-secondary);
  box-shadow: var(--luru-box-shadow);
  font-size: 0.8em;
  text-align: center;
  white-space: nowrap;
  z-index: 100;
}

[data-luru-role='hyperlink-helper'] a {
  margin-right: 0.5em;
  color: var(--brand-accent-color-blue);
  cursor: pointer;
}

.visible-false {
  visibility: hidden;
  opacity: 0;
  transform: scale(0.95);
  transition: visibility 0s;
  transition: opacity 0s;
  transition: transform 0.1s ease-in-out;
}

.visible-true {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
  transition: visibility 0s;
  transition: opacity 0s;
  transition: transform 0.1s ease-in-out;
}

[data-luru-role='hyperlink-helper'] button:focus,
[data-luru-role='hyperlink-helper'] button {
  margin-left: 0.5em;
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--brand-accent-color-blue);
  cursor: pointer;
}
