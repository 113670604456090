header.parent {
  position: relative; /* For making shadow of header appear above next sibling */
  user-select: none;
}

header.parent nav {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
}

header.parent nav a:first-child img {
  width: 2.5em;
  height: 2.5em;
}

.search {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  z-index: 1;
}

header.parent nav a:first-child img {
  margin-top: 10px;
  padding: 0;
  transform: scale(0.8);
}

header.parent nav > a + a {
  margin-left: 0.5em;
}

header.parent nav a {
  display: inline-block;
  border-radius: 0.3em;
  padding: 0 1.25em;
  font-weight: var(--bold-font-weight);
  transition: all 0.1s ease-in;
}

header.parent nav a.normal {
  background-color: var(--background-color);
  color: var(--foreground-color);
}

header.parent nav a.normal:hover {
  background-color: var(--brand-color-lighter);
  color: var(--brand-color);
}

header.parent nav a.normal.selected,
header.parent nav a.normal.selected:hover {
  background-color: var(--brand-color);
  color: var(--brand-color-contrast);
}

/*
@media (prefers-color-scheme: dark) {
  header nav a.normal.selected {
    background-color:
  }
  header nav a.normal:hover {
    background-color: ;
    color: var(--foreground-color);
  }
}
*/

header.parent nav .right {
  margin-left: auto;
}

.user {
  padding: 0;
  z-index: 1;
  margin: 0.1em;
  margin-right: 0.4em;
  height: 38px;
}

.pushRight {
  margin-left: auto;
}

button.newNoteButton {
  margin-right: 1em;
  border-radius: 2em;
  padding: 0;
}

button.newNoteButton img {
  height: 3em;
}

header.parent nav button.newNoteButton img {
  height: 2.5em;
}

.notification {
  display: inline-block;
  position: relative;
  visibility: hidden;
  opacity: 0;
  border-radius: 0.3em;
  padding: 0.3em 1em;
  background-color: var(--notification-color-normal);
  box-shadow: var(--luru-box-shadow);
  color: var(--background-color);
  font-size: var(--font-size-decrease-1);
  font-weight: var(--bold-font-weight);
}

.visible {
  visibility: visible;
}

.highlight {
  opacity: 1;
}

.completelyHidden {
  display: none;
}

.flash {
  transition: all 0.4s ease-out;
}

.loading {
  display: inline-block;
  width: 1em;
  min-width: 1em;
}

.warning {
  background-color: var(--notification-color-normal);
}

.newRecordIcon {
  filter: invert(1);
}

button.newRecord {
  display: flex;
  align-items: center;
  margin-right: 1em;
  border: 0;
  border-radius: 1.5rem;
  padding: 0.5rem 1rem;
  background-color: var(--background-color);
  color: var(--brand-accent-color-gray-darker-2);
  cursor: pointer;
  transition: all 0.1s ease-in;
}

button.newRecordAlt {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5em;
  width: 2.5em;
  outline: none;
  border: 1px solid var(--brand-accent-color-blue-lighter-3);
  border-radius: 50%;
  background-color: var(--background-color);
  cursor: pointer;
  margin-right: 1rem;
  transition: all 0.1s ease-in;
}

button.newRecordAlt img {
  height: 1.5em;
  width: 1.5em;
  filter: var(--brand-accent-color-blue-lighter-1-filter);
}

button.newRecordAlt:hover {
  box-shadow: var(--luru-box-shadow);
}

button.newRecordAlt:hover img {
  filter: var(--brand-accent-color-blue-filter);
}

button.newRecord:hover img {
  filter: invert(100%) sepia(13%) saturate(7299%) hue-rotate(175deg) brightness(87%) contrast(79%);
}

button.newRecord:hover {
  color: var(--brand-accent-color-gray-darker-1);
}

[data-notification-type='success'] {
  background-color: var(--brand-accent-color-blue-lighter-2);
  color: var(--brand-accent-color-blue-darker-2);
}

[data-notification-type='loading'] {
  background-color: var(--brand-accent-color-gray-lighter-2);
  color: var(--brand-accent-color-gray-darker-2);
}

[data-notification-type='warning'] {
  background-color: var(--brand-accent-color-pink-lighter-2);
  color: var(--brand-accent-color-pink-darker-2);
}

.bannerNotificationContainer {
  border-radius: 0px !important;
}

.menu {
  position: absolute;
  top: 5px;
  left: 10px;
  padding: 4px 10px;
  border-radius: 10px;
  cursor: pointer;
  z-index: 1001;
}

.menu:hover {
  background-color: var(--brand-accent-color-gray-lighter-1);
}
