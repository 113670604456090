.parent {
  background-color: var(--modal-background-color);
  backdrop-filter: var(--modal-background-filter);
  transition: var(--modal-background-transition);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4000;
  user-select: none;
  font-size: 1rem;
}

.minimized {
  visibility: hidden;
  backdrop-filter: blur(0px);
  transition: backdrop-filter 0.1s ease-out;
}

.dialog {
  --dialog-width: calc(min(95vw, 40em));
  --dialog-height: 11em;
  position: fixed;
  top: calc((100% - var(--dialog-height) - 20em) / 2);
  /* top: 10px; */
  left: calc((100% - var(--dialog-width)) / 2);
  height: auto;
  width: var(--dialog-width);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* gap: 1em; */
  border: var(--luru-border);
  box-shadow: var(--luru-box-shadow);
  border-radius: 0.4em;
  padding: 1em;
  background-color: var(--background-color);
  color: var(--foreground-color);
}

.dialog label {
  display: block;
  margin: 1em 0 0.5em;
  color: var(--brand-accent-color-blue);
  font-size: 0.9em;
  font-weight: var(--bold-font-weight);
}

.dialog textarea {
  width: 100%;
  height: var(--dialog-height);
  margin: 0.5em 0;
  border: 1px solid var(--brand-accent-color-gray);
  outline: none;
  box-shadow: 0 0 0 3px transparent;
  border-radius: 0.3em;
  padding: 0.3em 1em;
  resize: vertical;
}

.dialog textarea:focus {
  border: 1px solid var(--brand-accent-color-blue);
  box-shadow: 0 0 0 3px var(--brand-accent-color-blue-lighter-2);
  border-radius: 0.5em;
  transition: box-shadow 0.2s ease-in;
}

.dialog > * {
  margin-top: 0;
}

.dialog > *:first-child {
  margin-top: 0;
}

.title {
  font-weight: var(--bold-font-weight);
  font-size: 1.2em;
  text-align: left;
}
.description {
  font-size: 0.9em;
  text-align: left;
  margin-bottom: 1em;
}

.content {
  height: 300px;
  max-height: 50vh;
  padding: 0 1em;
  text-align: left;
  overflow: auto;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  text-align: center;
}

.buttons button {
  font-size: 0.9em;
  font-weight: var(--bold-font-weight);
  overflow: hidden;
  text-overflow: ellipsis;
}

.buttons button:last-child {
  margin-left: 1em;
  background-color: var(--brand-accent-color-blue);
  color: white;
}

.parent input[type='text'] {
  width: 100%;
  outline: none;
  margin-bottom: 0.5em;
  border: 1px solid var(--brand-accent-color-gray);
  box-shadow: 0 0 0 3px transparent;
  border-radius: var(--luru-border-radius);
  padding: 0.5em;
  background-color: var(--background-color);
  color: var(--foreground-color);
}

.parent input[type='text']:focus {
  outline: none;
  border: 1px solid var(--brand-accent-color-blue);
  box-shadow: 0 0 0 3px var(--brand-accent-color-blue-lighter-2);
  border-radius: 0.5em;
  transition: box-shadow 0.2s ease-in;
}

.tip {
  margin: 0 0.5em;
  color: var(--text-subdued);
  font-size: 0.8em;
}

.crmObjectSelectRow {
  display: flex;
  align-items: center;
}

.crmObjectSelectRow button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.8rem;
  height: 1.8rem;
  border: 0;
  border-radius: 0.3em;
  padding: 0.3em;
  outline: none;
  background-color: transparent;
  color: var(--brand-accent-color-blue-lighter-1);
  cursor: pointer;
}

.summaryTextContainer {
  min-width: 100px;
  display: flex;
  align-items: center;
  gap: 0.2em;
  flex-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  cursor: pointer;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  text-align: center;
  gap: 0.5em;
}

div.chipsContainer {
  width: 0;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  border: 1px solid var(--brand-accent-color-blue-lighter-2);
  border-radius: 0.3em;
  padding: 0.3em 1em;
  background-color: var(--brand-accent-color-blue-lighter-2);
  font-size: 0.9em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chipsContainer:hover {
  background-color: var(--brand-accent-color-blue-lighter-1);
  color: white;
}

.duplicate {
  border: 1px solid red;
}

div.warningMessage {
  color: var(--brand-accent-color-pink-darker-1);
  font-weight: var(--bold-font-weight);
}
