.parent {
  position: fixed;
  z-index: 300;
  display: none;
  flex-direction: column;
  visibility: hidden;
  top: 10%;
  left: 2%;
  width: 96%;
  min-width: 310px;
  height: 80%;
  padding: 0.5em 1em;
  border: 1px solid var(--brand-accent-color-gray);
  box-shadow: var(--luru-box-shadow);
  border-radius: 0.3em;
  background-color: var(--background-color);
  opacity: 0;
  font-size: calc(0.9em * 0.9);
  user-select: none;
}

div.dividerContainer {
  display: flex;
  width: 100%;
  align-items: center;
  margin: 0.5em 0em 0.5em 0em;
}

div.dividerContainer .divider {
  border: 0.5px solid var(--brand-accent-color-blue-lighter-2);
  flex-grow: 1;
}

div.dividerContainer .text {
  font-weight: var(--bold-font-weight);
  color: var(--brand-accent-color-blue);
  margin: 0em 0.5em 0em 0.5em;
}

div.insertCollection,
button.insertCollection {
  /* width: 5em; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0em 0em 0em 0.2em;
  /* border: 1px solid var(--brand-accent-color-gray); */
  /* border-radius: 0.4em; */
  /* outline: 0; */
  /* padding: 0.3em 1em; */
  /* background-color: transparent !important; */
  cursor: pointer;
  /* vertical-align: middle; */
}
div.filterBoxContainer {
  display: flex;
  width: 100%;
  align-items: center;
}

.record {
  left: 2%;
  width: 96%;
}

.schema {
  left: 38%;
  width: 24%;
}

@media (min-width: 600px) {
  .parent {
    left: 20%;
    width: 60%;
  }
  .schema {
    left: 38%;
    width: 24%;
  }
}

@media (min-width: 900px) {
  .parent {
    left: 20%;
    width: 60%;
  }
  .schema {
    left: 38%;
    width: 24%;
  }
}

.visible {
  visibility: visible;
  opacity: 1;
  display: flex;
}

.filterBox {
  position: relative;
  padding: 0.3em 1em;
  width: 100%;
}

.results {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-start;
  margin: 0.5em 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.results ul {
  display: block;
  width: 100%;
  list-style-type: none;
  align-self: flex-start;
}

li[data-field-result='true'] {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5em 1em;
  cursor: pointer;
}

li[data-field-result='true'] > * {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

li[data-field-result='true'] img {
  height: 2em;
  vertical-align: middle;
}

.noresults,
.error {
  display: block;
  width: 100%;
  align-self: center;
  text-align: center;
}

.loaded {
  justify-self: center;
}

.visible {
  visibility: visible;
  opacity: 1;
  display: flex;
}

.parent footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: auto;
  border-top: 1px solid var(--brand-accent-color-gray);
  padding-top: 0.3em;
  font-size: 0.8em;
}

.parent footer * {
  font-size: inherit;
}

.parent footer > * {
  font-size: 0.9em;
}

.parent footer a {
  color: var(--brand-accent-color-blue);
  text-decoration: underline;
}

@media (min-width: 600px) {
  .parent footer > * {
    font-size: 1em;
  }
}

.selected img {
  filter: grayscale() invert(0) contrast(1000%) saturate(100%);
}

.field {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.schemaFieldName,
.fieldName {
  width: 40%;
  color: var(--text-subdued);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.85em;
}

.schemaFieldName {
  width: 90%;
}

@media (min-width: 600px) {
  .schemaFieldName,
  .fieldName {
    font-size: 1em;
  }
}

.nullValue {
  color: var(--text-subdued);
  font-style: italic;
  font-size: 0.85em;
}

.fieldValue {
  width: 55%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.85em;
}

@media (min-width: 600px) {
  .fieldValue,
  .nullValue {
    font-size: 1em;
  }
}

li[data-field-result='true']:hover .schemaFieldName,
li[data-field-result='true']:hover .fieldName,
li[data-field-result='true']:hover .nullValue,
li[data-field-result='true']:hover {
  /* background-color: var(--brand-accent-color-blue-lighter-2);
  background-color: var(--brand-accent-color-gray); */
  color: var(--brand-accent-color-blue);
  font-weight: var(--bold-font-weight);
  cursor: pointer;
}

li[data-field-result='true'].selected:hover .nullValue,
li[data-field-result='true'].selected:hover .schemaFieldName,
li[data-field-result='true'].selected:hover .fieldName,
li[data-field-result='true'].selected:hover,
.selected .fieldName,
.selected .schemaFieldName,
.selected .nullValue,
.selected {
  background-color: var(--brand-accent-color-blue-lighter-2);
}

.selected img {
  filter: grayscale() invert(0) contrast(1000%) saturate(100%);
}

li[data-field-result='true']:hover {
  padding: 0.5em 1em;
}

.fieldsetChooserContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5em;
  position: relative;
  margin-bottom: 0.5em;
  border-bottom: 1px solid var(--brand-accent-color-gray);
  padding-bottom: 1em;
}

.fieldsetChooserContainer button {
  font-size: 0.8em;
}

.fieldsetChooserContainer button span {
  font-size: inherit;
}

@media (min-width: 600px) {
  .fieldsetChooserContainer {
    flex-direction: row;
    margin-bottom: 1em;
    padding: 1em 0;
  }

  .fieldsetChooserContainer button {
    font-size: 1em;
  }

  .fieldsetChooserContainer button span {
    font-size: inherit;
  }
}

.fieldsetChooser {
  width: 10em !important;
}

.fieldsetChooserContainer::after {
  position: absolute;
  content: 'OR';
  bottom: -0.65em;
  left: 48%;
  padding: 0 1em;
  background-color: var(--background-color);
  color: var(--brand-accent-color-blue);
  font-weight: var(--bold-font-weight);
}
