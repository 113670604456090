.highlight.red {
  background-color: var(--brand-accent-color-pink);
}

.highlight.orange {
  background-color: var(--brand-accent-color-orange-lighter-2);
}

.highlight.yellow {
  background-color: var(--brand-accent-color-beige-lighter-2);
}

.highlight.green {
  background-color: var(--green-color-lighter-1);
}

.highlight.blue {
  background-color: var(--brand-accent-color-neon-blue-lighter-2);
}

.highlight.purple {
  background-color: var(--brand-accent-color-blue-lighter-2);
}

.highlight.pink {
  background-color: var(--brand-accent-color-pink-lighter-2);
}
