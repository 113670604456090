.parent {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.menu {
  position: absolute;
  visibility: hidden;
  z-index: 600;
  box-shadow: var(--luru-box-shadow);
  border-radius: 0.3em;
  background-color: var(--background-color);
  opacity: 0;
  transition: opacity 0.15s ease-in-out;
  margin-top: -1000px;
}

.menu.leftAligned {
  left: 0;
}

.menu.rightAligned {
  right: 0;
}

.menuVisible {
  visibility: visible;
  opacity: 1;
  margin-top: 0px;
}

.menu ul {
  background-color: var(--background-color);
  list-style-type: none;
  text-align: left;
}

.menu li {
  display: block;
  width: calc(min(100%, 15em));
  white-space: nowrap;
  padding: 0.6em 1em;
  background-color: var(--background-color);
  color: var(--foreground-color);
  user-select: none;
}

.menu li > * {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.menu li:hover {
  background-color: var(--brand-accent-color-blue-lighter-1);
  color: #fff;
}

/* .menu li:first-child {
  padding-top: 1em;
} */

.separated {
  border-top: 1px solid #d9d9d9;
}

div[data-role='popup-menu'] {
  min-width: 10em;
}
