.parent {
  border-radius: var(--luru-border-radius);
  padding: 1em;
  background-color: var(--background-color);
  user-select: none;
  box-shadow: var(--brand-accent-color-gray) 0px 1px 2px 0px;
}

.header {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.header .icon {
  height: 1.8em;
  user-select: none;
  margin-right: 0.2em;
}

.openIcon {
  transform: rotate(180deg);
  transition: transform 0.2s ease-in-out;
}

.closeIcon {
  transform: rotate(90deg);
  transition: transform 0.2s ease-in-out;
}

.header .title {
  font-size: var(--font-size-increase-1);
  text-align: left;
  font-weight: var(--bold-font-weight);
  background: -webkit-linear-gradient(360deg, var(--brand-accent-color-blue), var(--brand-accent-color-blue-darker-1));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  max-width: 40%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.content {
  margin-top: 1em;
}

.showContent {
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  visibility: visible;
}

.hideContent {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  visibility: hidden;
}

.showParentConent {
  max-height: 2000px;
  /* overflow: hidden; */
  transition: max-height 0.2s ease-in-out;
}

.hidenParentContent {
  max-height: 60px;
  /* min-height: 65px; */
  transition: max-height 0.2s ease-in-out;
}

div.extraCompAtHeaderEnd {
  margin-top: -2.8em;
  width: 20vw;
  height: 60px;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
  justify-content: center;
  margin-left: 65vw;
}

div.extraCompAtHeaderEnd button {
  display: flex;
  gap: 1em;
}

div.extraCompAtHeaderEnd button > span:first-child {
  font-weight: bold;
}

@media not all and (min-width: 640px) {
  .hideContent {
    height: 0px;
  }
}
