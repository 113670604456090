.parent {
  margin-left: 1em;
  width: 98%;
  padding: 1em;
}

.parent h2 {
  margin-bottom: 1em;
  color: var(--brand-color);
  font-size: var(--font-size-increase-1);
  font-weight: var(--bold-font-weight);
}
.parent h2 {
  margin: 0.5em 0;
}

.parent > .body {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 73vh;
  overflow-y: auto;
  overflow-x: hidden;
  width: 102%;
}

.labelConnected {
  margin-left: 1em;
  font-weight: var(--bold-font-weight);
  color: var(--green-color);
  border: 0.5px solid var(--green-color);
  padding: 2px 6px 2px 6px;
  border-radius: var(--luru-border-radius);
}

.labelNotConnected {
  margin-left: 1em;
  font-weight: var(--bold-font-weight);
  color: var(--text-subdued);
  border: 0.5px solid var(--text-subdued);
  padding: 2px 6px 2px 6px;
  border-radius: var(--luru-border-radius);
}

.headerHeight {
  padding: -10%;
}

.parent .body h3 {
  text-align: left;
  font-weight: var(--bold-font-weight);
  background: -webkit-linear-gradient(360deg, var(--brand-accent-color-blue), var(--brand-accent-color-blue-darker-1));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.copyIcon {
  cursor: pointer;
  position: relative;
}

.copiedText {
  cursor: pointer;
  padding: 1px;
  position: absolute;
  background-color: black;
  color: white;
  border-radius: 3px;
}

.integrationContainer {
  display: flex;
  gap: 2em;
  margin-top: 1em;
  margin-bottom: 2em;
  flex-wrap: wrap;
}

/* Card:Start */
.integrationCard {
  border: 1px solid var(--brand-accent-color-blue-lighter-2);
  border-radius: 0.5em;
  padding: 1em;
  width: 25em;
  position: relative;
}

/* Disable card */
.integrationCard[data-luru-disable='true']::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--brand-accent-color-gray);
  opacity: 0.7;
}

.integrationCard .title {
  position: relative;
  color: var(--brand-color);
  font-weight: var(--bold-font-weight);
  user-select: none;
}

.integrationCard .title .settings {
  display: none;
  position: absolute;
  top: 0;
  right: 0.3em;
  color: var(--brand-accent-color-blue-lighter-1);
  cursor: pointer;
}

.settings > img {
  filter: var(--brand-accent-color-blue-lighter-1-filter);
}

.integrationCard[data-installation-status='installed'] .settings {
  display: block;
}

.integrationCard .body {
  margin: 1em 0em 1em 0em;
}

.integrationCard .actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.integrationCard .logo {
  width: 2.3em;
}

.integrationCard .logo[data-luru-cal='O365CAL'] {
  width: 1.9em;
}

.integrationCard .installButton {
  font-weight: var(--bold-font-weight);
  cursor: pointer !important;
}

.integrationCard .installedButton {
  border: 1px solid var(--green-color);
  background-color: var(--green-color);
  color: white !important;
  cursor: default;
}

.integrationCard .disabledButton {
  border: 1px solid var(--brand-accent-color-gray) !important;
  background-color: var(--brand-accent-color-gray) !important;
  color: white !important;
  cursor: default;
}

.integrationCard .connectButton {
  border: 1px solid var(--green-color);
  background-color: var(--green-color);
  color: white !important;
  cursor: pointer !important;
}

.integrationCard .disconnectButton {
  border: 1px solid var(--warning-color);
  background-color: var(--warning-color);
  color: white !important;
  cursor: pointer !important;
}

/* Card:End */

.sorLogo {
  width: 2em;
  height: 2em;
}

div.googleExtCard {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

div.integrationCards {
  display: flex;
  gap: 1rem;
  flex-direction: row;
  flex-wrap: wrap;
}

div.highlightSection {
  border: 2px outset var(--brand-accent-color-blue-lighter-1);
  border-radius: var(--border-radius);
}

div.highlightCard {
  border: 1px dashed var(--brand-accent-color-blue-lighter-1);
  border-radius: 0.5rem;
}

div.highlightSection div {
  box-shadow: none;
}

div.emailSyncLabel a {
  color: var(--brand-accent-color-orange-darker-1);
  font-weight: bold;
}
