.parent {
  position: relative;
  display: inline-block;
  cursor: default;
  width: 100%;
  z-index: 100;
}

.icon {
  position: absolute;
  top: calc(50% - 0.6em);
  right: -1em;
  font-size: var(--font-size-increase-1);
  cursor: pointer;
}

.icon img {
  width: 1em;
}

.parentInput {
  display: flex;
  width: 120%;
  align-items: center;
  /* gap: 0.5em; */
  outline: none;
  border: 1px solid var(--brand-accent-color-gray);
  box-shadow: 0 0 0 3px transparent;
  border-radius: 0.3em;
  padding: 0.3em 0.6em;
  background-color: transparent;
  color: var(--foreground-color);
  min-width: fit-content;
}
.parentInput:focus-within {
  outline: none;
  border: 1px solid var(--brand-accent-color-blue);
  border-radius: 0.5em;
  box-shadow: var(--luru-box-shadow);
  background-color: var(--background-color);
  transition: box-shadow 0.1s ease-in, background-color 0.2s ease-in;
}

.parentInput:invalid {
  border: 1px solid var(--warning-color);
  box-shadow: 0 0 0 3px var(--warning-color-lighter);
  color: var(--warning-color);
}

.parentInput input[type='number'] {
  outline: none;
  border: none;
  width: 1.9em;
}

/* Chrome, Safari, Edge, Opera */
.parentInput input[type='number']::-webkit-outer-spin-button,
.parentInput input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.parentInput input[type='number'] {
  -moz-appearance: textfield;
  appearance: none;
}

.parentInput select {
  outline: none;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  appearance: none;
}

/* Clock related css */

.clockContainer {
  min-width: 250px;
}
.clock {
  width: 100%;
  aspect-ratio: 1 / 1;
  background-color: var(--brand-accent-color-gray-lighter-2);
  border-radius: 100%;
  position: relative;
}

div.center {
  width: 5%;
  right: 47.5%;
  height: 13px;
  background: var(--brand-accent-color-blue);
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  /* transform-origin: 100%; */
  /* transform: rotate(90deg); */
}

div.period {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

div.period div.periodItem {
  background-color: var(--brand-accent-color-gray-lighter-2);
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 5px; */
  border-radius: 20px;
  cursor: pointer;
  height: 40px;
  width: 40px;
  font-size: 0.8em;
}
div.period div.periodItem[data-luru-selected='true'] {
  background-color: var(--brand-accent-color-blue);
  color: whitesmoke;
}

div.dialType {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

div.dialType img {
  filter: var(--brand-accent-color-gray-filter);
  width: 1.2em;
}
div.dialType img.dialTypeItem[data-luru-timer='hour'] {
  transform: rotate(270deg);
}
div.dialType img.dialTypeItem[data-luru-timer='minute'] {
  transform: rotate(270deg);
}
div.dialType img.dialTypeItem[data-luru-selected='true'] {
  /* filter: invert(0.1); */
  filter: sepia(100%) hue-rotate(190deg) saturate(900%);
}

/* .center {
  width: 50%;
  right: 50%;
  height: 2px;
  background: black;
  position: absolute;
  top: 50%;
  border-radius: 40%;
  transform-origin: 100%;
  transform: rotate(90deg);
  transition-timing-function: cubic-bezier(0.1, 2.7, 0.58, 1);
} */

.hand {
  width: 50%;
  right: 50%;
  height: 6px;
  background: var(--brand-accent-color-blue);
  position: absolute;
  top: 50%;
  border-radius: 5%;
  transform-origin: 100%;
  transform: rotate(90deg);
  transform: translate(-50%, -50%);
  transition-timing-function: cubic-bezier(0.1, 2.7, 0.58, 1);
}

.hourHand {
  width: 43%;
  height: 2px;
}
.minHand {
  width: 43%;
  height: 2px;
}

.clockHandThumb {
  width: 35px;
  height: 35px;
  background-color: var(--brand-accent-color-blue);
  position: absolute;
  left: 2%;
  transform: translate(-50%, -47%);
  border-radius: 50%;
}

ul.hours li[data-luru-selected='true'] {
  color: white;
  font-weight: bolder;
}

ul.hours li[data-luru-hour='1'] {
  --nth-child: 1;
}
ul.hours li[data-luru-hour='2'] {
  --nth-child: 2;
}
ul.hours li[data-luru-hour='3'] {
  --nth-child: 3;
}
ul.hours li[data-luru-hour='4'] {
  --nth-child: 4;
}
ul.hours li[data-luru-hour='5'] {
  --nth-child: 5;
}
ul.hours li[data-luru-hour='6'] {
  --nth-child: 6;
}
ul.hours li[data-luru-hour='7'] {
  --nth-child: 7;
}
ul.hours li[data-luru-hour='8'] {
  --nth-child: 8;
}
ul.hours li[data-luru-hour='9'] {
  --nth-child: 9;
}
ul.hours li[data-luru-hour='10'] {
  --nth-child: 10;
}
ul.hours li[data-luru-hour='11'] {
  --nth-child: 11;
}
ul.hours li[data-luru-hour='12'] {
  --nth-child: 12;
}

.hours .hour {
  left: 45%;
  top: 45%;
  position: absolute;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10%;
  height: 10%;
  /* background-color: black; */
  border-radius: 100%;
  padding: 5px;
  transform: rotateZ(calc((var(--nth-child) + 6) * calc(360 / 12) * 1deg)) translateY(430%)
    rotateZ(calc((var(--nth-child) + 6) * calc(360 / 12) * -1deg));
  cursor: pointer;
  z-index: 1;
  background-color: transparent;
  font-size: 0.8em;
}

.hours .hour:hover {
  background-color: var(--brand-accent-color-blue);
  color: white;
}

ul.minutes li[data-luru-selected='true'] {
  background-color: var(--brand-accent-color-blue);
  color: white;
  font-weight: bolder;
  opacity: 1;
}

.minutes :nth-child(1) {
  --nth-child-min: 1;
  opacity: 0;
}
.minutes :nth-child(2) {
  --nth-child-min: 2;
  opacity: 0;
}
.minutes :nth-child(3) {
  --nth-child-min: 3;
  opacity: 0;
}
.minutes :nth-child(4) {
  --nth-child-min: 4;
  opacity: 0;
}
.minutes :nth-child(5) {
  --nth-child-min: 5;
}
.minutes :nth-child(6) {
  --nth-child-min: 6;
  opacity: 0;
}
.minutes :nth-child(7) {
  --nth-child-min: 7;
  opacity: 0;
}
.minutes :nth-child(8) {
  --nth-child-min: 8;
  opacity: 0;
}
.minutes :nth-child(9) {
  --nth-child-min: 9;
  opacity: 0;
}
.minutes :nth-child(10) {
  --nth-child-min: 10;
}
.minutes :nth-child(11) {
  --nth-child-min: 11;
  opacity: 0;
}
.minutes :nth-child(12) {
  --nth-child-min: 12;
  opacity: 0;
}

.minutes :nth-child(13) {
  --nth-child-min: 13;
  opacity: 0;
}
.minutes :nth-child(14) {
  --nth-child-min: 14;
  opacity: 0;
}
.minutes :nth-child(15) {
  --nth-child-min: 15;
}
.minutes :nth-child(16) {
  --nth-child-min: 16;
  opacity: 0;
}
.minutes :nth-child(17) {
  --nth-child-min: 17;
  opacity: 0;
}
.minutes :nth-child(18) {
  --nth-child-min: 18;
  opacity: 0;
}
.minutes :nth-child(19) {
  --nth-child-min: 19;
  opacity: 0;
}
.minutes :nth-child(20) {
  --nth-child-min: 20;
}

.minutes :nth-child(21) {
  --nth-child-min: 21;
  opacity: 0;
}
.minutes :nth-child(22) {
  --nth-child-min: 22;
  opacity: 0;
}
.minutes :nth-child(23) {
  --nth-child-min: 23;
  opacity: 0;
}
.minutes :nth-child(24) {
  --nth-child-min: 24;
  opacity: 0;
}
.minutes :nth-child(25) {
  --nth-child-min: 25;
}
.minutes :nth-child(26) {
  --nth-child-min: 26;
  opacity: 0;
}
.minutes :nth-child(27) {
  --nth-child-min: 27;
  opacity: 0;
}
.minutes :nth-child(28) {
  --nth-child-min: 28;
  opacity: 0;
}
.minutes :nth-child(29) {
  --nth-child-min: 29;
  opacity: 0;
}
.minutes :nth-child(30) {
  --nth-child-min: 30;
}
.minutes :nth-child(31) {
  --nth-child-min: 31;
  opacity: 0;
}
.minutes :nth-child(32) {
  --nth-child-min: 32;
  opacity: 0;
}
.minutes :nth-child(33) {
  --nth-child-min: 33;
  opacity: 0;
}
.minutes :nth-child(34) {
  --nth-child-min: 34;
  opacity: 0;
}
.minutes :nth-child(35) {
  --nth-child-min: 35;
}
.minutes :nth-child(36) {
  --nth-child-min: 36;
  opacity: 0;
}
.minutes :nth-child(37) {
  --nth-child-min: 37;
  opacity: 0;
}
.minutes :nth-child(38) {
  --nth-child-min: 38;
  opacity: 0;
}
.minutes :nth-child(39) {
  --nth-child-min: 39;
  opacity: 0;
}
.minutes :nth-child(40) {
  --nth-child-min: 40;
}

.minutes :nth-child(41) {
  --nth-child-min: 41;
  opacity: 0;
}
.minutes :nth-child(42) {
  --nth-child-min: 42;
  opacity: 0;
}
.minutes :nth-child(43) {
  --nth-child-min: 43;
  opacity: 0;
}
.minutes :nth-child(44) {
  --nth-child-min: 44;
  opacity: 0;
}
.minutes :nth-child(45) {
  --nth-child-min: 45;
}
.minutes :nth-child(46) {
  --nth-child-min: 46;
  opacity: 0;
}
.minutes :nth-child(47) {
  --nth-child-min: 47;
  opacity: 0;
}
.minutes :nth-child(48) {
  --nth-child-min: 48;
  opacity: 0;
}
.minutes :nth-child(49) {
  --nth-child-min: 49;
  opacity: 0;
}
.minutes :nth-child(50) {
  --nth-child-min: 50;
}
.minutes :nth-child(51) {
  --nth-child-min: 51;
  opacity: 0;
}
.minutes :nth-child(52) {
  --nth-child-min: 52;
  opacity: 0;
}
.minutes :nth-child(53) {
  --nth-child-min: 53;
  opacity: 0;
}
.minutes :nth-child(54) {
  --nth-child-min: 54;
  opacity: 0;
}
.minutes :nth-child(55) {
  --nth-child-min: 55;
}
.minutes :nth-child(56) {
  --nth-child-min: 56;
  opacity: 0;
}
.minutes :nth-child(57) {
  --nth-child-min: 57;
  opacity: 0;
}
.minutes :nth-child(58) {
  --nth-child-min: 58;
  opacity: 0;
}
.minutes :nth-child(59) {
  --nth-child-min: 59;
  opacity: 0;
}
.minutes :nth-child(60) {
  --nth-child-min: 60;
}

.minutes .minute {
  left: 45%;
  top: 45%;
  position: absolute;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10%;
  height: 10%;
  /* background-color: black; */
  border-radius: 100%;
  padding: 5px;
  transform: rotateZ(calc((var(--nth-child-min) + 30) * calc(360 / 60) * 1deg)) translateY(430%)
    rotateZ(calc((var(--nth-child-min) + 30) * calc(360 / 60) * -1deg));
  cursor: pointer;
  z-index: 1;
  background-color: transparent;
  font-size: 0.8em;
}

.minutes .minute:hover {
  background-color: var(--brand-accent-color-blue);
  color: white;
}

/* Popup Menu Css */
.clockPopupMenu {
  padding: 1em;
  width: auto !important;
}

.errorContainer span {
  font-size: 0.7em;
  color: var(--warning-color);
}
