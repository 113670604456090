.parent {
  position: fixed;
  z-index: 300;
  display: none;
  flex-direction: column;
  visibility: hidden;
  top: 25%;
  left: 2%;
  width: 96%;
  min-width: 310px;
  height: 50%;
  padding: 1em;
  border: 1px solid var(--brand-accent-color-gray);
  box-shadow: var(--luru-box-shadow);
  border-radius: 0.3em;
  background-color: var(--background-color);
  opacity: 0;
  font-size: var(--font-size-decrease-1);
  user-select: none;
}

div.filterBoxContainer {
  display: flex;
  width: 100%;
  align-items: center;
}

@media (min-width: 600px) {
  .parent {
    left: 35%;
    width: 30%;
  }
}

@media (min-width: 900px) {
  .parent {
    left: 35%;
    width: 30%;
  }
}

div.filterBoxWrapper {
  flex-grow: 1;
}

.visible {
  visibility: visible;
  opacity: 1;
  display: flex;
}

.filterBox {
  position: relative;
  padding: 0.3em 1em;
  width: 100%;
}

.results {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-start;
  margin: 0.5em 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.results ul {
  display: block;
  width: 100%;
  list-style-type: none;
  align-self: flex-start;
}

li[data-collection-result='true'] {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5em 1em;
  cursor: pointer;
}

li[data-collection-result='true'] > * {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

li[data-collection-result='true'] img {
  height: 2em;
  vertical-align: middle;
}

.noresults,
.error {
  display: block;
  width: 100%;
  align-self: center;
  text-align: center;
}

.loaded {
  justify-self: center;
}

.parent footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: auto;
  border-top: 1px solid var(--brand-accent-color-gray);
  padding-top: 0.3em;
  font-size: var(--font-size-decrease-1);
}

.parent footer * {
  font-size: inherit;
}

.parent footer > * {
  font-size: var(--font-size-decrease-1);
}

.parent footer a {
  color: var(--brand-accent-color-blue);
  text-decoration: underline;
}

li[data-collection-result='true']:hover {
  color: var(--brand-accent-color-blue);
  font-weight: var(--bold-font-weight);
  cursor: pointer;
}

li[data-collection-result='true'].selected:hover,
.selected .fieldName,
.selected .schemaFieldName,
.selected .nullValue,
.selected {
  background-color: var(--brand-accent-color-blue-lighter-2);
}

.selected img {
  filter: grayscale() invert(0) contrast(1000%) saturate(100%);
}

li[data-collection-result='true']:hover {
  padding: 0.5em 1em;
}

.action {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 0.5em;
  border-top: 1px solid var(--brand-accent-color-gray);
  padding: 1em;
}

.action::after {
  position: absolute;
  width: 2em;
  height: 2em;
  top: -1.125em;
  left: calc(50% - 2em);
  content: 'or';
  padding: 0.5em;
  background-color: var(--background-color);
}

.emptyList {
  padding: 0.3em 1em;
}

.action.noCollection {
  border-top: none;
}

.action.noCollection::after {
  display: none;
}

.noresults {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
