.icon {
  --crm-icon-width: 24px;
  --crm-icon-height: 24px;
  --crm-icon-padding-x: 0px;
  --crm-icon-padding-y: 0px;
}

img[data-luru-icon-name='pipedrive-deal'] {
  --crm-icon-padding-x: 2px;
  --crm-icon-padding-y: 2px;
}

img[data-luru-icon-name='pipedrive-organization'] {
  --crm-icon-padding-x: 5px;
  --crm-icon-padding-y: 5px;
}

img[data-luru-icon-name='pipedrive-person'] {
  --crm-icon-padding-x: 2px;
  --crm-icon-padding-y: 2px;
}

img[data-luru-icon-name='sfdc-opportunity'] {
  --crm-icon-padding-x: 5px;
  --crm-icon-padding-y: 5px;
}

img[data-luru-icon-name='sfdc-contact'] {
  --crm-icon-padding-x: 5px;
  --crm-icon-padding-y: 5px;
}

img[data-luru-icon-name='sfdc-account'] {
  --crm-icon-padding-x: 5px;
  --crm-icon-padding-y: 5px;
}

img[data-luru-icon-name='sfdc-lead'] {
  --crm-icon-padding-x: 5px;
  --crm-icon-padding-y: 5px;
}

img[data-luru-icon-name='hubspot-contacts'] {
  --crm-icon-padding-x: 0;
  --crm-icon-padding-y: 0;
  filter: var(--hubspot-icon-filter);
}

img[data-luru-icon-name='hubspot-companies'] {
  --crm-icon-padding-x: 0;
  --crm-icon-padding-y: 0;
  filter: var(--hubspot-icon-filter);
}

img[data-luru-icon-name='hubspot-deals'] {
  --crm-icon-padding-x: 0;
  --crm-icon-padding-y: 0;
  filter: var(--hubspot-icon-filter);
}

summary img[data-luru-icon-name],
img[data-luru-icon-name] {
  width: calc(var(--crm-icon-width) - 2 * var(--crm-icon-padding-x));
  height: calc(var(--crm-icon-height) - 2 * var(--crm-icon-padding-y));
  padding: var(--crm-icon-padding-y) var(--crm-icon-padding-x);
}
