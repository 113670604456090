.main {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5em;
  margin-right: 0.5em;
  width: 100%;
  /* display: -webkit-inline-box; */
}

.logAsIcon {
  vertical-align: middle;
  height: 1.3em;
  width: 1.3em !important;
  margin: 0.15em 0.14em;
  transform: scale(1);
}

.logged {
  background-color: var(--green-color);
  color: white;
}
.logged:hover {
  background-color: var(--green-color);
}

.highLightRecordLinkController {
  background-color: var(--orange-color-lighter) !important;
}

.text {
  display: inline-block;
  width: 90% !important;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.9em;
}

.selectLabelClassName {
  font-size: 0.9em;
}

img.removeActionImg {
  z-index: 1;
  cursor: pointer;
}

img.whiteFilter {
  filter: var(--white-color-filter);
}

.ovalButton {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.ovalButtonRegular {
  width: calc(50% + 1em);
  flex-grow: 1;
}

@media screen and (max-width: 800px) {
  .ovalButtonRegular {
    width: calc(75% + 1.5em);
    flex-grow: 1;
  }
}

/* @media (max-width: 600px) {
  .ovalButtonRegular {
    width: 80%;
  }
}

@media (min-width: 600px) {
  .ovalButtonRegular {
    width: 53%;
  }
}

@media (min-width: 1200px) {
  .ovalButtonRegular {
    width: 52.5%;
  }
} */

.ovalButtonEmbedded {
  width: 100%;
}

.popupMenuClassName {
  width: 50%;
}

.popupMenuParent {
  width: 100%;
  display: block;
}
