.closeAside {
  width: 0px;
  opacity: 0;
  transition: all 0.3s ease;
  height: 70vh;
}

.closeAside > div {
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease;
  height: 70vh;
}

.closeAside > h1 {
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease;
  height: 70vh;
}

.closeAside > button {
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease;
  height: 70vh;
}

.closeAside > span {
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease;
  height: 70vh;
}