.parent {
  position: relative;
  font-size: 1rem;
}

.parent input[type='text'] {
  margin: 0 0.5em 0 0;
  border: 1px solid var(--brand-accent-color-gray);
  border-radius: 0.5em;
  outline: none;
  padding: 0.3em 1em;
}

.parent input[type='text']:focus {
  outline: none;
  border: 1px solid var(--brand-accent-color-blue);
  box-shadow: 0 0 0 3px var(--brand-accent-color-blue-lighter-2);
  border-radius: 0.5em;
  transition: box-shadow 0.2s ease-in;
}

.parent .popupMenu {
  position: absolute;
  top: calc(5px + 1.9em);
  left: 0;
  padding: 0.3em;
  background-color: var(--background-color);
  border: 1px solid var(--brand-accent-color-gray);
  z-index: 500;
}

.popupMenu li {
  display: block;
  border-radius: 0.3em;
  padding: 0.3em 1em;
}

.popupMenu li:hover {
  background-color: var(--brand-accent-color-blue-lighter-2);
}

.popupMenu.visible {
  visibility: visible;
}

.popupMenu.hidden {
  visibility: hidden;
}

.popupMenu .noMatches {
  width: 200px;
  padding: 0.3em 1em;
}
