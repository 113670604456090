[data-luru-role='floating-formatter'] {
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  border: var(--luru-border);
  border-radius: var(--luru-border-radius);
  padding: 0.2em;
  background-color: var(--background-color);
  box-shadow: var(--luru-box-shadow);
  font-size: 0.9em;
  text-align: center;
  white-space: nowrap;
  z-index: 100;
}

.visible-false {
  visibility: hidden;
  opacity: 0;
  transform: scale(0.95);
  transition: visibility 0s;
  transition: opacity 0s;
  transition: transform 0.1s ease-in-out;
}

.visible-true {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
  transition: visibility 0s;
  transition: opacity 0s;
  transition: transform 0.1s ease-in-out;
}

[data-luru-role='floating-formatter'] > div {
  position: relative;
  margin-left: 0.2em;
}

[data-luru-role='floating-formatter'] > div:first-child {
  margin-left: 0;
}

[data-luru-role='floating-formatter'] > div > button:focus,
[data-luru-role='floating-formatter'] > div > button {
  width: 30.2px;
  height: 30.2px;
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--text-subdued);
  cursor: pointer;
}

[data-luru-role='floating-formatter'] > div > button[data-luru-state='on'] {
  /* border: 1px solid var(--brand-accent-color-gray); */
  background-color: var(--brand-accent-color-gray-lighter-2);
}

[data-luru-role='floating-formatter'] > div > button span {
  padding: 0.3em;
  font-size: 1.2em;
}

[data-luru-role='floating-formatter'] > div > button:hover span {
  background-color: var(--brand-accent-color-gray-lighter-2);
}

[data-luru-role='floating-formatter'] button span.floatFormatHeading1Icon,
[data-luru-role='floating-formatter'] button span.floatFormatHeading2Icon,
[data-luru-role='floating-formatter'] button span.floatFormatHeading3Icon {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1em;
  text-transform: uppercase;
}

[data-luru-role='floating-formatter'] button span.floatFormatHeading2Icon {
  font-size: 0.85em;
}

[data-luru-role='floating-formatter'] button span.floatFormatHeading3Icon {
  font-size: 0.7em;
}

.floatFormatIcon {
  height: 1.4em;
  filter: invert(75%) sepia(0%) saturate(3144%) hue-rotate(118deg) brightness(99%) contrast(81%);
}
