.parent {
  display: flex;
  align-items: center;
  gap: 0.3em;
  width: 100%;
  padding: 0.2em;
  border: 1px solid var(--brand-accent-color-gray);
  border-radius: var(--luru-border-radius);
  /* flex-wrap: wrap; */
}

.chipsContainer {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.3em;
  flex-wrap: wrap;
}

.chip {
  border-radius: 0.3em;
  background-color: var(--brand-accent-color-blue-lighter-2);
  font-size: var(--font-size-increase-1);
  max-width: 150px;
  display: flex;
  align-items: center;
}

.chip:has(div[data-invalid='true']) {
  border: 1px solid var(--brand-accent-color-pink) !important;
}

.chip span.closeIcon {
  display: inline-flex;
  font-size: var(--font-size-increase-1);
  cursor: pointer;
  padding-right: 0.4em;
}

.chip span.closeIcon img {
  width: 0.8em;
  height: 0.8em;
  filter: var(--brand-accent-color-blue-filter);
  vertical-align: middle;
}

.chip span.chipTitle {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 130px;
  font-size: 0.9em;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  flex: 1;
  padding: 0.3em 0.4em 0.3em 0.6em;
}

.inputContainer {
  flex: 1;
}
.textInput {
  width: 100% !important;
  flex: 1;
  margin: 0;
  padding: 0;
  background: transparent;
  border: none !important;
  outline: none !important;
  appearance: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.parentFocused {
  border: 1px solid var(--brand-accent-color-blue-lighter-1) !important;
  box-shadow: 0 0 0 3px var(--brand-accent-color-blue-lighter-2);
  transition: box-shadow 0.2s ease-in;
}

.popupMenu {
  width: 100% !important;
}

.popupMenuContainer {
  width: 100%;
}

.checkedIcon {
  filter: var(--brand-accent-color-blue-filter);
}
