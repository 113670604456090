.slack-sign-in-button {
  align-items: center;
  color: #000;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: inline-flex;
  font-family: Lato, sans-serif;
  font-size: 14px;
  /* font-weight: 600; */
  height: 44px;
  justify-content: center;
  margin: 0 auto;
  text-decoration: none;
  width: 224px;
}

.slack-sign-in-button > svg {
  height: 16px;
  width: 16px;
  margin-right: 12px;
}
